import React from "react";
import { Container , Image} from "react-bootstrap";
import styles from "./Agencies.module.css";
import Agency1 from "../../assets/images/agency/business-insider.png";
import Agency2 from "../../assets/images/agency/fast-company.png";
import Agency3 from "../../assets/images/agency/marketwatch.png"
import Agency4 from "../../assets/images/agency/wall-street.png"
import Agency5 from "../../assets/images/agency/Yahoo_Finance.png"

const Agencies = () => {
    return (
        <section className={`${styles["featured-agencies"]} section-padding`}>
            <Container>
                <div className="text-center">
                    <h4 className=" mb-0 fw-500">Furnished Apartments </h4>
                    <h1 className="fw-600 primary-color mt-2 mb-3"> a Furnished Finder company</h1>
                    <h6 className="mb-4">AS SEEN ON</h6>
                </div>
                <div className={`${styles["agencies"]} bg-primary p-5 rounded-3 d-flex align-items-center justify-content-xl-between`}>
                <Image src={Agency1} className="img-fluid" ></Image>
                <Image src={Agency2} className="img-fluid" ></Image>
                <Image src={Agency3} className="img-fluid" ></Image>
                <Image src={Agency4} className="img-fluid" ></Image>
                <Image src={Agency5} className="img-fluid" ></Image>
                </div>
              
            </Container>
        </section>

    )
}

export default Agencies;