import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getCityState } from './BannerAPI';


const initialState: any = {
  loading: false,
  cities: [],
  error: "",
  selectedCity: [],
  selectedDateRange: {},
  priceRange: 20,
  rooms:0
};


export const getCityStateAsync = createAsyncThunk(
  'property/getCityState',
  async (matchString: string) => {
    const response = await getCityState(matchString);
    let cities = response?.data?.Data?.lstCityStateData.map((el: any) => { return el.city; });
    return cities
  }
);


export const placeListSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    incrementRoom: (state) => {
      if(state.rooms <10)
      {
      state.rooms += 1;
      }
    },
    decrementRoom: (state) => {
      if (state.rooms > 0) {
        state.rooms -= 1;
      }
    },
    updateSelectedCity: (state, action) => {
      state.selectedCity = [...action.payload]
    },
    updateDateRange: (state, action) => {
      state.selectedDateRange = action.payload
    },
    updatePriceRange: (state, action) => {
      state.minBudget = action.payload[0]
      state.maxBudget = action.payload[1]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCityStateAsync.pending, (state) => {
        state.loading = true;
      })

      .addCase(getCityStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload;
        state.error = "";
      })

      .addCase(getCityStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.cities = [];
        state.error = action.error.message || 'Something went wrong.';
      })

  },
});
export const { incrementRoom, decrementRoom, updateDateRange, updatePriceRange, updateSelectedCity } = placeListSlice.actions;
export default placeListSlice.reducer;