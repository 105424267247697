import React, { useState, useEffect, useRef } from 'react';
import Logo from '../../assets/images/logo.png';
import FFLogo from '../../assets/images/FF-logo.png';
import { Link } from 'react-router-dom';
import { Nav, Navbar, Container, Image } from 'react-bootstrap';
import styles from './Header.module.css';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  let useClickOutside = (handler: any) => {
    let domNode = useRef<any>();
    useEffect(() => {
      let maybeHandler = (event: any) => {
        if (domNode && domNode?.current && !domNode.current.contains(event.target)) {
          handler();
        }
      };
  
      document.addEventListener("mousedown", maybeHandler);
  
      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    });
  
    return domNode;
  };
  let expandNode = useClickOutside(() => {
    setExpanded(false);
  });
  return (
    <header className={styles.header}>
      <Container fluid className="h-100">
        <Navbar variant='light' expand="lg" expanded={expanded} className={`h-100 align-items-center`}>
          <div>
          <Navbar.Brand href="/"><Image src={Logo} alt="logo" className={styles["logo"]} />
          </Navbar.Brand>
          <div className={`text-grey ${styles["logo-text"]}`}>Powered by <a href='https://www.furnishedfinder.com/' target={"_blank"} rel="noreferrer"><Image src={FFLogo} className={`${styles["ff-logo"]}`}/></a></div>

          </div>
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" ref={expandNode} onClick={() => setExpanded(!expanded)}  />
          <Navbar.Collapse className="justify-content-end ">
            <Nav className={expanded ? 'show' : ''}  onClick={() => setExpanded(false)}>
              <Link className='nav-link' to="/list-your-property">List your property</Link>
              <Link className='nav-link' to="/property-owner">Landlords</Link>
              <Link className='nav-link' to="/search-map">Search map</Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );

}

export default Header;