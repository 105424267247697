import axiosInstance from "../../lib/axios";

export const getCityState = (matchString: string) => {
    return axiosInstance
        .get(`/Properties/getMapAutoCompleteData?matchString=${matchString}`)
        .then((response) => {
            return { data: response.data }
        })
        .catch((error) => {
            if (error && error.response) {
                if (error.response) {
                    return { data: error.response.data }
                }
            }
        });
};