import React from 'react';
import { Container } from 'react-bootstrap';
import BlogContainer from '../../features/blog-list/BlogContainer';

const Blog = () => {
    return (
        <Container>
            <BlogContainer />
        </Container>
    )
}
export default Blog;
