import React from 'react';
// import { Link } from 'react-router-dom';
// import { Card, Row, Col, Badge } from 'react-bootstrap';
// import Blog from '../../assets/images/blog.png';
import styles from './BlogList.module.css';

const BlogList = () => {
  return (
    <div className='container'>
      <div className={styles['blog-wrapper']}>
      <h4 className='primary-color'>Coming Soon</h4>
      </div>
      {/* <Card className='border-0 mb-3'>
        <Card.Body>
          <Row>

            <Col lg={6} sm={12}>
              <Card.Img variant="top" src={Blog}></Card.Img>
            </Col>
            <Col lg={6} sm={12} className={styles["blog-details"]}>
              <Badge bg="" pill className={styles["badge"]}>Travelers</Badge>
              <Card.Title className={`${styles["blog-title"]} mt-3`}><h1 className='text-start'>How professional real estate photos can make all the difference.</h1></Card.Title>
             <Card.Text className={styles["description"]}>
            
             
                July 10, 2022 <div className='vr'> </div> <span className={styles["blog-span"]}>4 min read</span>
              
                </Card.Text>
            </Col>
          </Row>



        </Card.Body>


      </Card>
      </Link>

      <Row>
        <Col sm={12} lg={4}>
        
          <Card className='mb-3 border-0' >
            <Card.Body>
              <Card.Img variant="top" src={Blog}></Card.Img>
              <Badge bg="" pill className={styles["badge-min"]}>Travelers</Badge>
              <Card.Title className={styles["blog-title-min"]}>How professional real estate photos can make all the difference.</Card.Title>
              <Card.Text className={styles["description"]}>
                July 10, 2022 <div className='vr'> </div> <span className={styles["blog-span"]}>4 min read</span>
              </Card.Text>
            </Card.Body>
          </Card>
          
        </Col>
        <Col sm={12} lg={4}>
        
          <Card className='mb-3 border-0'>
            <Card.Body>
              <Card.Img variant="top" src={Blog}></Card.Img>
              <Badge bg="" pill className={styles["badge-min"]}>Travelers</Badge>
              <Card.Title className={styles["blog-title-min"]}>How professional real estate photos can make all the difference.</Card.Title>
              <Card.Text className={styles["description"]}>
                July 10, 2022 <div className='vr'> </div> <span className={styles["blog-span"]}>4 min read</span>
              </Card.Text>
            </Card.Body>
          </Card>
          
        </Col>
        <Col sm={12} lg={4}>
          
          <Card className='mb-3 border-0'>
            <Card.Body>
              <Card.Img variant="top" src={Blog}></Card.Img>
              <Badge bg="" pill className={styles["badge-min"]}>Travelers</Badge>
              <Card.Title className={styles["blog-title-min"]}>How professional real estate photos can make all the difference.</Card.Title>
              <Card.Text className={styles["description"]}>
                July 10, 2022 <div className='vr'> </div> <span className={styles["blog-span"]}>4 min read</span>
              </Card.Text>
            </Card.Body>
          </Card>
          
        </Col>
      </Row> */}
    </div>
  )
}

export default BlogList;