import React from "react";
import { useParams } from "react-router-dom";
import POB2 from "../../components/blogs/property-owners/rapid-expansion-and-success-dee-coulsons-rental-arbitrage-journey";
import POB1 from "../../components/blogs/property-owners/revolutionizing-real-estate-lending-fund-that-flips-innovative-solutions";
import POB3 from "../../components/blogs/property-owners/better-your-community-through-real-estate-with-rb";
import POB4 from '../../components/blogs/property-owners/one-arbitrage-partner-can-change-everything-w-stay-nixon';


const BlogDetail = () => {

  const { name } = useParams();

  console.log('name', name)
  switch(name) {
    case "One Arbitrage Partner Can Change Everything w Stay Nixon": return <POB4/>
    case "Better Your Community Through Real Estate with RB": return <POB3/>
    case "From A Thought To 14 Mid-Term Arbitrage Rentals in 6 Months" : return <POB2/>;
    case "Revolutionizing Real Estate Lending: Fund That Flip's Innovative Solutions": return <POB1/>
  }


  };


export default BlogDetail;
