import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import Home from "../../pages/home";
import SearchMap from "../../pages/search-map";
import Blog from "../../pages/blog";
import Contact from "../../pages/contact";
import PropertyDetails from "../../features/property-details/PropertyDetails";
import ListPropertyRedirect from "../../components/ListPropertyRedirect/ListPropertyRedirect";
import PropertyOwner from "../../features/property-owner/PropertyOwner";
import BlogDetail from "../../features/blog-list/BlogDetail";
import PrivacyPolicy from "../../components/privacy-policy/PrivacyPolicy";
import TermsOfUse  from "../../components/terms-of-use/TermsOfUse";
import FAQ from "../../components/faq/FAQ";
import ScrollToTop from './ScrollToTop';
function RouterManager() {
  return (
    <Router>
     
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search-map" element={<SearchMap />} />
        <Route path="/search-map/:city/:state" element={<SearchMap />} />
        <Route path="/search-map/property/:propertyId"
        element={<PropertyDetails />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:name" element={<BlogDetail/>} />
        <Route path="/list-your-property" element={<ListPropertyRedirect/>} />
        <Route path="/property-owner" element={<PropertyOwner/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
        <Route path="/terms-of-service" element={<TermsOfUse/>}/>
        <Route path="/faq" element={<FAQ/>}/>


      </Routes>
      <Footer />
    </Router>
  );
}

export default RouterManager;
