import { useState, useRef } from 'react';
import { Container } from 'react-bootstrap';
import styles from './PrivacyPolicy.module.css';
import { MdOutlineArrowUpward } from 'react-icons/md';
const PrivacyPolicy = () => {
    const topRef = useRef<HTMLDivElement>(null)

    const executeScroll = () => topRef.current?.scrollIntoView();

    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };
    window.addEventListener('scroll', checkScrollTop)
    return (
        <section>
            <div className={styles["banner-heading"]}>
            <h1>Privacy Policy</h1>
            <p>Last Modified: October 3rd, 2022</p>
            </div>
        <Container className={styles['privacy-policy']} ref={topRef}>
        
        
        <div>
        <h5>1. Introduction</h5>
        <p>Pioneer Properties, Inc., doing business as Furnished Apartments (“Furnished Apartments”) respects your privacy and is committed to protecting it through its compliance with this Privacy Policy (the “Privacy Policy”).</p>

        <p>This Privacy Policy describes the types of information Furnished Apartments may collect from you or that you may provide when you visit www.furnishedapartments.com , www.travelnursehousing.com, and related websites, or any Furnished Apartments mobile or desktop apps (collectively, the “Furnished Apartments Platform”), and Furnished Apartments’ practices for collecting, using, maintaining, protecting, and disclosing that information.
        </p>
        <p>
            This Privacy Policy applies to information Furnished Apartments collects:
        <ul>
            <li>
                On the Furnished Apartments Platform.

            </li>
            <li>
                In email, text, and other electronic messages between you and the Furnished Apartments Platform or Furnished Apartments..
            </li>
            <li>
                Through mobile and desktop applications you download from the Furnished Apartments Platform, which provide dedicated non-browser-based interaction between you and the Furnished Apartments Platform.

            </li>
            <li>
                When you interact with Furnished Apartments’ advertising and applications on third-party websites and services, if those applications or advertising include links to this Privacy Policy.

            </li>
            <li>
                It does not apply to information collected by:

            </li>
            <li>
                any third party (including Furnished Apartments’ affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Furnished Apartments Platform

            </li>
        </ul>
        </p>

        <p>
            Please read this Privacy Policy carefully to understand Furnished Apartments’ policies and practices regarding your information and how Furnished Apartments will treat it. If you do not agree with Furnished Apartments’ policies and practices, your choice is not to use the Furnished Apartments Platform. By accessing or using the Furnished Apartments Platform, you agree to this Privacy Policy. This Privacy Policy may change from time to time (see the Changes to Furnished Apartments’ Privacy Policy section, below). Your continued use of the Furnished Apartments Platform after Furnished Apartments makes changes is deemed to be acceptance of those changes, so please check the Privacy Policy periodically for updates.
        </p>

        <h5>2. Children Under the Age of 13</h5>
        <p>The Furnished Apartments Platform is not intended for children under 13 years of age. No one under age 13 may provide any personal information to or on the Furnished Apartments Platform. Furnished Apartments does not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Furnished Apartments Platform or on or through any of its features/register on the Furnished Apartments Platform, make any purchases through the Furnished Apartments Platform, use any of the interactive or public comment features of this Furnished Apartments Platform or provide any information about yourself to Furnished Apartments, including your name, address, telephone number, email address, or any screen name or user name you may use. If Furnished Apartments learns Furnished Apartments has collected or received personal information from a child under 13 without verification of parental consent, Furnished Apartments will delete that information. If you believe Furnished Apartments might have any information from or about a child under 13, please contact Furnished Apartments at support@furnishedapartments.com.
        </p>
        <h5>3. Information Furnished Apartments Collects About You and How Furnished Apartments Collects </h5>
        <p><b>(a) Collection.</b> Furnished Apartments collects several types of information from and about users of the Furnished Apartments Platform, including information:
            <ul>
                <li>
                    by which you may be personally identified, either alone or in combination with other information available to Furnished Apartments, such as name, postal address, e-mail address, telephone number, age, birthdate, occupation, industry, move out dates for rental properties, background photos of yourself, or other personal information, as further defined under applicable laws (“Personal Information”);

                </li>
                <li>
                    that is about you but individually does not identify you, such as automatically recorded times and dates of visits to the Furnished Apartments Platform; and/or

                </li>
                <li>
                    about your internet connection, the equipment you use to access the Furnished Apartments Platform and usage details.

                </li>
             
                Furnished Apartments collects this information:
                <li>
                    Directly from you when you provide it to Furnished Apartments or through the Furnished Apartments Platform.

                </li>
                <li>
                    Automatically as you navigate through the Furnished Apartments Platform. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.
                </li>
                <li>
                    From third parties, for example, Furnished Apartments’ business partners.
                </li>
            </ul>

            <b>(b) Information You Provide to Us.</b> The information Furnished Apartments collects on or through the Furnished Apartments Platform may include:
            <ul>
                <li>
                    Information that you provide by filling in forms on the Furnished Apartments Platform. This includes information provided at the time of registering to use the Furnished Apartments Platform, subscribing to any Furnished Apartments service, posting material, or requesting further services, including housing requests and information on any contact owner form. Furnished Apartments may also ask you for information when you report a problem with the Furnished Apartments Platform.

                </li>
                <li>
                    Communications via text and or email may also be stored in the Furnished Apartments systems and Traveler's and Property Owner's communications may be saved within their dashboard. Travelers can cancel housing requests within their dashboard at any time, but that does not remove any communications prior to the cancellation.

                </li>
                <li>
                    Records and copies of your correspondence (including email addresses), if you contact Furnished Apartments.

                </li>
                <li>
                    Your responses to surveys that Furnished Apartments might ask you to complete for research purposes.

                </li>
                <li>
                    Details of transactions you carry out through the Furnished Apartments Platform. You may be required to provide financial information before placing an order through the Furnished Apartments Platform.

                </li>
                <li>
                    Your search queries on the Website.

                </li>
                <li>
                    Communications with Furnished Apartments via chat, or while communicating with Property Owner via the Furnished Apartments Platform and or in an application on the Furnished Apartments Platform.

                </li>

            </ul>
            You also may provide information to be published or displayed (hereinafter, “posted”) on public areas of the Furnished Apartments Platform or transmitted to other users of the Furnished Apartments Platform or third parties (collectively, “User Contributions and Content”). Your User Contributions and Content are posted on and transmitted to others at your own risk. Although Furnished Apartments limits access to certain pages, please be aware that no security measures are perfect or impenetrable. Additionally, Furnished Apartments cannot control the actions of other users of the Furnished Apartments Platform with whom you may choose to share your User Contributions and Content. Therefore, Furnished Apartments cannot and does not guarantee that your User Contributions and Content will not be viewed by unauthorized persons.
        </p>
        <p>
            <b>

                (c) Information Furnished Apartments Collects Through Automatic Data Collection Technologies. </b>As you navigate through and interact with the Furnished Apartments Platform, Furnished Apartments may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:
            <ul>
                <li>
                    Details of your visits to the Furnished Apartments Platform, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Furnished Apartments Platform.

                </li>
                <li>
                    Information about your computer and internet connection, including your IP address, operating system, and browser type.

                </li>
            </ul>
            The information Furnished Apartments collects automatically is statistical data and may include personal information, or Furnished Apartments may maintain it or associate it with personal information Furnished Apartments collects in other ways or receive from third parties. It helps Furnished Apartments to improve the Furnished Apartments Platform and to deliver a better and more personalized service, including by enabling Furnished Apartments to:
            <ul>
                <li>
                    Estimate the Furnished Apartments Platform audience size and usage patterns.

                </li>
                <li>
                    Store information about your preferences, allowing Furnished Apartments to customize the Furnished Apartments Platform according to your individual interests.

                </li>
                <li>
                    Speed up your searches.

                </li>
                <li>
                    Recognize you when you return to the Furnished Apartments Platform.

                </li>
            </ul>
            The technologies Furnished Apartments uses for this automatic data collection may include:
            <ul>
                <li>
                    <b>Cookies (or browser cookies). </b>A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of the Furnished Apartments Platform. Unless you have adjusted your browser setting so that it will refuse cookies, Furnished Apartments’ system will issue cookies when you direct your browser to the Furnished Apartments Platform.

                </li>
                <li>
                    <b> Flash Cookies.</b> Certain features of the Furnished Apartments Platform may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on the Furnished Apartments Platform. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How Furnished Apartments Uses and Discloses Your Information, below.

                </li>
                <li>
                    <b> Web Beacons.</b> Pages of the Furnished Apartments Platform and Furnished Apartments’ e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit Furnished Apartments, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).

                </li>
            </ul>
        </p>
        <p>
            <h5> 4. Third-Party Use of Cookies and Other Tracking Technologies</h5>
            Some content or applications, including advertisements, on the Furnished Apartments Platform are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use the Furnished Apartments Platform. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. Furnished Apartments does not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How Furnished Apartments Uses and Discloses Your Information, below.
        </p>
        <h5>5. How Furnished Apartments Uses Your Information</h5>
        <p>Furnished Apartments uses information that Furnished Apartments collects about you or that you provide to Furnished Apartments, including any personal information:
        <ul>
            <li>
                To present the Furnished Apartments Platform and its contents to you

            </li>
            <li>
                To provide you with information, products, or services that you request from Furnished Apartments

            </li>
            <li>
                To send you text messages that you received a communication from a Property Owner regarding your booking request or relating to your stay, and that you can log in to the Furnished Apartments Platform to receive that message.

            </li>
            <li>
                To improve your experience using the Furnished Apartments Platform

            </li>
            <li>
                To allow you to post content to the Furnished Apartments Platform

            </li>
            <li>
                To allow you to interact with other users

            </li>
            <li>
                To allow you to participate in promotions and programs on the Furnished Apartments Platform

            </li>
            <li>
                To provide information or services you have requested

            </li>
            <li>To process or maintain your registration on the Furnished Apartments Platform</li>
            <li>To facilitate advertisements on the Furnished Apartments Platform</li>
            <li>To adapt the services of Furnished Apartments’ network and community to your needs</li>
            <li>To research the effectiveness of Furnished Apartments’ network and community</li>
            <li>To develop new tools for Furnished Apartments’ network and community</li>
            <li>To fulfill any other purpose for which you provide it</li>
            <li>To provide you with notices about your account, including expiration and renewal notices.</li>
            <li>To carry out Furnished Apartments’ obligations and enforce Furnished Apartments’ rights arising from any contracts entered into between you and Furnished Apartments, including for billing and collection</li>
            <li>To notify you about changes to the Furnished Apartments Platform or any products or services Furnished Apartments offers or provides though it</li>
            <li>To allow you to participate in interactive features on the Furnished Apartments Platform</li>
            <li>In any other way Furnished Apartments may describe when you provide the information</li>
            <li>For any other purpose with your consent</li>
        </ul>
        </p>

        <p>

            Furnished Apartments may also use your information to contact you about goods and services that may be of interest to you.
        </p>
        <p>
            Furnished Apartments may use the information Furnished Apartments has collected from you to enable Furnished Apartments to display advertisements to Furnished Apartments’ advertisers' target audiences. Even though Furnished Apartments does not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.
        </p>
        <h5>6. Disclosure of Your Information</h5>
        <p>
        Furnished Apartments may disclose aggregated information about Furnished Apartments’ users, and information that does not identify any individual, without restriction.

        Furnished Apartments may disclose personal information that Furnished Apartments collects, or you provide, as described in this Privacy Policy:

        <li>To Furnished Apartments’ subsidiaries and affiliates.</li>
        <li>To contractors, service providers, and other third parties Furnished Apartments uses to support Furnished Apartments’ business, and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which Furnished Apartments discloses it to them.</li>
        <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Furnished Apartments’ assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Furnished Apartments about Furnished Apartments Platform users is among the assets transferred.</li>
        <li>To Property Owners relating to your areas and properties of interest.</li>
        <li>To third parties to market their products or services to you.</li>
        <li>To vendors, partners, advertisers, and other service providers in different industries and categories of business.</li>
        <li>To fulfill the purpose for which you provide it.</li>
        For any other purpose disclosed by Furnished Apartments when you provide the information.
        With your consent.
        Furnished Apartments may also disclose your personal information:

        To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
        To enforce or apply the Furnished Apartments Terms of Use and other agreements, including for billing and collection purposes.
        If Furnished Apartments believes disclosure is necessary or appropriate to protect the rights, property, or safety of Furnished Apartments, Furnished Apartments’ customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
        </p>
        <h5>7. Choices About How Furnished Apartments Uses and Discloses Your Information</h5>
        <p>Furnished Apartments strives to provide you with choices regarding the personal information you provide to Furnished Apartments. Furnished Apartments has created mechanisms to provide you with the following control over your information:

<ul>
<li>
Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.

</li>
<li>
Disclosure of Your Information for Third-Party Advertising. If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by sending an email requesting an opt-out to support@furnishedapartments.com.

</li>
</ul>
        Furnished Apartments does not control third parties' collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the NAI's website.
        </p>
        <h5>8. Your California Privacy Rights</h5>
        third parties for their direct marketing purposes. To make such a request, please send an email to support@furnishedapartments.com or write Furnished Apartments at: 11020 S. Pikes Peak Dr. #50, Parker, CO 80238.

        <h5>9. Data Security</h5>
        <p>
            Furnished Apartments has implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.

        </p>

        <p>
            The safety and security of your information also depends on you. Where Furnished Apartments has given you (or where you have chosen) a password for access to certain parts of the Furnished Apartments Platform, you are responsible for keeping this password confidential. Furnished Apartments asks you not to share your password with anyone. Furnished Apartments urges you to be careful about giving out information in public areas of the Furnished Apartments Platform. The information you share in public areas may be viewed by any user of the Furnished Apartments Platform.

        </p>
        <p>
            Unfortunately, the transmission of information via the internet is not completely secure. Furnished Apartments cannot guarantee the security of your personal information transmitted to the Furnished Apartments Platform. Any transmission of personal information is at your own risk. Furnished Apartments is not responsible for circumvention of any privacy settings or security measures contained on the Furnished Apartments Platform.

        </p>


        <h5>10. Changes to the Privacy Policy</h5>
        <p>It is Furnished Apartments’ policy to post any changes to Furnished Apartments’ Privacy Policy on this page. If Furnished Apartments makes material changes to how Furnished Apartments treats users' personal information, Furnished Apartments will notify you. The date the Privacy Policy was last revised is identified at the top of the page. You are responsible for ensuring Furnished Apartments has an up-to-date active and deliverable email address for you, and for periodically visiting the Furnished Apartments Platform and this Privacy Policy to check for any changes.
        </p>
        <h5>11. Contact Information</h5>
        <p>To ask questions or comment about this Privacy Policy and Furnished Apartments’ privacy practices, contact Furnished Apartments at:
        </p>
        
        Furnished Apartments
        Privacy Department
        11020 S. Pikes Peak Dr., #50
        Parker, CO 80138

        To ask questions or comment about this Privacy Policy and Furnished Apartments’ privacy practices, contact Furnished Apartments at:
        Furnished Apartments Privacy Department
        12900 Stroh Ranch Pl. #125. Parker, CO 80134
        </div>
    </Container>
    <div className={"scroll-wrap"}>
                <div onClick={executeScroll} className={"scroll-icon"} style={{ display: showScroll ? 'flex' : 'none' }}><MdOutlineArrowUpward /></div>
            </div>
        </section>

    )
}

export default PrivacyPolicy;