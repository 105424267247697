
import React, { useState, useRef, FormEvent } from 'react';
import { Button, Row, Col, Container, Form, Image, Card } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import CriminalHistory from '../../assets/images/criminal-history.png';
import RentalHistory from '../../assets/images/Icons/rental-history.png';
import RentCollection from '../../assets/images/Icons/rent-collection2.png';
import Nasa from '../../assets/images/nasa.png';
import Airline from '../../assets/images/po-industry-airline.png';
import Electric from '../../assets/images/po-industry-electric.png';
import Teachers from '../../assets/images/po-industry-teachers.png';
import Defense from '../../assets/images/po-industry-defense.png';
import CorporateTraveler from '../../assets/images/po-corporate-traveler.png';
import PoBed from '../../assets/images/po-bed.png';
import ListProperty from '../../assets/images/po-step-1.png';
import Negotiate from '../../assets/images/po-step-3.png';
import ConfirmBooking from '../../assets/images/po-step-4.png';
import ConnectTravelers from '../../assets/images/po-step-2.png';
import POUsers from '../../assets/images/po-users.png';
import NewVisitors from '../../assets/svg/new-visitors.svg';
import { useAppDispatch } from '../../app/hooks';
import Logo from '../../assets/images/logo.png';
import FFLogo from '../../assets/images/FF-logo.png';
import TNHLogo from '../../assets/images/travel-nurse-housing.png';
import POPageViews from '../../assets/images/po-page-views.png';
import MarketWatch from '../../assets/images/marketwatch.png';
import FastCompany from '../../assets/images/fast-company.png';
import WallStreetJournal from '../../assets/images/wall-street-journal.png';
import YahooFinance from '../../assets/images/yahoofinance.png';
import BusinessInsider from '../../assets/images/business-insider.png';
import Doctor from '../../assets/images/doctor.png';
import bird from '../../assets/images/bird1.png';
import academics from '../../assets/images/academics1.png';
import hunter from '../../assets/images/hunter1.png';
import { Select } from 'antd';
import { postEnquiryAsync } from './propertyOwnerSlice';
import { MdOutlineArrowUpward } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styles from './PropertyOwner.module.css';
import Client1 from "../../assets/images/c1-travel-nurse-source.png";
import Client2 from "../../assets/images/c2-wanderly.png";
import Client3 from "../../assets/images/c3-therapia-staffing.png";
import Client4 from "../../assets/images/c4-lumify.png";
// import Client5 from "../../assets/images/c5-keycheck.png";
// import Client6 from "../../assets/images/c6-transunion.png";
// import Client7 from "../../assets/images/c7-kamana.png";

const PropertyOwner = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      }
    const { Option } = Select;

    const dispatch = useAppDispatch();

    const [validated, setValidated] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [city, setCity] = useState('')
    const [pType, setPType] = useState('')
    const [message, setMessage] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [showMoreInfo, setShowMoreInfo] = useState(false)
    const [showExtra, setShowExtra] = useState(false);
    const [showExtra1, setShowExtra1] = useState(false);
    const [showExtra2, setShowExtra2] = useState(false);
    const [showExtra3, setShowExtra3] = useState(false);
    


    const handleClose = () => setShowMoreInfo(false);
    const handleShow = () => setShowMoreInfo(true);



    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
      
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);

        if (form.checkValidity() === true) {
            event.preventDefault();
            dispatch(postEnquiryAsync({
                firstName,
                lastName,
                email,
                phoneNumber,
                city,
                pType,
                message
            }))


            setTimeout(() => {
                setFirstName('')
                setEmail('')
                setPhoneNumber('')
                setPType('')
                setMessage('')
                setValidated(false)
                setSubmitted(true)
            }, 100);
        }
    };
    const topRef = useRef<HTMLDivElement>(null)

    const executeScroll = () => topRef.current?.scrollIntoView();

    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };


    window.addEventListener('scroll', checkScrollTop)

    return (


        <div className={styles["property-owner"]} ref={topRef}>
            <section className='po-banner'>
                <Container fluid>
                    <Row className='gx-0'>
                        <Col lg={6}>
                            <div className={styles["wrap-left"]}>
                                <div className= {`${styles.infobox} m-auto`} >
                                    <div className='bg-primary   p-4'>
                                       <h4 className='text-white m-0 fw-600'>Rent to people looking for Fully Furnished Accomodations </h4>
                                     </div>
                                     <div className='bg-white p-4'>
                                        <p className='mb-3'>Looking to rent to our new breed of apartment hunters that prefer long and mid term fully furnished vs unfurnished or our travelling professionals in need of a short term furnished stay, please fill out this simple form, or call us now <a href="tel:(661) 489-5907" className='fs-4 fw-500 primary-color'> (661) 489-5907  </a>to have a conversation with one of our listing specialists to ensure your property is a good fit for our traffic.</p>
                                        <Button className='btn btn-primary ' onClick={handleShow}>More info</Button>
                                     </div>
                                </div>
                               
                              
                             
                                <Modal
                                    show={showMoreInfo}
                                    onHide={handleClose}
                                    backdrop="static"
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title className='primary-color mx-auto'>More Info</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className='text-center'>
                                        <p>Furnished Apartments, combined with Furnished Finder, is the largest housing site dedicated to the traveling healthcare industry. Our travel professionals who use us typically have 13 week to 26 week assignments and use our map to locate reasonably priced short term furnished accommodations. If you think your property fits this criteria and you would like to attract more travel nurses and doctors, then we should have a chat. Fill out the form below and one of our property coordinators will be in contact shortly.
                                        </p>
                                    </Modal.Body>
                                </Modal>
                            </div>

                        </Col>
                        <Col lg={6}>
                            <div className={styles["wrap-right"]}>
                                <Container className={styles["focus-set"]}>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit} name="inquiry">
                                        <Row className="mb-3">
                                            <Form.Group as={Col} controlId="firstName" className='mb-3' sm={12} md={6}>
                                                <Form.Label>First name</Form.Label>
                                                <Form.Control type="text" required placeholder="" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="lastName" className='mb-3' sm={12} md={6}>
                                                <Form.Label>Last name</Form.Label>
                                                <Form.Control type="text" required placeholder="" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="email" className='mb-3' sm={12} md={6}>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control type="email" required placeholder="" value={email} onChange={(e) => setEmail(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="phone" className='mb-3' sm={12} md={6}>
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control required value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="city" className='mb-3' sm={12} md={6}>
                                                <Form.Label>Rental property City</Form.Label>
                                                <Form.Control type="text" placeholder="" value={city} onChange={(e) => setCity(e.target.value)} />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="pType" className='mb-3' sm={12} md={6}>
                                                <Form.Label>Property Type</Form.Label>
                                                <Select value={pType}
                                                    onChange={(value) => setPType(value)} className={styles["po-select"]}>
                                                    <Option value="apartment-complex">Apartment Complex</Option>
                                                    <Option value="entire-place">Entire Place</Option>
                                                    <Option value="hotel">Hotel</Option>
                                                    <Option value="private-room">Private Room</Option>
                                                </Select>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="message" className='mb-2' sm={12} lg={12}>
                                                <Form.Label>Your Message(Optional)</Form.Label>
                                                <Form.Control as="textarea" rows={3} placeholder="Type your message here…" value={message} onChange={(e) => setMessage(e.target.value)} />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Col md className='mb-2'>
                                            <a href="https://www.furnishedfinder.com/list-your-property?s=FA"  className='btn btn-primary me-3 w-100'>List your property</a>
                                            </Col>
                                            <Col md className='mb-2'>
                                            <Button type="submit" className='d-block w-100'>Inquire Now</Button>
                                            </Col>
                                        </Row>
                                       
                                        {submitted ? <p className='text-center fs-13 mt-2'>Great! We’re looking forward to connecting with you.</p>
                                            :
                                            <p className='text-center fs-13 mt-4'>By clicking on ‘Inquire Now’, I agree to the Furnished Apartments <br /><Link className='primary-color fw-500' to="/terms-of-service"> Terms & Conditions</Link> and <Link className='primary-color fw-500' to="/privacy-policy">Privacy Policy</Link></p>
                                        }

                                    </Form>
                                </Container>

                            </div>
                        </Col>
                    </Row>


                    <div className={styles['po-biz']}>
                        <Image src={MarketWatch} />
                        <Image src={YahooFinance} />
                        <Image src={BusinessInsider} />
                        <Image src={FastCompany} />
                        <Image src={WallStreetJournal} />

                    </div>
                </Container>
            </section>
        
            <section className='section-padding po-row1'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col sm={12} md={12} lg={7} >
                            <Row>
                                <Col className={'text-center d-none d-md-block'}>
                                    <Image className='{styles["img-mx-width-sm-devices"]}' src={POUsers} />
                                </Col>

                                <Col>
                                    <Card className={`border-0 ${styles["pv-card"]}`}>
                                        <div className={styles["primary-background"]}>
                                            <div className={styles["user-icon-bg"]}>
                                                <Image src={NewVisitors}  />
                                            </div>

                                        </div>
                                        <div className='mt-5 text-center'>
                                            <div className='primary-color fs-5 fw-500'> New Visitors </div>
                                            <div className='text-grey fs-5 fw-500 mb-3'><b className='primary-color fs-1'>2</b> millions per month</div>
                                        </div>
                                    </Card>

                                    <Card className={` border-0 ${styles["pv-card"]} mt-4`}>
                                        <div className={styles["primary-background"]}>
                                            <div className={styles["user-icon-bg"]}>
                                                <Image src={POPageViews} />
                                            </div>



                                        </div>
                                        <div className={`mt-5 text-center`}>
                                            <div className='primary-color fs-5 fw-500'> Page Views </div>
                                            <div className='text-grey fs-5 fw-500  mb-3'><b className='primary-color fs-2'>200</b> millions per year</div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={12} lg={5} className={styles["bg-width-brand-section"]}>
                            <h2 className='primary-color fw-600 mb-5'>One Listing,  Displayed on 3 great sites dedicated to Furnished listings only</h2>
                            <hr className='bg-primary ' />
                            <Card className='bg-white shadow-sm p-4 border-0 mt-5 d-block'>
                                <Row>
                                    <Col lg={6} sm={6}>
                                        <Image src={FFLogo} className='img-fluid mt-3 mb-3' />
                                    </Col>
                                    <Col lg={6} sm={6}>
                                        <Image src={TNHLogo} className='img-fluid mt-3 mb-3' />

                                    </Col>
                                    <Col lg={12} sm={12}>
                                        <div className='text-center'>
                                            <Image src={Logo} width={250}  className='img-fluid mt-3 mb-3' />
                                        </div>

                                    </Col>


                                </Row>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section-padding po-row2'>
                <Container>
                    <div className={styles["row2-wrap"]}>
                        <Row>
                            <Col lg={8}>
                                <h1 className='primary-color fs-1'>
                                    The benefits of renting to Furnished Apartment hunters
                                </h1>
                                <p className={styles["po-description"]}>
                                Specifically built to serve The new breed of apartment hunters that prefer long and mid term furnished over traditional unfurnished and traveling professionals in need of monthly furnished accommodations.
                                </p>

                            </Col>
                            <Col xl={9} lg={8} className={styles["data"]}>
                                <div >
                                    <Row>
                                        <Col md={6} className='mb-3'>
                                            <div className={styles['po-data-wrap'] }>
                                                <div className={styles['po-img-wrap']}><Image src={Doctor} /></div>
                                                <div className={`${styles['po-data']} ms-3 me-3`}>Corporate travelers</div>
                                            </div>
                                        </Col>

                                        <Col md={6} className='mb-3'>
                                            <div className={styles['po-data-wrap']}>
                                                <div className={styles['po-img-wrap']}><Image src={academics} height="50px" width="50px" /></div>
                                                <div className={`${styles['po-data']} ms-3 me-3`}>Traveling academics</div>
                                            </div>

                                        </Col>
                                        <Col md={6} className='mb-3'>
                                            <div className={styles['po-data-wrap']}>
                                                <div className={styles['po-img-wrap']}><Image src={bird} height="50px" width="50px" /></div>
                                                <div className={`${styles['po-data']} ms-3 me-3 `}>Snowbirds</div>
                                            </div>

                                        </Col>
                                        <Col md={6} className='mb-3'>
                                            <div className={styles['po-data-wrap']}>
                                                <div className={styles['po-img-wrap']}><Image src={hunter} height="50px" width="50px" /></div>
                                                <div className={`${styles['po-data']} ms-3 me-3 text-left`}>Furnished Apartment Hunters</div>
                                            </div>

                                        </Col>
                                    </Row>


                                </div>




                            </Col>
                        </Row>
                    </div>

                </Container>
            </section>
            <section className='section-padding light-bg'>
                <Container fluid>
                    <h1 className='primary-color text-center mb-lg-5'>How furnished apartments <br /> works for property owners</h1>
                    <Row className={styles["po-steps"]}>
                        <Col lg={3} md={6} className={styles["po-step-wrap"]}>
                            <div className='mb-3' >
                                <div className={styles["po-step-img"]}>
                                    <img src={ListProperty} alt="" />
                                </div>
                                <div className={`${styles.stepCard}  shadow-card`}>
                                    <h4 className='primary-color mt-0 fw-500'>List your property</h4>
                                    
                                    <p> Submit details and create a listing to start attracting people  {showExtra && (<p> looking for fully furnished long term stays. </p>)} </p>
                                    <button className={`${styles.expCollapseBtn}  primary-color`} onClick={() => setShowExtra(!showExtra)}>
                                        {showExtra ? 'Read less' : 'Read more'}
                                    </button>


                                    
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} className={styles["po-step-wrap"]}>
                            <div className='mb-3'>
                                <div className={styles["po-step-img"]}>
                                    <img src={ConnectTravelers} alt="" />

                                </div>
                                <div className={`${styles.stepCard}  shadow-card`}>
                                    <h4 className='primary-color  mt-0  fw-500'>Attract Tenants</h4>
                                    <p> Once your listing is approved, Your listing willl be displayed on all 3 of {showExtra1 && (<p> our sites. Furnished Finder, Travel Nurse Housing, and Furnished Apartments. </p>)} </p>
                                    <button className={`${styles.expCollapseBtn}  primary-color`} onClick={() => setShowExtra1(!showExtra1)}>
                                        {showExtra1 ? 'Read less' : 'Read more'}
                                    </button>
                                    </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} className={styles["po-step-wrap"]}>
                            <div className='mb-3'>
                                <div className={styles["po-step-img"]}>
                                    <img src={Negotiate} alt="" />
                                </div>
                                <div className={`${styles.stepCard}  shadow-card`}>
                                    <h4 className='primary-color mt-0 fw-500'>Connect Direct</h4>
                                    <p> We connect you directly with interested tenants so that you can {showExtra2 && (<p> connect directly with them. You will receive their direct contact info when they are interested or they will be calling you directly</p>)} </p>
                                    <button className={`${styles.expCollapseBtn}  primary-color`} onClick={() => setShowExtra2(!showExtra2)}>
                                        {showExtra2 ? 'Read less' : 'Read more'}
                                    </button>


                                </div>
                            </div>
                        </Col>
                        <Col lg={3} md={6} className={styles["po-step-wrap"]}>
                            <div className='mb-3'>
                                <div className={styles["po-step-img"]}>
                                    <img src={ConfirmBooking} alt="" />

                                </div>
                                <div className={`${styles.stepCard}  shadow-card`}>
                                    <h4 className='primary-color mt-0  fw-500'>Screening tools</h4>

                                    <p> We provide access to tenant screening, e signature leases, {showExtra3 && (<p> eviction checks and more.</p>)} </p>
                                    <button className={`${styles.expCollapseBtn}  primary-color`} onClick={() => setShowExtra3(!showExtra3)}>
                                        {showExtra3 ? 'Read less' : 'Read more'}
                                    </button>


                                  
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section-padding'>
                <Container>
                    <h1 className='primary-color text-center'>Approved listings may be displayed <br className='sm-hide' /> across our whole partner network</h1>
                    <ul className={styles["partner-grid"]}>
                        <li>
                            <img height="80" src={FFLogo} className='img-fluid' alt="" />
                        </li>
                        <li>
                            <img height="80" src={TNHLogo} className='img-fluid' alt="" />
                        </li>
                        <li>
                            <img height="80" src={Client1} className='img-fluid' alt="" />
                        </li>
                        <li>
                            <img height="80" src={Client2} className='img-fluid' alt="" />
                        </li>
                        <li>
                            <img height="80" src={Client3} className='img-fluid' alt="" />
                        </li>
                        <li>
                            <img height="80" src={Client4} className='img-fluid' alt="" />
                        </li>
                       
                    </ul>
                   
                 
                </Container>
            </section>
            <section className={`${styles['industries-wrap']} section-padding light-bg`}>
                <Container>
                    <h1 className='primary-color text-center'>Industries we serve</h1>
                    <Row className=' align-items-center justify-content center'>
                        <Col md={2} className={styles["w-sm-50"]}>
                            <div className='text-center'>
                                <img height="80" src={Teachers} className='img-fluid' alt="" />
                            </div>
                        </Col>
                        <Col md={2} className={styles["w-sm-50"]}>
                            <div className='text-center'>
                                <img height="80" src={Defense} className='img-fluid' alt="" />
                            </div>
                        </Col>
                        <Col md={2} className={styles["w-sm-50"]}>
                            <div className='text-center'>
                                <img height="80" src={Nasa} className='img-fluid' alt="" />
                            </div>
                        </Col>
                        <Col md={2} className={styles["w-sm-50"]}>
                            <div className='text-center'>
                                <img height="80" src={Airline} className='img-fluid' alt="" />
                            </div>
                        </Col>
                        <Col md={1} className={styles["w-sm-50"]}>
                            <div className='text-center'>
                                <img height="80" src={Electric} className='img-fluid' alt="" />
                            </div>
                        </Col>
                        <Col md={3} className={styles["w-sm-50"]}>
                            <div className='text-center'>
                                <img height="80" src={CorporateTraveler} className='img-fluid' alt="" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={`${styles.pofaq}  section-padding`}>
                <Container>
                    <Row className='gx-0'>
                        <Col lg={5} md={4}>
                            <img src={PoBed} className='img-fluid' alt="" />
                        </Col>
                        <Col lg={7} md={8}>
                            <div className={`${styles.SpacingSmdevices}  light-bg p-lg-5 p-3 h-100`}>
                                <h2 className='primary-color mt-0 fw-500'>Property owner education center</h2>
                                <p className='sub-title fw-400'>Still have questions about Furnished apartment?<br /> Check Out our <Link onClick={scrollToTop} to='/faq'>Frequently Asked Questions (FAQs)</Link>.</p>
                                <Link onClick={scrollToTop} to="/contact" className='btn btn-primary'>Contact Us</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='section-padding pt-0'>
                <Container>
                <h1 className='primary-color text-center mb-lg-5'>Free tenant screening for our landlords</h1>
                    <Row>
                        {/* <Col lg={3}>
                            <h2 className='fw-600 primary-color text-center'>Free tenant screening for our landlords</h2>
                        </Col> */}
                        <Col lg={3} md={3} sm={6} xs={6}>
                            <div className={`${styles.shadowbox} shadow-card rounded-3 text-center`}>
                                <h1 className='primary-color mb-0' style={{ fontSize: "50px" }}>789</h1>
                                <hr />
                                <h4 className='mb-0 primary-color fw-500'>Credit Score</h4>
                            </div>
                        </Col >
                        <Col lg={3} md={3} sm={6} xs={6}>
                            <div className={`${styles.shadowbox} shadow-card rounded-3 text-center`}>
                                <img src={CriminalHistory} height="65" className=' m-auto' alt="" />
                                <hr />
                                <h4 className='mb-0 primary-color fw-500'>Criminal History</h4>
                            </div>
                        </Col>

                        <Col lg={3} md={3} sm={6} xs={6}>
                            <div className={`${styles.shadowbox} shadow-card rounded-3 text-center`}>
                                <img src={RentalHistory} height="65" className='m-auto' alt="" />
                                <hr />
                                <h4 className='mb-0 primary-color  fw-500'>Rental History</h4>
                            </div>
                        </Col>
                        <Col lg={3} md={3} sm={6} xs={6}>
                            <div className={`${styles.shadowbox} shadow-card rounded-3 text-center`}>
                                <img src={RentCollection} height="65" className='m-auto' alt="" />
                                <hr />
                                <h4 className='mb-0 primary-color  fw-500'>Rent Collection</h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <div className={styles["scroll-wrap"]}>
                <div onClick={executeScroll} className={styles["scroll-icon"]} style={{ display: showScroll ? 'flex' : 'none' }}><MdOutlineArrowUpward /></div>
            </div>

        </div>
    )
}

export default PropertyOwner;