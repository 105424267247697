import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import propertyListReducer from '../features/property-list/propertyListSlice';
import placeListReducer from '../features/Banner/BannerSlice';
import  propertyDetailsSlice from '../features/property-details/PropertyDetailsSlice';

export const store = configureStore({
  reducer: {
    propertyList: propertyListReducer,
    places: placeListReducer,
    propertyDetails: propertyDetailsSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
