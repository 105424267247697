import { createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { postEnquiryForm } from './propertyOwnerAPI';


export interface POForm {
    firstName: string,
    lastName: string,
}
export interface EnquiryForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  city: string;
  pType: string;
  message: string;
}
interface PropertyOwnerState {
  loading: boolean;
  status: string;
  rooms: number;
  selectedDateRange: [Date, Date];
  minBudget: number;
  maxBudget: number;
  total_pages: number;
  error?: string;
}
const initialState: PropertyOwnerState = {
  loading: false,
  status: '',
  rooms: 0,
  selectedDateRange: [new Date(), new Date()],
  minBudget: 0,
  maxBudget: 0,
  total_pages: 0,
  error: undefined,
};


export const postEnquiryAsync = createAsyncThunk(
  'property-owners/postEnquiryForm',
  async (params: EnquiryForm) => {
    const response = await postEnquiryForm(params);
    return response?.data?.HttpStatusCode;
  }
);

export const propertyOwnerSlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    incrementRoom: (state) => {
      state.rooms += 1;
    },

    updateDateRange: (state, action) => {
      state.selectedDateRange = action.payload
    },
    updatePriceRange: (state, action) => {
      state.minBudget = action.payload[0]
      state.maxBudget = action.payload[1]
    },
    updatePages: (state, action) => {
      state.total_pages = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(postEnquiryAsync.pending, (state) => {
        state.loading = true;
      })

      .addCase(postEnquiryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload;
        state.error = "";
      })

      .addCase(postEnquiryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong.';
      });


  },
});

export default propertyOwnerSlice.reducer;
