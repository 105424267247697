import React, { useEffect, useState, useRef } from 'react';
import { useAppSelector, useAppDispatch, useScreenWidth } from '../../app/hooks';
import { getPropertiesAsync, getPropertyListAsync, getCityStateAsync, updateFilters, getSortAsync, getSortDescAsync, getSortDateAsync, updateSelectedCity, updatePageNumber, updateData } from './propertyListSlice';
import { Col, Container, Row, Form, Badge } from 'react-bootstrap';
import PropertySearchBar from '../../components/PropertySeachBar/PropertySearchBar';
import PropertySort from './PropertySort';
import PropertyListLoading from '../../components/PropertyListCard/PropertyListLoading';
import { useLoadScript } from '@react-google-maps/api';
import styles from './PropertyList.module.css';
import MapContainer from './MapContainer';
import { MdOutlineArrowUpward } from 'react-icons/md';
import { MdMap } from 'react-icons/md';
import { MdViewList } from 'react-icons/md';
import { useSearchParams } from "react-router-dom";
import PropertyList from './PropertyList';
import SearchLanding from './SearchLanding';
import { MdOutlineClose } from 'react-icons/md';

const usePageMeta = (title: string, description: string) => {
    const defaultTitle = "furnished apartments";
    const defaultDesc = "home, condos etc";

    useEffect(() => {
        document.title = title !== undefined ? title : defaultTitle;
        const desc = document.querySelector("meta[name='description']")!
        desc.setAttribute("content", description !== undefined ? description : defaultDesc);
    }, [defaultTitle, title, defaultDesc, description]);
};

const Search = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const params = new URLSearchParams(searchParams);
    const [modalShow, setModalShow] = React.useState(false);

    const [city, setCity] = useState<string | null>('');
    const [state, setState] = useState<string | null>('');
    const [pageLoader, setPageLoader] = useState(false);
    const [showToast, setShowToast] = useState(false)

    const [isLocationEmpty, setIsLocationEmpty] = useState(false);
    let cities = useAppSelector((state) => state.places.cities);
    const isLoading = useAppSelector((state) => state.propertyList.loading);
    const isPLLoading = useAppSelector((state) => state.propertyList.plLoading);
    const screenWidth = useScreenWidth();

    const [selectedPlace, setSelectedPlace] = useState<any>([]);
    const selectedCity = useAppSelector((state) => state.places.selectedCity);
    const filters = useAppSelector((state) => state.propertyList.filters);

    //filter variables
    const [rooms, setRooms] = useState(0)
    const [bathrooms, setBathrooms] = useState(0)
    const [minBudget, setMinBudget] = useState(20)
    const [maxBudget, setMaxBudget] = useState(10000)
    const today = new Date();
    const [selectedDay, setSelectedDay] = useState<Date>(today);
    const [ATRoom, setATRoom] = useState(false);
    const [ATCondo, setATCondo] = useState(false);
    const [ATCottage, setATCottage] = useState(false);
    const [ATAppartment, setATAppartment] = useState(false);
    const [ATHouse, setATHouse] = useState(false);

    const [selectedProperty, setSelectedProperty] = useState<any>()

    const mapdata = useAppSelector((state: any) => state?.propertyList?.mapData);
    const dispatch = useAppDispatch();


    const [mapView, setMapView] = useState(true);
    const [listView, setListView] = useState(true);


    const [matchString, setMatchString] = useState('');
    const [isLocation, setIsLocation] = useState(false);

    const properties = useAppSelector((state) => state.propertyList.properties);

    const pageNumber = useAppSelector((state) => state.propertyList.pageNumber);


    const [sortValue, setSortValue] = useState('availability')
    const [propertyCount, setPropertyCount] = useState(-1)
    const [totalPages, setTotalPages] = useState(0)
    const [mapMarkers, setMapMarkers] = useState<any>([])
    const [propertyData, setPropertyData] = useState<any>({})

    const NUM_PER_PAGE = mapView ? 30 : 40
    const TOTAL_COUNT = propertyData?.GridProperty?.RecordCount

    usePageMeta(propertyCount > 0 ? `${propertyCount} ${city}, ${state}` : 'Search Map Furnished Apartments', "Furnished Apartments");
    useEffect(() => {
        setPageLoader(true)
        setCity(params.get("city"))
        setState(params.get("state"))
        dispatch(updateFilters(JSON.parse(params.get("filters")!)))
        // eslint-disable-next-line
    }, [searchParams, dispatch])


    useEffect(() => {
        if (properties) {
            setPropertyData({ ...properties })
        }

    }, [properties])

    useEffect(() => {
        if (propertyData) {
            setPropertyCount(propertyData?.GridProperty?.RecordCount)
            setTotalPages(Math.ceil(propertyData?.GridProperty?.RecordCount / NUM_PER_PAGE))
            setMapMarkers(propertyData?.GridProperty?.points)
        }

        // eslint-disable-next-line
    }, [propertyData])


    useEffect(() => {
        if (city && state) {
            setSelectedPlace([`${city}, ${state}`])
            dispatch(updateSelectedCity([`${city}, ${state}`]))
            dispatch(getPropertiesAsync({ state: state, city: city }));
            dispatch(updatePageNumber(0))
        }
        // eslint-disable-next-line
    }, [city, state])
    useEffect(() => {

        if (filters) {
            setRooms(filters?.rooms ?? 0);
            setBathrooms(filters?.bathrooms ?? 0);
            setMinBudget(filters?.minBudget ?? 20);
            setMaxBudget(filters?.maxBudget ? filters?.maxBudget : 10000);
            setSelectedDay(filters?.selectedDay ? filters?.selectedDay : selectedDay?.toISOString());
            filters?.ATCondo && setATCondo(filters?.ATCondo);
            filters?.ATCottage && setATCottage(filters?.ATCottage);
            filters?.ATAppartment && setATAppartment(filters?.ATAppartment);
            filters?.ATRoom && setATRoom(filters?.ATRoom);
            filters?.ATHouse && setATHouse(filters?.ATHouse);
        }
        // eslint-disable-next-line
    }, [filters]);



    useEffect(() => {
        if (mapdata && city && state) {
            let Mzoom = parseInt(mapdata?.Zoom)
            let Clat = parseFloat(mapdata?.Center?.split(", ")[0])
            let Clon = parseFloat(mapdata?.Center?.split(", ")[1])
            let factor = 0.25;
            if (Mzoom > 13) {
                factor = 0.25;
            } else if (Mzoom === 13) {
                factor = 0.30;
            } else if (Mzoom === 12) {
                factor = 0.35;
            } else if (Mzoom === 11) {
                factor = 0.40;
            } else if (Mzoom === 10) {
                factor = 0.60;
            } else if (Mzoom === 9) {
                factor = 1;
            } else if (Mzoom === 8) {
                factor = 1.5;
            } else if (Mzoom === 7) {
                factor = 2;
            } else if (Mzoom === 6) {
                factor = 3;
            } else if (Mzoom === 5) {
                factor = 4;
            } else if (Mzoom < 5) {
                factor = 6;
            }
            let maxlat = Clat + factor;
            let minlat = Clat - factor;
            let maxlon = Clon + factor;
            let minlon = Clon - factor;

            dispatch(getPropertyListAsync({
                CityName: city,
                StateName: state,
                MaxLat: maxlat,
                MaxLon: maxlon,
                MinLat: minlat,
                MinLon: minlon,
                PageNumber: 0,
                PageSize: 100,
                Shared: filters?.ATRoom ?? ATRoom,
                AppartMent: filters?.ATAppartment ?? ATAppartment,
                Condo: filters?.ATCondo ?? ATCondo,
                Cottage: filters?.ATCottage ?? ATCottage,
                BasementSuite: filters?.ATAppartment ?? ATAppartment,
                BedRoomCount: filters?.rooms > 0 ? filters?.rooms : 0,
                House: filters?.ATHouse ?? ATHouse,
                MinBudget: filters?.minBudget?.toString() ?? minBudget?.toString(),
                MaxBudget: filters?.maxBudget?.toString() ?? maxBudget?.toString(),
                MoveInDate: new Date(filters?.selectedDay) ?? new Date(selectedDay),
                Own: (!ATCottage&&!ATCondo&&!ATHouse&&!ATAppartment&&ATRoom?false:true)
            }));
        }
        // eslint-disable-next-line
    }, [mapdata, city, state, filters]);

    useEffect(() => {
    }, [properties])

    useEffect(() => {
        if (selectedCity[0] === selectedPlace[0]) {
            setIsLocation(false)
        }
        // eslint-disable-next-line
    }, [selectedCity])

    useEffect(() => {
        dispatch(getCityStateAsync(matchString));
    }, [matchString, dispatch])

    const handleSearch = () => {
        if (selectedPlace.length === 0) {
            setShowToast(true)
            setIsLocationEmpty(true);
            return;
        }
        setPageLoader(true)
        dispatch(updateData([]))
        setPropertyData([])


        setSearchParams(`city=${selectedPlace[0]?.split(', ')[0]}&state=${selectedPlace[0]?.split(", ")[1]}&filters=${JSON.stringify({
            rooms,
            bathrooms,
            minBudget,
            maxBudget,
            selectedDay: selectedDay,
            ATCondo,
            ATCottage,
            ATAppartment,
            ATRoom,
            ATHouse
        })}`);

    }


    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!
    })
    const handlePlaceUpdate = (place: any) => {
        setSelectedPlace(place)
    }
    const handleChange = (value: any) => {
        if (value === 'lth') {
            dispatch(updateData([]))
            dispatch(getSortAsync({ ...properties }))
        }
        if (value === 'htl') {
            dispatch(updateData([]))
            dispatch(getSortDescAsync({ ...properties }))
        }
        if (value === 'availability') {
            dispatch(updateData([]))
            dispatch(getSortDateAsync({ ...properties }))
        }
        setSortValue(value)
    }
    const topRef = useRef<HTMLDivElement>(null)
    const executeScroll = () => topRef.current?.scrollIntoView();
    const [showScroll, setShowScroll] = useState(false)
    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };
    useEffect(() => {
        if (isLoaded && !isPLLoading) {
            setTimeout(() => {
                setPageLoader(false)
            }, 1500);
        }
    }, [isLoaded, propertyData, isPLLoading])
    window.addEventListener('scroll', checkScrollTop)


    const handleCondoClose = () => {
        setATCondo(false)
        setPageLoader(true)
        dispatch(updateData([]))
        setPropertyData([])
        setSearchParams(`city=${selectedPlace[0]?.split(', ')[0]}&state=${selectedPlace[0]?.split(", ")[1]}&filters=${JSON.stringify({
            rooms,
            bathrooms,
            minBudget,
            maxBudget,
            selectedDay: selectedDay,
            ATCondo: false,
            ATAppartment,
            ATRoom,
            ATHouse
        })}`);

    }


    const handleCottageClose = () => {
        setATCottage(false)
        setPageLoader(true)
        dispatch(updateData([]))
        setPropertyData([])
        setSearchParams(`city=${selectedPlace[0]?.split(', ')[0]}&state=${selectedPlace[0]?.split(", ")[1]}&filters=${JSON.stringify({
            rooms,
            bathrooms,
            minBudget,
            maxBudget,
            selectedDay: selectedDay,
            ATCottage: false,
            ATCondo,
            ATAppartment,
            ATRoom,
            ATHouse
        })}`);

    }

    const handleRoomClose = () => {
        setATRoom(false)
        setPageLoader(true)
        dispatch(updateData([]))
        setPropertyData([])
        setSearchParams(`city=${selectedPlace[0]?.split(', ')[0]}&state=${selectedPlace[0]?.split(", ")[1]}&filters=${JSON.stringify({
            rooms,
            bathrooms,
            minBudget,
            maxBudget,
            selectedDay: selectedDay,
            ATCondo,
            ATCottage,
            ATAppartment,
            ATRoom: false,
            ATHouse
        })}`);

    }


    const handleApartmentClose = () => {
        setATAppartment(false)
        setPageLoader(true)
        dispatch(updateData([]))
        setPropertyData([])
        setSearchParams(`city=${selectedPlace[0]?.split(', ')[0]}&state=${selectedPlace[0]?.split(", ")[1]}&filters=${JSON.stringify({
            rooms,
            bathrooms,
            minBudget,
            maxBudget,
            selectedDay: selectedDay,
            ATCondo,
            ATCottage,
            ATAppartment: false,
            ATRoom,
            ATHouse
        })}`);
    }

    const handleHouseClose = () => {
        setATHouse(false)
        setPageLoader(true)
        dispatch(updateData([]))
        setPropertyData([])
        setSearchParams(`city=${selectedPlace[0]?.split(', ')[0]}&state=${selectedPlace[0]?.split(", ")[1]}&filters=${JSON.stringify({
            rooms,
            bathrooms,
            minBudget,
            maxBudget,
            selectedDay: selectedDay,
            ATCondo,
            ATCottage,
            ATAppartment,
            ATRoom,
            ATHouse: false
        })}`);
    }

    const handleRoomsClose = () => {
        setRooms(0)
        setPageLoader(true)
        dispatch(updateData([]))
        setPropertyData([])
        setSearchParams(`city=${selectedPlace[0]?.split(', ')[0]}&state=${selectedPlace[0]?.split(", ")[1]}&filters=${JSON.stringify({
            rooms: 0,
            bathrooms,
            minBudget,
            maxBudget,
            selectedDay: selectedDay,
            ATCondo,
            ATCottage,
            ATAppartment,
            ATRoom,
            ATHouse
        })}`);
    }

    const handleBathroomsClose = () => {
        setBathrooms(0)
        setPageLoader(true)
        dispatch(updateData([]))
        setPropertyData([])
        setSearchParams(`city=${selectedPlace[0]?.split(', ')[0]}&state=${selectedPlace[0]?.split(", ")[1]}&filters=${JSON.stringify({
            rooms,
            bathrooms: 0,
            minBudget,
            maxBudget,
            selectedDay: selectedDay,
            ATCondo,
            ATCottage,
            ATAppartment,
            ATRoom,
            ATHouse: false
        })}`);
    }

    return (
        <Container fluid ref={topRef}>
            {city && state ?
                <>

                    <Row className={`${styles["property-search-row"]}`}>
                        <Col lg={10} xl={8}>
                            <PropertySearchBar
                                modalShow={modalShow}
                                setModalShow={setModalShow}
                                isLocationEmpty={isLocationEmpty}
                                showToast={showToast}
                                setShowToast={setShowToast}
                                isLocation={isLocation}
                                setIsLocation={setIsLocation}
                                handlePlaceUpdate={handlePlaceUpdate}
                                filters={filters}
                                handleSearch={handleSearch}
                                selectedCity={selectedCity}
                                updateSelectedCity={updateSelectedCity}
                                minBudget={minBudget}
                                setMinBudget={setMinBudget}
                                maxBudget={maxBudget}
                                setMaxBudget={setMaxBudget}
                                selectedDay={selectedDay}
                                setSelectedDay={setSelectedDay}
                                rooms={rooms}
                                setRooms={setRooms}
                                cities={cities}
                                selectedPlace={selectedPlace}
                                setSelectedPlace={setSelectedPlace}
                                isLoading={isLoading}
                                matchString={matchString}
                                setMatchString={setMatchString}
                                ATHouse={ATHouse}
                                setATHouse={setATHouse}
                                ATRoom={ATRoom}
                                setATRoom={setATRoom}
                                ATCondo={ATCondo}
                                setATCondo={setATCondo}
                                ATCottage={ATCottage}
                                setATCottage={setATCottage}
                                ATAppartment={ATAppartment}
                                setATAppartment={setATAppartment}
                                bathrooms={bathrooms}
                                setBathrooms={setBathrooms}
                                updateFilters={updateFilters}
                                dispatch={dispatch}
                                screenWidth={screenWidth}
                            />
                        </Col>
                        <Col lg={2} xl={4} style={{ display: city && state ? "flex" : "none", justifyContent: screenWidth > 768 ? "end" : "space-between" }} className="jalign-items-center mt-2 map-view-hide" >
                            {screenWidth < 768 ? <PropertySort
                                sortValue={sortValue}
                                handleChange={handleChange}
                            /> : null}
                            {screenWidth <= 1200 ? null : <Form.Check
                                type="switch"
                                id="search-map-switch"
                                label="Map View"
                                checked={mapView}
                                onChange={() => { setMapView(!mapView) }}
                            />}
                        </Col>
                    </Row>
                    <div className='active-badges mt-3'>
                        {ATAppartment ? <Badge bg="" className={styles["filter-badge"]}><div> <text onClick={() => setModalShow(true)}>Apartment</text> <span onClick={handleApartmentClose}><MdOutlineClose /></span></div></Badge> : ''}
                        {ATCondo ? <Badge bg="" className={styles["filter-badge"]}> <div> <text onClick={() => setModalShow(true)}>Condo</text><span onClick={handleCondoClose}><MdOutlineClose /></span></div></Badge> : ''}
                        {ATCottage ? <Badge bg="" className={styles["filter-badge"]}> <div> <text onClick={() => setModalShow(true)}>Cottage</text><span onClick={handleCottageClose}><MdOutlineClose /></span></div></Badge> : ''}
                        {ATHouse ? <Badge bg="" className={styles["filter-badge"]}> <div> <text onClick={() => setModalShow(true)}>House</text><span onClick={handleHouseClose}><MdOutlineClose /></span></div></Badge> : ''}
                        {ATRoom ? <Badge bg="" className={styles["filter-badge"]}> <div> <text onClick={() => setModalShow(true)}>Room</text><span onClick={handleRoomClose}><MdOutlineClose /></span></div></Badge> : ''}
                        {rooms > 0 ? <Badge bg="" className={styles["filter-badge"]}> <div> <text onClick={() => setModalShow(true)}>{rooms}+ Bedrooms</text><span onClick={handleRoomsClose}><MdOutlineClose /></span></div></Badge> : ''}
                        {bathrooms > 0 ? <Badge bg="" className={styles["filter-badge"]}> <div> <text onClick={() => setModalShow(true)}>{bathrooms}+ Bathrooms</text><span onClick={handleBathroomsClose}><MdOutlineClose /></span></div></Badge> : ''}
                    </div>
                    <hr />
                    {screenWidth <= 1200 ? (
                        <button
                            className={styles['view-btn']}
                            onClick={() => setListView(!listView)}
                        >
                            {listView ? (<><MdMap /> Map View</>) : (<><MdViewList /> List View</>)}
                        </button>
                    ) : null}
                    <Row>
                        {(screenWidth <= 1200 && listView) || screenWidth > 1200 ? <Col md={12} xl={mapView ? 8 : 12} sm={mapView ? 12 : 12} className={`${styles["content-column"]} `}>
                            <Row>
                                <Col md={8}>
                                    <h4 className='primary-color mt-0'> {`${propertyCount > 0 && !pageLoader ? propertyCount : propertyCount === 0 ? 'No' : ''} Furnished Apartments in ${city}, ${state}`}</h4>
                                </Col>
                                {screenWidth >=768 ? <Col md={4}>
                                    <PropertySort
                                        sortValue={sortValue}
                                        handleChange={handleChange}
                                    />
                                </Col> : null}
                            </Row>
                            {pageLoader ? <div>
                                <PropertyListLoading total={mapView ? 6 : 8} />
                            </div> : <PropertyList
                                setSelectedProperty={setSelectedProperty}
                                propertyData={propertyData}
                                setPropertyData={setPropertyData}
                                selectedCity={selectedCity}
                                mapView={mapView}
                                isPLLoading={isPLLoading}
                                screenWidth={screenWidth}
                                dispatch={dispatch}
                                mapdata={mapdata}
                                handleChange={handleChange}
                                isLoaded={isLoaded}
                                pageNumber={pageNumber}
                                totalPages={totalPages}
                                NUM_PER_PAGE={NUM_PER_PAGE}
                                TOTAL_COUNT={TOTAL_COUNT}
                                sortValue={sortValue}

                            />}
                        </Col> : null}
                        {mapView && ((screenWidth <= 1200 && !listView) || screenWidth > 1200) ?
                            <Col xl={mapView ? 4 : 12} md={12} sm={12} className={`${styles["map-column"]} `} >
                                {isLoaded && propertyData ? <MapContainer
                                    selectedProperty={selectedProperty}
                                    markers={mapMarkers}
                                    lat={mapdata?.Center ? mapdata?.Center.split(', ')[0] : '41.8781136'}
                                    lng={mapdata?.Center ? mapdata?.Center.split(', ')[1] : '-87.6297982'}
                                    zoom={parseInt(mapdata?.Zoom)}
                                /> :
                                    <div>Loading...</div>
                                }
                            </Col> : ''}
                    </Row>
                </>
                :
                <SearchLanding
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    isLocationEmpty={isLocationEmpty}
                    showToast={showToast}
                    setShowToast={setShowToast}
                    isLocation={isLocation}
                    setIsLocation={setIsLocation}
                    handlePlaceUpdate={handlePlaceUpdate}
                    filters={filters}
                    handleSearch={handleSearch}
                    selectedCity={selectedCity}
                    updateSelectedCity={updateSelectedCity}
                    minBudget={minBudget}
                    setMinBudget={setMinBudget}
                    maxBudget={maxBudget}
                    setMaxBudget={setMaxBudget}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    rooms={rooms}
                    setRooms={setRooms}
                    cities={cities}
                    selectedPlace={selectedPlace}
                    setSelectedPlace={setSelectedPlace}
                    isLoading={isLoading}
                    matchString={matchString}
                    setMatchString={setMatchString}
                    ATRoom={ATRoom}
                    setATRoom={setATRoom}
                    ATHouse={ATHouse}
                    setATHouse={setATHouse}
                    ATCondo={ATCondo}
                    setATCondo={setATCondo}
                    ATCottage={ATCottage}
                    setATCottage={setATCottage}
                    ATAppartment={ATAppartment}
                    setATAppartment={setATAppartment}
                    bathrooms={bathrooms}
                    setBathrooms={setBathrooms}
                    updateFilters={updateFilters}
                    dispatch={dispatch}
                    screenWidth={screenWidth}
                />
            }
            <div className={styles["scroll-wrap"]}>
                <div onClick={executeScroll} className={styles["scroll-icon"]} style={{ display: showScroll ? 'flex' : 'none' }}><MdOutlineArrowUpward /></div>
            </div>
        </Container>
    )
}
export default Search;