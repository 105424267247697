import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPropertyDetails } from './PropertyDetailsAPI';

const initialState: any = {
    loading: false,
    propertyDetails: {}
  
};

export const getPropertyDetailsAsync = createAsyncThunk(
  'property/getPropertyDetails',
  async (propertyId: string) => {
    const response = await getPropertyDetails(propertyId);
    return response?.data?.Data;
  }
);


export const propertyDetailsSlice = createSlice({
  name: 'property-details',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPropertyDetailsAsync.pending, (state) => {
        state.loading = true;
      })

      .addCase(getPropertyDetailsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.propertyDetails = action.payload;
        state.error = "";
      })

      .addCase(getPropertyDetailsAsync.rejected, (state, action) => {
        state.loading = false;
        state.properties = {}
        state.error = action.error.message || 'Something went wrong.';
      });

  },
});

export default propertyDetailsSlice.reducer;
