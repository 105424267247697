import React from 'react';
import Banner from '../../features/Banner/Banner';
import PopularCities from '../../features/popular-cities/PopularCities';
import LearnMore from '../../components/learn-more/LearnMore';
import AboutUs from '../../components/about-us/AboutUs';
import Agencies from '../../components/Featured-Agencies/Agencies';

const Home = () => {
   
    return (
        <>
            <Banner />
            <AboutUs />
            <LearnMore />
            <PopularCities />
            <Agencies />
        </>
    )
}
export default Home;
