import React, { useRef, useState } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import Image1 from "../../../assets/images/blogs/propety_owners/pob1/pob_1_1.png";
import Image2 from "../../../assets/images/blogs/propety_owners/pob1/pob_1_2.png";
import Image3 from "../../../assets/images/blogs/propety_owners/pob1/pob_1_3.png";
import { Link } from "react-router-dom";
import { MdOutlineArrowUpward } from "react-icons/md";
import { FaSpotify, FaYoutube, FaPodcast } from "react-icons/fa";
import LatestArticles from "../latest-articles";
import styles from "../Blog.module.css";

const POB1 = () => {
  const topRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => topRef.current?.scrollIntoView();
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  return (
    <>
      <Container fluid id={"blog-detail"} ref={topRef}>
        <Row>
          <Col md={8} className={styles["blog-left-panel"]}>
            <Row className="mb-2 mt-2">
              <h2 className="primary-color mb-2">
                Revolutionizing Real Estate Lending: Fund That Flip's Innovative
                Solutions
              </h2>

              <span className="fs-6 mb-3">
                Author : FF Team | September 8, 2023
              </span>
            </Row>
            <Image
              src={Image3}
              alt="blog-image-1"
              className={"blog-image mb-3"}
            />

            <p>
              Embark on a journey to Cleveland, Ohio, where we meet Brendan
              Bennett, the VP of Revenue for{" "}
              <a
                href="https://www.fundthatflip.com/"
                target="_blank"
                className="blog-content-link"
              >
                Fund That Flip
              </a>
              . Brendan boasts an impressive personal portfolio of 19 units,
              consisting of 17 long-term rentals and 2 mid-term rentals.
              Discover actionable strategies from Brendan on how to grow your
              portfolio, even in the absence of substantial funds.
              <p>
                In Brendan's words, Fund That Flip is run by investors, for
                investors. Its founder was a short-term rental investor who was
                struggling to obtain the necessary financing through
                conventional lending. He came to the conclusion that he wasn't
                the only one who needed easier, more scalable, and more
                dependable access to capital, and so Fund That Flip was born.
              </p>
              <p>
                The goal of Fund That Flip is to establish itself as a complete
                platform for real estate investors. Adding value at every stage
                of the real estate flipping process, from finding the initial
                property to making an offer to project management and
                construction management. With the acquisition of{" "}
                <a
                  href="https://www.flipperforce.com/"
                  target="_blank"
                  className="blog-content-link"
                >
                  Flipper Force
                </a>
                , a software company that analyzes house flips, estimates rehab
                costs, creates project schedules, and tracks project expenses,
                they are already fulfilling that vision.
              </p>
              <p>
                In 2019, Brendan began his career with Fund That Flip, initially
                working as an underwriter. He was then in charge of figuring out
                how much a property was worth, how extensive renovations would
                be, and whether they were paying the right price for it. He
                eventually switched to Capital Markets and Investor Relations
                after Covid started taking off, where he decided how to raise
                money, who to raise it from, and why they should raise it in the
                first place. Soon after, he transferred to the sales department
                of the business, where he assisted investors in leveraging
                capital to scale their businesses as large as they desired. He
                transitioned from sales to customer service and account
                management before landing in his current role as vice president
                of revenue.
              </p>
              <p>
                Fund That Flip offers a variety of hard money lending services
                to real estate investors and is currently primarily focused on
                serving the east coast, though it is rapidly growing in the
                west. Their platform links borrowers with dependable and
                effective sources of capital, enabling them to quickly and
                easily fund their projects. Fund That Flip provides fix-and-flip
                loans, loans for new construction, and loans for rental
                properties, with a focus on residential properties. By providing
                competitive rates, adaptable terms, and a streamlined
                application and approval process, they work to make the lending
                process as simple as possible.
              </p>
              <h3 className="primary-color">Speed Bumps When Scaling</h3>
              <p>
                Many real estate investors face difficulties when attempting to
                scale their business with traditional loans. These traditional
                loan options frequently rely heavily on personal income, tax
                returns, and strict lending criteria, making it difficult for
                investors to quickly and efficiently expand their portfolios.
                The time-consuming paperwork and lengthy process involved in
                obtaining traditional loans can be a significant barrier for
                investors looking to capitalize on opportunities and expand
                their real estate ventures.
              </p>
              <p>
                The strict scrutiny placed on personal financial factors is one
                of the primary issues with traditional loans. Lenders frequently
                require detailed documentation of personal income, credit
                history, and debt-to-income ratios, which can be difficult for
                investors with unusual income streams or multiple investment
                properties. These stringent requirements can limit real estate
                investors' borrowing capacity and slow their businesses' growth.
              </p>
              <p>
                Furthermore, traditional loans may have restrictions on the
                number of properties an investor can finance at the same time.
                Many lenders limit the maximum number of loans they will make,
                which can prevent investors from expanding their portfolios
                beyond a certain point. This limitation can be especially
                difficult for investors who want to take advantage of multiple
                investment opportunities or diversify their holdings.
              </p>
              <h3 className="primary-color">DSCR Loans</h3>
              <p>
                Fund That Flip excels with a specialized type of loan known as a
                DSCR loan. A "debt service coverage ratio" (DSCR) compares your
                monthly debt service to your expected property earnings each
                month. DSCR loans provide a straightforward and streamlined
                process in contrast to conventional loans, which take personal
                income, tax returns, and other factors into account. They are
                made to be more practical and effective, easing the burden of
                copious paperwork and drawn-out processes.
              </p>
              <p>
                With DSCR loans, the property's income potential rather than the
                borrower's individual financial situation is the main focus.
                Lenders can assess a borrower's capacity to pay off debt by
                evaluating the anticipated monthly income the property will
                produce. This method streamlines and expedites the loan
                application and approval process by doing away with the need for
                intricate documentation and financial analysis related to
                personal income and tax returns.
              </p>
              <p>
                The advantage of DSCR loans lies in their simplicity and
                efficiency. They provide an alternative lending option for
                individuals and businesses looking to finance their property
                investments without the extensive paperwork and time
                requirements associated with traditional loans. By shifting the
                focus to the property's income potential, borrowers can access
                the funds they need with a simplified application process,
                allowing them to expedite their investment plans and seize
                opportunities in a more timely manner.
              </p>
              <p>
                Brendan notes that there often is a negative stigma associated
                with other lenders in this field for being out to get the
                investor with exorbitant fees, penalties, and attempts to seize
                the investor's property if a loan payment is missed. The
                difference between Fund That Flip and other companies is that
                they don't own properties. They just want to support investors.
                They are willing to work with their investors to come up with a
                plan to keep them afloat if something unforeseen occurs and
                payments start to get missed. Again, they're in the business of
                helping those they lend to, not owning their properties.
              </p>
              <h3 className="primary-color">
                Additional Opportunities for Investment
              </h3>
              <p>
                An intriguing feature of Fund That Flip is the ability for
                passive investors to directly invest in the loans. While Fund
                That Flip does receive funding from traditional sources such as
                large institutional Real Estate Investment Trusts (REIT),
                insurance companies, and others, they also receive funding from
                accredited individuals looking to invest a small sum, say
                $5,000, to support an individual loan and profit from the
                interest on that loan.
              </p>
              <h3 className="primary-color">
                Real Estate Investing: Unscripted
              </h3>
              <Image
                src={Image2}
                alt="blog-image-2"
                className={"blog-image mb-3"}
                // style={{ width:"500px", float: 'left', marginRight: '16px', marginBottom: '16px' }}
              />
              <p>
                Real Estate Investing: Unscripted, the podcast that Brendan also
                runs, has a wide range of guests, including real estate data
                analysts, economists, and other market influencers. The
                conversations are through the lens of Fund That Flip's
                customers: wholesalers, flippers, builders and passive
                investors. The podcast will soon feature an episode with Brian
                Payne, the CEO of Furnished Finder.
              </p>
              <h3 className="primary-color">Brendan's Personal Portfolio</h3>
              <p>
                Brendan currently owns 19 properties, 17 of which are long-term
                rentals and two of which are short term / mid term rentals. He
                started his real estate investing journey as many do, by house
                hacking duplexes.
              </p>
              <p>
                House hacking is a real estate investment strategy in which
                individuals buy a property and live in one section while renting
                out the remaining units or rooms to tenants. This strategy
                allows investors to generate rental income while also lowering
                their own housing costs. House hacking, which typically involves
                properties with multiple units, such as duplexes or triplexes,
                uses the rental income from the additional units to cover a
                significant portion, if not all, of the property expenses, such
                as the mortgage and taxes.
              </p>
              <p>
                House hacking provides several advantages to real estate
                investors. For starters, it allows you to begin building wealth
                through real estate without making a large initial investment.
                Tenant rental income can be used to offset mortgage payments,
                reduce housing costs, or even allow the investor to live
                rent-free, increasing the investor's cash flow and potentially
                allowing them to save for future investments.
              </p>
              <p>
                Having only recently entered the mid-term rental market, his
                first impression is that it is far more low-key in terms of
                tenant interaction and turnovers than short-term rentals, which
                he has dabbled with in the past.
              </p>
              <h3 className="primary-color">Getting His Start</h3>
              <p>
                His family assisted him in his first foray into real estate
                investment. His uncle knew he was interested in real estate and
                was willing to provide private lending as long as he put in the
                sweat equity of finding, remodeling, and eventually doing a
                cash-out refinance to pay off the initial loan. Brendan and his
                uncle did this on his first four properties before moving on to
                more traditional loans.
              </p>
            </p>
            <Image
              src={Image1}
              alt="blog-image-1"
              className={"blog-image mb-3"}
              // style={{ width:"500px", float: 'left', marginRight: '16px', marginBottom: '16px' }}
            />

            <div>
              <h3 className="primary-color">Capping Utility Expenses</h3>
              <p>
                One way to feel more secure about your rental's expenses is to
                set a limit on what you, the owner, will pay for utilities such
                as electricity and water. By informing the tenant that there is
                a limit to what will be paid for and that anything above that
                will be the tenant's responsibility, you plant the seed in their
                mind that they must be responsible and not wasteful.
                Essentially, they must treat your rental as if it were their own
                home rather than a hotel or short-term vacation rental where
                caution and responsible use of resources may get thrown to the
                wind.
              </p>

              <h3 className="primary-color">
                Advice for Investors Just Starting Out
              </h3>
              <p>
                Brendan emphasizes the advantages of owning a duplex. He keeps a
                long-term tenant in one unit who essentially pays the mortgage.
                The second unit he rents out mid-term and earns a higher profit.
                While he could rent both units out mid-term and maximize his
                profit, he prefers the security of a mix of short- and long-term
                rentals.
              </p>
            </div>
            <div className="podcasts mb-3">
              <h4> Listen to the Podcast here</h4>
              <a
                href="https://www.youtube.com/watch?v=AQuMtxQSq8A&feature=youtu.be"
                className={`me-3`}
                target="_blank"
              >
                <FaYoutube style={{ color: "FF0000", fontSize: "30px" }} />
              </a>
              <a
                href="https://open.spotify.com/episode/2pGOV5BhdFA4LHZUwuDVmE?si=X_kprBJGRsKwKYMeHBraqQ&nd=1"
                className="me-3"
                target="_blank"
              >
                <FaSpotify style={{ color: "#1DB954", fontSize: "28px" }} />
              </a>
              <a
                href="https://podcasts.apple.com/us/podcast/the-landlord-diaries/id1641540804"
                className="me-3"
                target="_blank"
              >
                <FaPodcast style={{ color: "#872ec4", fontSize: "26px" }} />
              </a>
            </div>
            <hr />
            <div className="mb-3 back-link">
              <Link to="/blog" className="primary-color fs-6">
                Back to Blog Home Page
              </Link>
            </div>
          </Col>
          <Col
            md={4}
            className={`${styles["blog-right-panel"]} ${styles["latest-column"]}`}
          >
            <LatestArticles />
          </Col>
        </Row>
        {/* <Container>
            <h3>RELATED ARTICLES</h3>
            <Row>
                <Col>
                <BlogCard/>
                </Col>
                <Col>
                <BlogCard/>
                </Col>
                <Col>
                <BlogCard/>
                </Col>
            </Row>
            </Container> */}
      </Container>
      <div className={"scroll-wrap"}>
        <div
          onClick={executeScroll}
          className={"scroll-icon"}
          style={{ display: showScroll ? "flex" : "none" }}
        >
          <MdOutlineArrowUpward />
        </div>
      </div>
    </>
  );
};

export default POB1;
