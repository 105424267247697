import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import {
  getCityStateAsync,
  incrementRoom,
  decrementRoom,
  updateSelectedCity,
  updatePriceRange,
} from "./BannerSlice";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Toast,
  ToastContainer,
  Image,
} from "react-bootstrap";
import styles from "./Banner.module.css";
import {
  MdAddCircleOutline,
  MdRemoveCircleOutline,
  MdKeyboardArrowRight,
} from "react-icons/md";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import { Slider } from "antd";
import { updateFilters } from "../property-list/propertyListSlice";


let useClickOutside = (handler: any) => {
  let domNode = useRef<any>();
  useEffect(() => {
    let maybeHandler = (event: any) => {
      if (
        domNode &&
        domNode?.current &&
        !domNode.current.contains(event.target)
      ) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Banner: React.FC = () => {
  let cities = useAppSelector((state) => state.places.cities);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const today = new Date();
  let currentMonth = today.getMonth();
  let currentYear = today.getFullYear();
  let toYear = currentYear + 10;

  const [selectedDay, setSelectedDay] = useState<Date | undefined>(today);

  const [selectedPlace, setSelectedPlace] = useState<string[]>([]);
  const [isLocationEmpty, setIsLocationEmpty] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const selectedCity = useAppSelector((state) => state.places.selectedCity);
  const [matchString, setMatchString] = useState("");
  const navigate = useNavigate();

  const searchPage = () => {
    if (selectedPlace.length === 0) {
      setIsLocationEmpty(true);
      setShowToast(true);
      return;
    }
    if (selectedPlace.length > 0) {
      setIsLocationEmpty(false);
    }
    dispatch(updateSelectedCity([...selectedPlace]));
    dispatch(
      updateFilters({
        rooms,
        selectedDay: selectedDay ? selectedDay : today?.toISOString(),
        minBudget,
        maxBudget,
      })
    );
    navigate({
      pathname: "search-map",
      search: `?city=${selectedPlace[0]?.split(", ")[0]}&state=${
        selectedPlace[0]?.split(", ")[1]
      }&filters=${JSON.stringify({
        rooms,
        selectedDay: selectedDay ? selectedDay : today?.toISOString(),
        minBudget,
        maxBudget,
      })}`,
    });
  };

  const [minBudget, setMinBudget] = useState(20);
  const [maxBudget, setMaxBudget] = useState(10000);

  const rooms = useAppSelector((state) => state.places.rooms);

  useEffect(() => {
    dispatch(getCityStateAsync(matchString));
  }, [matchString]);

  useEffect(() => {
    if (selectedCity[0] === selectedPlace[0]) {
      setIsLocation(false);
    }
  }, [selectedCity]);

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 2000);
    }
  }, [showToast]);

  const filterBy = () => true;

  const [isLocation, setIsLocation] = useState(false);
  let domNode = useClickOutside(() => {
    setIsLocation(false);
  });
  const [isPriceRange, setIsPriceRange] = useState(false);
  let priceNode = useClickOutside(() => {
    setIsPriceRange(false);
  });
  const [isDate, setIsDate] = useState(false);
  let dateNode = useClickOutside(() => {
    setIsDate(false);
  });
  const [isBedroom, setIsBedroom] = useState(false);
  let bedNode = useClickOutside(() => {
    setIsBedroom(false);
  });
  let typeRef = useRef<any>();

  const handlePlaceUpdate = (place: any) => {
    setSelectedPlace(place);
    dispatch(updateSelectedCity(place));
  };

  const onAfterChange = (value: any) => {
    setMinBudget(value[0]);
    setMaxBudget(value[1]);
    updatePriceRange(value);
  };
  return (
    <section className={`${styles.homeBanner}  mb-lg-5`}>
      <ToastContainer position="top-center" className={styles["toast-all"]}>
        <Toast
          onClose={() => setShowToast(false)}
          data-testid="toast"
          autohide={true}
          show={showToast}
          bg="danger"
          delay={3000}
        >
          <Toast.Body>Please select a location</Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid>
        <div className={`${styles.innerWrap} `}>
          <Container className="section-padding">
            <Row>
              <Col lg={6}>
                <h1 className={`${styles["z-index-1"]} mb-2 position-relative`}>
                  Furnished Apartments Nationwide
                </h1>
                <h5
                  className={`${styles["z-index-1"]} sub-title position-relative`}
                >
                  Furnished apartments, condos, cottages,
                  <br className="d-none d-md-block" /> guesthouses, homes and
                  rooms
                </h5>
              </Col>
              <Col xl={9} lg={9}>
                <div className={`${styles.searchBox} mb-4`}>
                  <Row className="bannerRow gx-0">
                    <Col xl md={6} ref={domNode}>
                      <div className={styles["dropdown"]}>
                        <label className={styles["label"]}>Location</label>
                        <AsyncTypeahead
                          filterBy={filterBy}
                          id="city"
                          align="justify"
                          isLoading={isLoading}
                          labelKey={(option) => `${option}`}
                          onChange={(place: any) => {
                            handlePlaceUpdate(place);
                          }}
                          className={styles["location-input"]}
                          selected={selectedPlace}
                          minLength={3}
                          onSearch={(query) => {
                            setMatchString(query);
                          }}
                          options={cities}
                          ref={typeRef}
                          placeholder="Enter destination"
                          renderMenuItemChildren={(option: any) => (
                            <>
                              <span>{option}</span>
                            </>
                          )}
                        />
                      </div>
                    </Col>

                    <Col xl md={6} ref={dateNode}>
                      <div className={styles["dropdown"]}>
                        <label className={styles["label"]}>When</label>
                        <button
                          className={styles["dropbtn"]}
                          onClick={() => {
                            setIsDate(!isDate);
                          }}
                        >
                          {selectedDay ? (
                            <span className="w-max-content">
                              {moment(selectedDay).format("L")}
                            </span>
                          ) : (
                            "Move-in date"
                          )}{" "}
                          {selectedDay ? "" : <Image src={ArrowDown} />}
                        </button>
                        <div
                          className={styles["dropdown-content"]}
                          style={
                            isDate ? { display: "block" } : { display: "none" }
                          }
                        >
                          <Form className={styles["date-form "]}>
                            <div className="pe-3 ps-3">
                              <Form.Group
                                className="mb-3 align-items-center d-flex justify-content-between"
                                controlId="formHorizontalEmail"
                              >
                                <Form.Label
                                  column
                                  sm={4}
                                  className="primary-color"
                                >
                                  Check-in
                                </Form.Label>
                                <div className={styles["date-input"]}>
                                  {selectedDay
                                    ? moment(selectedDay).format("L")
                                    : "Move-in date"}
                                </div>
                              </Form.Group>
                            </div>
                          </Form>
                          <hr className={styles["dropdown-divider"]} />

                          <DayPicker
                            mode="single"
                            className="banner-calendar"
                            selected={selectedDay}
                            onSelect={setSelectedDay}
                            captionLayout="dropdown"
                            fromYear={currentYear}
                            toYear={toYear}
                            disabled={{ before: today }}
                          />
                          <div className={`d-md-none d-xl-none d-block`}>
                            <hr className={styles["dropdown-divider"]} />
                            <p
                              className="text-end primary-color pt-2 pe-3"
                              onClick={() => {
                                setIsDate(false);
                                setIsPriceRange(true);
                              }}
                            >
                              <u>Next</u> <MdKeyboardArrowRight />
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xl md={6} ref={priceNode}>
                      <div className={styles["dropdown"]}>
                        <label className={styles["label"]}>Price</label>
                        <button
                          className={styles["dropbtn"]}
                          onClick={() => {
                            setIsPriceRange(!isPriceRange);
                          }}
                        >
                          {minBudget > 20 && maxBudget === 10000 ? `$${minBudget}-$${maxBudget}+` : minBudget > 20 || maxBudget < 10000
                            ? `$${minBudget}-$${maxBudget}`
                            : "Any Range"}{" "}
                          {minBudget > 20 ? null : <Image src={ArrowDown} />}
                        </button>
                        <div
                          className={styles["dropdown-content"]}
                          style={
                            isPriceRange
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className={styles["dropdown-title"]}>
                              Price per month{" "}
                            </h6>
                            <span className="pe-3">
                              {`$${minBudget}-$${maxBudget}${maxBudget === 10000 ? '+' : '' }  ` }
                            </span>
                          </div>

                          <div className="p-3">
                            <Slider
                              range
                              min={20}
                              max={10000}
                              defaultValue={[20, 10000]}
                              onAfterChange={onAfterChange}
                              tooltip={{
                                formatter: (value) => {
                                  return value === 10000 ? `${value}+` : value;
                                }
                              }}
                            />
                            <div className="d-flex justify-content-between align-items-center   ">
                              <p className="mb-0 fs-6">Min $20</p>
                              <p className="mb-0 fs-6">Max $10000+</p>
                            </div>
                          </div>
                          <div className={`d-md-none d-xl-none d-block`}>
                            <hr className={styles["dropdown-divider"]} />
                            <p
                              className="text-end primary-color pt-2 pe-3"
                              onClick={() => {
                                setIsPriceRange(false);
                                setIsBedroom(true);
                              }}
                            >
                              <u>Next</u> <MdKeyboardArrowRight />
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col xl md={6} ref={bedNode} className={styles["bed-ddn"]}>
                      <div className={styles["dropdown"]}>
                        <label className={styles["label"]}>Bedrooms</label>
                        <button
                          className={styles["dropbtn"]}
                          onClick={() => {
                            setIsBedroom(!isBedroom);
                          }}
                        >
                          {rooms > 0 && rooms < 10
                            ? rooms
                            : rooms === 10
                            ? "10+"
                            : "Any Bedrooms"}{" "}
                          {rooms > 0 ? "" : <Image src={ArrowDown} />}
                        </button>
                        <div
                          className={styles["dropdown-content"]}
                          style={
                            isBedroom
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <h6 className={styles["dropdown-title"]}>
                            No. of bedrooms
                          </h6>
                          <div>
                            <div
                              className={`fs-5 p-3  align-items-center justify-content-center d-flex`}
                            >
                              <MdRemoveCircleOutline
                                className="primary-color me-3"
                                onClick={() => dispatch(decrementRoom())}
                              />
                              <span className="me-1">{rooms}</span>{" "}
                              <MdAddCircleOutline
                                className="primary-color ms-3"
                                onClick={() => dispatch(incrementRoom())}
                              />
                            </div>
                          </div>
                          <div className={`d-md-none d-xl-none d-block`}>
                            <hr className={styles["dropdown-divider"]} />
                            <p
                              className="text-end primary-color pt-2 pe-3"
                              onClick={() => {
                                setIsBedroom(false);
                              }}
                            >
                              <u>Done</u>
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Col xs={12} sm={5} md={3} xl={3} xxl={2}>
                  <Button
                    className="w-100 z-index-1"
                    onClick={() => {
                      searchPage();
                    }}
                  >
                    Search Map
                  </Button>
                  <div className={styles["sm-toast"]}>
                    <Toast
                      onClose={() => setShowToast(false)}
                      data-testid="toast"
                      autohide={true}
                      show={showToast}
                      bg="danger"
                      delay={3000}
                    >
                      <Toast.Body>Please select a location</Toast.Body>
                    </Toast>
                  </div>
                </Col>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </section>
  );
};
export default Banner;
