import React from 'react';
import { Tab, Nav, Container } from 'react-bootstrap';
import BlogList from './BlogList';
import POBlogList from './POBlogList';
import styles from './BlogList.module.css';

const BlogContainer = () => {
    return (
        <Container className={`${styles["blog-container"]} mb-5`}>
            <h1>Blog Categories</h1>
            <Tab.Container defaultActiveKey="all">

                <Nav className={`switch-tabs mb-5`} variant="pills">
                    <Nav.Item className={`${styles["nav-content"]} m-1`}>
                        <Nav.Link eventKey="all">All blogs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styles["nav-content"]} m-1`}>
                        <Nav.Link eventKey="city-guides">City guides</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styles["nav-content"]} m-1`}>
                        <Nav.Link eventKey="travellers">Travellers</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className={`${styles["nav-content"]} m-1`}>
                        <Nav.Link eventKey="property-owner">Property Owner</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="all">
                        <h2 className='mb-3'>All Blogs</h2>
                        <POBlogList />

                    </Tab.Pane>
                    <Tab.Pane eventKey="city-guides">
                        <h2 className='mb-3'>City Guides</h2>


                        <BlogList />

                    </Tab.Pane>
                    <Tab.Pane eventKey="travellers">
                        <h2 className='mb-3'>Travellers</h2>

                        <BlogList />

                    </Tab.Pane>
                    <Tab.Pane eventKey="property-owner">
                        <h2 className='mb-3'>Property Owner</h2>

                        <POBlogList />

                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>


        </Container>
    )
}
export default BlogContainer;
