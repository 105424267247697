import React from 'react';
import styles from './PropertyList.module.css'
import PropertySearchBar from '../../components/PropertySeachBar/PropertySearchBar';
const SearchLanding = (props: any) => {
  return (
    <div className={`mb-4 mt-4 ${styles['search-landing']}`}>
    <div className={`${styles["search-title"]} text-center fs-2 mt-1`}>Nationwide  Furnished Apartments <br className='sm-hide'/>Homes, Condos and more.</div>
    {/* <div className={`${styles["search-title"]} text-center lh-2 fs-2`}></div> */}
    <div className={`mt-3 ${styles['search-box']}`}>
        <PropertySearchBar
            modalShow={props.modalShow}
            setModalShow={props.setModalShow}
            isLocationEmpty={props.isLocationEmpty}
            showToast={props.showToast} 
            setShowToast={props.setShowToast}
            isLocation={props.isLocation}
            setIsLocation={props.setIsLocation}
            handlePlaceUpdate={props.handlePlaceUpdate}
            filters={props.filters}
            handleSearch={props.handleSearch}
            selectedCity={props.selectedCity}
            updateSelectedCity={props.updateSelectedCity}
            minBudget={props.minBudget}
            setMinBudget={props.setMinBudget}
            maxBudget={props.maxBudget}
            setMaxBudget={props.setMaxBudget}
            selectedDay={props.selectedDay}
            setSelectedDay={props.setSelectedDay}
            rooms={props.rooms}
            setRooms={props.setRooms}
            cities={props.cities}
            selectedPlace={props.selectedPlace}
            setSelectedPlace={props.setSelectedPlace}
            isLoading={props.isLoading}
            matchString={props.matchString}
            setMatchString={props.setMatchString}
            ATRoom={props.ATRoom}
            setATRoom={props.setATRoom}
            ATHouse={props.ATHouse}
            setATHouse={props.setATHouse}
            ATCondo={props.ATCondo}
            setATCondo={props.setATCondo}
            ATCottage={props.ATCottage}
            setATCottage={props.setATCottage}
            ATAppartment={props.ATAppartment}
            setATAppartment={props.setATAppartment}
            bathrooms={props.bathrooms}
            setBathrooms={props.setBathrooms}
            updateFilters={props.updateFilters}
            dispatch={props.dispatch}
            screenWidth={props.screenWidth}
        />
    </div>
</div>
  )
}

export default SearchLanding;