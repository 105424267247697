import axiosInstance from "../../lib/axios";

export const postContactForm = (params: any) => {
    return axiosInstance
        .post(`/Feedback/SendFeedback/`, 
        {
            "Email" : params.email,
            "Department" : params.department,
            "Comment" : params.message,
            "Name" : params.name,
            "Department2" : "",
            "Phone" : params.phone,
            'Source': 'FA'
          }

        )
        .then((response) => {
            return { data: response.data }
        })
        .catch((error) => {
            if (error && error.response) {
                if (error.response) {
                    return { data: error.response.data }
                }
            }
        });
};

