import React from 'react';
import { Select } from 'antd';


const PropertySort = (props: any) => {
  return (
    <div className=' d-flex align-items-center justify-content-end'>
      <Select
        defaultValue="availability"
        className="filter_by_sort filter_ant-select"
        onChange={props.handleChange}
        value={props.sortValue}
        options={[
          { value: 'availability', label: 'Availability' },
          { value: 'lth', label: 'Price: low to high' },
          { value: 'htl', label: 'Price: high to low' },
        ]}
      />
    </div>
  )
}

export default PropertySort;