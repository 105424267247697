import { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { MdOutlineArrowUpward } from "react-icons/md";

const FAQ = () => {
  const currentDate = new Date();
  const yearEnd = new Date("2023-12-31");
  const [isShow1, setShow1] = useState(false);
  const [isShow2, setShow2] = useState(false);
  const [isShow3, setShow3] = useState(false);
  const [isShow4, setShow4] = useState(false);
  const [isShow5, setShow5] = useState(false);
  const [isShow6, setShow6] = useState(false);
  const [isShow7, setShow7] = useState(false);
  const [isShow8, setShow8] = useState(false);
  const [isShow9, setShow9] = useState(false);
  const [isShow10, setShow10] = useState(false);
  const [isShow11, setShow11] = useState(false);
  const [isShow12, setShow12] = useState(false);
  const [isShow13, setShow13] = useState(false);
  const [isShow14, setShow14] = useState(false);
  const [isShow15, setShow15] = useState(false);
  const [isShow16, setShow16] = useState(false);
  const [isShow17, setShow17] = useState(false);
  const [isShow18, setShow18] = useState(false);
  const [isShow19, setShow19] = useState(false);
  const [isShow20, setShow20] = useState(false);
  const [isShow21, setShow21] = useState(false);
  const [isShow22, setShow22] = useState(false);
  const [isShow23, setShow23] = useState(false);
  const [isShow24, setShow24] = useState(false);
  const [isShow25, setShow25] = useState(false);
  const [isShow26, setShow26] = useState(false);
  const [isShow27, setShow27] = useState(false);
  const [isShow28, setShow28] = useState(false);
  const [isShow29, setShow29] = useState(false);
  const [isShow30, setShow30] = useState(false);
  const [isShow31, setShow31] = useState(false);
  const [isShow32, setShow32] = useState(false);
  const [isShow33, setShow33] = useState(false);
  const [isShow34, setShow34] = useState(false);

  const topRef = useRef<HTMLDivElement>(null);

  const executeScroll = () => topRef.current?.scrollIntoView();

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  return (
    <section className={`faq-page`} ref={topRef}>
      <Container fluid>
        <h4 className="primary-color">FAQ - Traveling Nurses</h4>
        <div className="border-bottom">
          <div onClick={() => setShow1(!isShow1)}>
            <h5>How does this work?</h5>
          </div>
          <p style={{ display: isShow1 ? "block" : "none" }}>
            When you need short term housing, you start by submitting a housing
            request so property owners with availability in your search area can
            reach out to you. From there, you chat with the property(s) that fit
            your needs… if one of them is a winner, you work with the property
            owner directly to secure the unit.
          </p>
        </div>
        <div className="border-bottom">
          <div onClick={() => setShow2(!isShow2)}>
            <h5>Do you charge the traveler?</h5>
          </div>
          <p style={{ display: isShow2 ? "block" : "none" }}>
            Nope. We never charge our travelers a dime.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow3(!isShow3)}>
            <h5>Is there a minimum stay?</h5>
          </div>
          <p style={{ display: isShow3 ? "block" : "none" }}>
            We mostly focus on 30-day minimum stays (with an average length of
            stay of 95+ days), but we understand your housing needs may vary and
            we encourage our travelers to negotiate with individual property
            owners and hotels that might be open to hosting you for shorter
            periods of time.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow4(!isShow4)}>
            <h5>How do I book a property?</h5>
          </div>
          <p style={{ display: isShow4 ? "block" : "none" }}>
            You reach out to the properties you’re interested in and book
            directly with the property owner when you’re ready. Since you book
            directly with our owners, there are no mark-ups, extra fees, or
            commissions which is how we keep housing affordable. Each landlord
            has their own rental process in place, which might include a quick
            screening and lease agreement via our preferred partner KeyCheck.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow5(!isShow5)}>
            <h5>I just submitted a housing request...now what?</h5>
          </div>
          <p style={{ display: isShow5 ? "block" : "none" }}>
            Don’t stop there! Search our map to see what’s out there! If you see
            something you want, don’t wait for them to contact you. Chat through
            the app! Available short-term housing can move pretty
            quickly...especially the most unique & affordable options.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow6(!isShow6)}>
            <h5>Why did it ask for my cell phone carrier?</h5>
          </div>
          <p style={{ display: isShow6 ? "block" : "none" }}>
            So we can enable text alerts. Many travelers, and property owners
            for that matter, prefer to chat via text because it’s fast and easy
            to use. You can text "STOP" anytime to cancel.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow7(!isShow7)}>
            <h5>
              I found a place on another site and I want to make sure it’s not a
              scam. Can you help me?
            </h5>
          </div>
          <p style={{ display: isShow7 ? "block" : "none" }}>
            Absolutely! We want our travelers to be safe and protected no matter
            where they find furnished housing! Submit a free Owner Verification
            request, and we’ll shoot you a report that tells you the owner’s
            name and other property details so you can make the best decision.
            (doesn’t verify sublets).
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow8(!isShow8)}>
            <h5>What if my assignment cancels and I need to leave early?</h5>
          </div>
          <p style={{ display: isShow8 ? "block" : "none" }}>
            This is totally up to the property owner you hook up with. Make sure
            to ask what type of notice is required to submit before you leave
            (30 days notice is common), and understand their policies about
            leaving before the term is completed. Every property is different
            and so are their policies, so be sure to find out these answers
            before you sign your lease. Tip: if you find out that a property
            owner requires a 30 day notice, set a calendar item on your phone as
            a reminder!
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow9(!isShow9)}>
            <h5>Can I report a property?</h5>
          </div>
          <p style={{ display: isShow9 ? "block" : "none" }}>
            Yes. If a property is less than forthright or using questionable
            tactics, we want to know about it immediately! Property owners agree
            to our terms of use and are expected to conduct themselves
            professionally at all times. Any property can be reported at any
            time. Look for the report property icon on every listing, or contact
            us with more information.
          </p>
        </div>

        <h4 className="primary-color">FAQ - Property Owners</h4>

        <div className="border-bottom">
          <div onClick={() => setShow10(!isShow10)}>
            <h5>Do you specialize in traveling nurses for your traffic?</h5>
          </div>
          <div style={{ display: isShow10 ? "block" : "none" }}>
            <p>
              Yes, most of our traffic is from traveling nurses, allied health
              professionals, and locum tenens. They trust us for all their
              housing needs. We are by far and away the nation's leader in
              traveling nurse housing.
            </p>
            <p>
              Our system is also open for other types of non-medical traveling
              professionals, such as athletes, engineers, military and
              relocating families. Due to the similarities in how they travel,
              and our expertise within the online housing industry, we are able
              to serve them as well.
            </p>
          </div>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow11(!isShow11)}>
            <h5>
              Can I get notified when a travel nurse is coming to my area?
            </h5>
          </div>
          <p style={{ display: isShow11 ? "block" : "none" }}>
            Yes. If a property is less than forthright or using questionable
            tactics, we want to know about it immediately! Property owners agree
            to our terms of use and are expected to conduct themselves
            professionally at all times. Any property can be reported at any
            time. Look for the report property icon on every listing, or contact
            us with more information.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow12(!isShow12)}>
            <h5>Can I list a room?</h5>
          </div>
          <p style={{ display: isShow12 ? "block" : "none" }}>
            Yes! We hear from homeowners around the nation who have an extra
            room or two, that choose to rent to travel nurses because they're
            employed, professional, and predictable as a tenant group. Our
            travelers make great tenants, and rooms are an important part of our
            travel nurse housing strategy nationwide... especially in high-cost
            cities.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow13(!isShow13)}>
            <h5>
              How is this different than vacation rental or home-sharing sites?
            </h5>
          </div>
          <p style={{ display: isShow13 ? "block" : "none" }}>
            Furnished Finder is heavily concentrated in healthcare and focuses
            mainly on 30 day and greater stays. An average term for this type of
            traveler is just over 3 months which is very attractive for the
            traditional vacation rental owner!
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow14(!isShow14)}>
            <h5>Is the location of my property important?</h5>
          </div>
          <p style={{ display: isShow14 ? "block" : "none" }}>
            We have a high concentration of traveling healthcare professionals,
            so for some landlords it’s ideal to be within a reasonable driving
            distance to a hospital. While on-call healthcare travelers need to
            be with 15 minutes of their assignment typically, the majority of
            our travelers work regular shifts and can commute a little further
            for the right place.Many other traveling professionals frequently
            visit our website to search for furnished housing. This includes
            military, tech, and professional athletes to name a few. Their needs
            are spread across the city so the location of your home does not
            need to be right next to a hospital. Our travelers (both medical and
            non-medical, heavily rely on hotels as well.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow15(!isShow15)}>
            <h5>
              I’m new to this; how should I prepare my unit for a travel nurse?
            </h5>
          </div>
          <div style={{ display: isShow15 ? "block" : "none" }}>
            <p>
              Travel nurses are professionals who stay 3 months at a time. They
              are looking for affordable housing that is clean and safe.
              Properties on our map need to be all 3 (affordable, clean, & safe)
              because that’s what our travelers expect.
            </p>
            <p>
              You can stock the place with a few essentials for them to get
              started, but they don’t expect housekeeping, fruit-baskets,
              coffee, or mini-shampoos. All those things are nice (especially
              the coffee!) but certainly not expected.
            </p>
            <p>
              Here’s what they want:
              <br />
              All utilities should be included in the rent. Bedding & all linens
              (two sets are nice!). Kitchenware, bathware, and essential
              housewares. WIFI is practically a must. If you don’t have it...get
              it. SmartTV’s in their room are preferred so they can unwind after
              a long shift by watching their favorite netflix movie or hulu
              show. (shows and movies streamed via the internet on a smart tv).
              Extras include laundry in unit/on site, assigned parking/carport,
              and pets.
            </p>
          </div>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow16(!isShow16)}>
            <h5>Do travelers ever bring a pet?</h5>
          </div>
          <p style={{ display: isShow16 ? "block" : "none" }}>
            Yes. According to our internal polling of a couple hundred travel
            nurses, about 1/3 travel with a pet. Travelers with pets expect to
            pay a reasonable amount of extra deposit because, well... accidents
            happen. You Do Not have to accept pets to list on our site.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow17(!isShow17)}>
            <h5>How do I screen the tenant?</h5>
          </div>
          <p style={{ display: isShow17 ? "block" : "none" }}>
            With KeyCheck,our partner site who provides free tenant screening
            for Furnished Finder landlords. You simply create a KeyCheck account
            and start screening. (Screening fees paid by traveler).
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow18(!isShow18)}>
            <h5>Who do I collect rent from?</h5>
          </div>
          <p style={{ display: isShow18 ? "block" : "none" }}>
            Most of the time you’ll work with the stipend traveler directly,
            however sometimes a medical staffing company may be the payer. About
            75% of travel nurses will choose to take the housing stipend and
            manage housing on their own, but there are still some that prefer
            that the company manage their housing.You can use KeyCheck to
            collect rent efficiently and 100% online.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow1(!isShow1)}>
            <h5>Should I charge a deposit ?</h5>
          </div>
          <p style={{ display: isShow1 ? "block" : "none" }}>
            Yes, you need to be comfortable with the person who's living in your
            unit, so we always recommend you secure the unit by collecting a
            refundable security deposit. That said, if you charge too much, it
            will deter many travel nurses. The days of requiring first and last
            month’s rent upfront are gone... the sweet spot is typically 1/3
            rent. Charging a one-time cleaning fee (typically $100-$125)
            typically makes sense as well.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow19(!isShow19)}>
            <h5>What can I do to make my listing stand out?</h5>
          </div>
          <p style={{ display: isShow19 ? "block" : "none" }}>
            Choose the Owner Verification Badge at checkout! Our travelers look
            for new housing every 2 months and go to great lengths to protect
            themselves. When you go through the verification process, it gives
            the traveler that extra reassurance that you are the owner on record
            with the county and are not currently going through a foreclosure.
            This badge gives them the assurance that you’ve gone through an
            extra layer of vetting and can be trusted as a reputable landlord.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow20(!isShow20)}>
            <h5>Is there a process I need to go through to be approved?</h5>
          </div>
          <p style={{ display: isShow20 ? "block" : "none" }}>
            Certainly. We like to have a conversation before you list to make
            sure your property is right for our nurses, and to ensure we provide
            the answers you need regarding our service and about renting a
            furnished property short term. Every listing has to meet our
            criteria to help ensure accuracy and accord.We also verify you
            through our Fraud Prevention partners as part of your registration,
            please refer to your listing verification page for more info.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow21(!isShow21)}>
            <h5>
              What’s involved in listing my property and are there any fees?
            </h5>
          </div>

          <div style={{ display: isShow21 ? "block" : "none" }}>
            <p>
              {" "}
              Property Owners/Landlords: Once you add your listing on Furnished
              Finder, you’re eligible to start receiving travelers from us. It’s
              simply {currentDate <= yearEnd ? "$99" : "$149"} for a 12 month
              listing. That’s it - just a one-time listing fee which is good for
              12 months. We don’t charge commissions or booking fees as you work
              directly with the traveler...and there are never any markups to
              the rent.
            </p>
            <p>
              Hotels/Apartment Complexes: We love our hotel and apartment
              complex partners as they play a pivotal role in housing our travel
              nurses on a larger scale nationwide.
              <br />
              Secure your spot on our map today. It’s just $250 for a 12 month
              listing.
            </p>
            <p>
              *All listings are authenticated and are subject to approval by
              Furnished Finder.
              <br /> **All listings renew automatically at the end of its term
              unless user opt’s-out prior to renewal date.
              <br />
              ***The frequency of travel nurse tenant leads in your area depend
              on the hiring patterns of travel nurses. (There are over 250
              travel nursing staffing companies, and about 100,000 travel nurses
              in circulation at any time).
            </p>
          </div>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow22(!isShow22)}>
            <h5>What’s your refund policy?</h5>
          </div>
          <p style={{ display: isShow22 ? "block" : "none" }}>
            A listing is valid for 12 months after the purchase date, and will
            automatically renew for a consecutive 12 month term. You are
            purchasing a FurnishedFinder listing, and there are no refunds for
            any reason once your listing is created. If your listing was never
            created, a full refund will be provided upon request. You may
            opt-out of automatic renewal anytime (under Account in your
            Dashboard) before your renewal date. Refunds will not be provided
            after your listing renews for any reason. Listings are not
            transferable to another person, but your purchased time may be
            rolled into another listing owned by the same person.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow23(!isShow23)}>
            <h5>Can I list my property if it’s unfurnished?</h5>
          </div>
          <p style={{ display: isShow23 ? "block" : "none" }}>
            Most of our users prefer furnished properties, but if you’re a
            complex that rents short-term (month to month or 3 month minimum),
            we have furniture rental partners that can furnish your unit for the
            traveler if needed.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow24(!isShow24)}>
            <h5>What’s the best way to contact the travelers?</h5>
          </div>
          <p style={{ display: isShow24 ? "block" : "none" }}>
            The best way to communicate with travelers is through the app.
            (Visit the Apple app store or Google Play to download - Coming
            Soon). You can also call, text, or email them directly, but the app
            is best since it keeps track of all your messages in one place.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow25(!isShow25)}>
            <h5>Can I list my Hotel or Apartment Complex?</h5>
          </div>
          <p style={{ display: isShow25 ? "block" : "none" }}>
            Absolutely. Travel nurses need as many housing options as possible
            and frequently rely on Hotels and Apartment Complexes!
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow26(!isShow26)}>
            <h5>Is my full address displayed on your map?</h5>
          </div>
          <p style={{ display: isShow26 ? "block" : "none" }}>
            No. We require your full address so we can verify you and your
            property. However, we only display your City/State on your public
            listing.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow27(!isShow27)}>
            <h5>How do I secure a tenant?</h5>
          </div>
          <p style={{ display: isShow27 ? "block" : "none" }}>
            We recommend using the tenant screening from KeyCheck, a Furnished
            Finder partner site, followed up with a state-specific month to
            month lease with a 30-day notice to vacate.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow28(!isShow28)}>
            <h5>Can I manage my calendar with iCal?</h5>
          </div>
          <p style={{ display: isShow28 ? "block" : "none" }}>
            Yes! We’re set up to receive imported iCalendars from Airbnb and
            VRBO. This feature enables you to have a single-calendar view for
            all your reservations! Currently, exports are not enabled.
            <br />
            If you don’t use iCal, we recommend you keep your calendar updated
            at all times to provide our travelers with the best information
            available from your property.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow29(!isShow29)}>
            <h5>Can I make edits to my listing?</h5>
          </div>
          <p style={{ display: isShow29 ? "block" : "none" }}>
            Yes. You simply need to be logged in and go to your dashboard. There
            you’ll be able to do things like update your calendar to show your
            next availability, adjust your notification preferences, change your
            rent amount, and update your images.Yes. You simply need to be
            logged in and go to your dashboard. There you’ll be able to do
            things like update your calendar to show your next availability,
            adjust your notification preferences, change your rent amount, and
            update your images.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow30(!isShow30)}>
            <h5>I don’t want to renew my listing. What do I do?</h5>
          </div>
          <p style={{ display: isShow30 ? "block" : "none" }}>
            While you’re logged in, hover over your username and select Account.
            There you can make major account changes like deleting your listing,
            canceling a renewal, and updating your credit card information.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow31(!isShow31)}>
            <h5>
              I rented my place and don’t want to get notifications for a couple
              months. How can I update my notification settings?
            </h5>
          </div>
          <p style={{ display: isShow31 ? "block" : "none" }}>
            While you’re logged in, go to your Dashboard, select Edit Listing,
            then click Notifications.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow32(!isShow32)}>
            <h5>
              f I update my calendar to show I don’t have availability for a
              while, will the listing still be active on the map?
            </h5>
          </div>
          <p style={{ display: isShow32 ? "block" : "none" }}>
            Yes. If you don’t want your listing to display or don’t want to
            receive travel nurse housing requests for a while, you can make
            these changes in your Notification Settings.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow33(!isShow33)}>
            <h5>If I sign up, can I list with other sites as well?</h5>
          </div>
          <p style={{ display: isShow33 ? "block" : "none" }}>
            Absolutely! We have no restrictions on where or how you market your
            property.
          </p>
        </div>

        <div className="border-bottom">
          <div onClick={() => setShow34(!isShow34)}>
            <h5>What do I do once I receive a travel nurse housing request?</h5>
          </div>
          <p style={{ display: isShow34 ? "block" : "none" }}>
            Reach out to the travel nurse via our system, or just plain old
            email/text/phone... it's totally up to you. We recommend mentioning
            that you received their housing request from Furnished Finder, and
            that you're a property owner that has space for a travel nurse. It
            also helps to mention certain aspects of their housing request to
            jar their memory: "It looks like you're working at Rose Medical
            starting on the 15th..." This should help the traveler remember that
            they filled out a housing request form, and set up the conversation
            for success!
          </p>
        </div>
      </Container>
      <div className={"scroll-wrap"}>
        <div
          onClick={executeScroll}
          className={"scroll-icon"}
          style={{ display: showScroll ? "flex" : "none" }}
        >
          <MdOutlineArrowUpward />
        </div>
      </div>
    </section>
  );
};
export default FAQ;
