import React from "react";
import { Card, Row, Col, Badge } from "react-bootstrap";
import Blog1 from "../../assets/images/blogs/propety_owners/pob1/pob_1_3.png";
import Blog2 from "../../assets/images/blogs/propety_owners/rapid-expansion-and-success-dee-coulsons-rental-arbitrage-journey/pob_2_1.webp";
import Blog3 from '../../assets/images/blogs/propety_owners/pob3/pob_3_1.png';
import Blog4 from '../../assets/images/blogs/propety_owners/pob4/pob4_1.jpg';
import styles from "./BlogList.module.css";
import { Link } from "react-router-dom";

const POBlogList = () => {
  return (
    <div>
      <Link
        to={`/blog/${"One Arbitrage Partner Can Change Everything w Stay Nixon"}`}
      >
        <Card className="border-0 mb-3">
          <Card.Body>
            <Row>
              <Col lg={6} sm={12}>
                <Card.Img variant="top" className={styles["blog-image-main"]} src={Blog4}></Card.Img>
              </Col>
              <Col lg={6} sm={12} className={styles["blog-details"]}>
                <Badge bg="" pill className={styles["badge"]}>
                  Property Owners
                </Badge>

                <Card.Title className={`${styles["blog-title"]} mt-3`}>
                  <h1 className="text-start primary-color">
                  One Arbitrage Partner Can Change Everything with Stay Nixon
                  </h1>
                </Card.Title>
                <Card.Text className={styles["description"]}>
                  November 16, 2023 <div className="vr"> </div>{" "}
                  <span className={styles["blog-span"]}>12 min read</span>
                </Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Link>

      <Row>
        <Col sm={12} lg={4}>
        <Link
        to={`/blog/${"Better Your Community Through Real Estate with RB"}`}
      >
         <Card className={`${styles["blog-card-min"]} mb-3 border-0`}>
          <Card.Body>
          <Card.Img variant="top" src={Blog3}></Card.Img>
          <Badge bg="" pill className={styles["badge-min"]}>
                  Property Owners
                </Badge>
                <Card.Title className={styles["blog-title-min"]}>
                  Better Your Community Through Real Estate w/ RB
                </Card.Title>
                <Card.Text className={styles["description"]}>
                  September 20, 2023 <div className="vr"> </div>{" "}
                  <span className={styles["blog-span"]}>10 min read</span>
                </Card.Text>
          </Card.Body>
        </Card>
      </Link>
        </Col>
      <Col sm={12} lg={4}>
        <Link
        to={`/blog/${"From A Thought To 14 Mid-Term Arbitrage Rentals in 6 Months"}`}
      >
        <Card className={`${styles["blog-card-min"]} mb-3 border-0`}>
          <Card.Body>
                <Card.Img variant="top" src={Blog2}></Card.Img>
                <Badge bg="" pill className={styles["badge-min"]}>
                  Property Owners
                </Badge>

                <Card.Title className={styles["blog-title-min"]}>
                  From A Thought To 14 Mid-Term Arbitrage Rentals in 6 Months
                </Card.Title>
                <Card.Text className={styles["description"]}>
                  September 18, 2023 <div className="vr"> </div>{" "}
                  <span className={styles["blog-span"]}>6 min read</span>
                </Card.Text>
          </Card.Body>
        </Card>
      </Link>
        </Col>

        <Col sm={12} lg={4}>
        <Link to={`/blog/${"Revolutionizing Real Estate Lending: Fund That Flip's Innovative Solutions"}`} >

            <Card className={`${styles["blog-card-min"]} mb-3 border-0`}>
              <Card.Body>
                <Card.Img variant="top" src={Blog1}></Card.Img>
                <Badge bg="" pill className={styles["badge-min"]}>
                  Property Owners
                </Badge>
                <Card.Title className={styles["blog-title-min"]}>
                    Revolutionizing Real Estate Lending: Fund That Flip's
                    Innovative Solutions.
                </Card.Title>
                <Card.Text className={styles["description"]}>
                  September 8, 2023 <div className="vr"> </div>{" "}
                  <span className={styles["blog-span"]}>6 min read</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>

   
       {/*  BLOGS DESIGN COMMENTED FOR NOW */}

        {/* 
          <Card className='mb-3 border-0'>
            <Card.Body>
              <Card.Img variant="top" src={Blog}></Card.Img>
              <Badge bg="" pill className={styles["badge-min"]}>Travelers</Badge>
              <Card.Title className={styles["blog-title-min"]}>How professional real estate photos can make all the difference.</Card.Title>
              <Card.Text className={styles["description"]}>
                July 10, 2022 <div className='vr'> </div> <span className={styles["blog-span"]}>4 min read</span>
              </Card.Text>
            </Card.Body>
          </Card>
          
          </Col>
          <Col sm={12} lg={4}>
        
          <Card className='mb-3 border-0'>
            <Card.Body>
              <Card.Img variant="top" src={Blog}></Card.Img>
              <Badge bg="" pill className={styles["badge-min"]}>Travelers</Badge>
              <Card.Title className={styles["blog-title-min"]}>How professional real estate photos can make all the difference.</Card.Title>
              <Card.Text className={styles["description"]}>
                July 10, 2022 <div className='vr'> </div> <span className={styles["blog-span"]}>4 min read</span>
              </Card.Text>
            </Card.Body>
          </Card>
          
        </Col>
        <Col sm={12} lg={4}>
          
          <Card className='mb-3 border-0'>
            <Card.Body>
              <Card.Img variant="top" src={Blog}></Card.Img>
              <Badge bg="" pill className={styles["badge-min"]}>Travelers</Badge>
              <Card.Title className={styles["blog-title-min"]}>How professional real estate photos can make all the difference.</Card.Title>
              <Card.Text className={styles["description"]}>
                July 10, 2022 <div className='vr'> </div> <span className={styles["blog-span"]}>4 min read</span>
              </Card.Text>
            </Card.Body>
          </Card>
          
        </Col>*/}
      </Row>
    </div>
  );
};

export default POBlogList;
