import React, { useEffect, useState, useRef } from 'react';
import {
    Row, Col, Form, Button, Modal, ToggleButtonGroup, ToggleButton, Toast,
    ToastContainer,
    Image
} from 'react-bootstrap';
import styles from './PropertySearchBar.module.css';
import Filter from '../../assets/svg/filter.svg';
import { MdOutlineSearch } from 'react-icons/md';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import ArrowDown from '../../assets/svg/arrow-down.svg';
import moment from 'moment';
import {
    DayPicker
} from 'react-day-picker';
import { Slider } from 'antd';

const useClickOutside = (handler: () => void) => {
    const domNode = useRef<HTMLElement | null>(null);
  
    useEffect(() => {
      const maybeHandler = (event: MouseEvent) => {
        if (domNode.current && !domNode.current.contains(event.target as Node)) {
          handler();
        }
      };
  
      document.addEventListener("mousedown", maybeHandler);
  
      return () => {
        document.removeEventListener("mousedown", maybeHandler);
      };
    }, [handler]);
  
    return domNode;
  };
  

const PropertySearchBar = (props: any) => {
    const today = new Date();
    let currentYear = today.getFullYear();
    let toYear = currentYear + 10;
    const filterBy = () => true;
    const locationNode = useClickOutside(() => {
        props.setIsLocation(false);
    });
    const [isPriceRange, setIsPriceRange] = useState(false)
    const priceNode = useClickOutside(() => {
        setIsPriceRange(false);
    });
    const [isDate, setIsDate] = useState(false)

    const dateNode = useClickOutside(() => {
        setIsDate(false);
    });
    const [defaultPriceRange, setDefaultPriceRange] = useState<[number, number]>([props.minBudget, props.maxBudget])

    const typeRef = useRef<any>();


    const onAfterChange = (value: [number, number]) => {
        props.setMinBudget(value[0])
        props.setMaxBudget(value[1])
    };

    const handleModalSubmit = () => {
        props.setModalShow(false)
        props.handleSearch()
    }
    const handleSelectDay = (day: any) => {
        if (day) {
            props.setSelectedDay(day)
        }
        setIsDate(false)
    }

    useEffect(() => {
        setDefaultPriceRange([props.minBudget, props.maxBudget])
    }, [props.minBudget, props.maxBudget])



    useEffect(() => {

        if (props.showToast) {
            setTimeout(() => {
                props.setShowToast(false)
            }, 1000);
        }
// eslint-disable-next-line
    }, [props.isLocationEmpty])

    return (
        <div>
            <ToastContainer position='top-center'>
                <Toast
                    onClose={() => props.setShowToast(false)}
                    data-testid="toast"
                    autohide={true}
                    show={props.showToast}
                    bg='danger'
                    delay={3000}
                >
                    <Toast.Body>
                        Please select a location
                    </Toast.Body>
                </Toast>
            </ToastContainer>

            {props.screenWidth > 700 ?
                <Row className={`${styles["property-search-bar"]} bannerRow gx-0`}>

                    <Col lg={4} md={6} sm={6} ref={locationNode}>
                        <div className={`${styles["dropdown"]}`}>

                            <label
                                className={`${styles["label"]} ${styles[props.isLocationEmpty ? "error-msg" : '']}`}
                            >Location</label>
                            <AsyncTypeahead
                                filterBy={filterBy}
                                className={`${styles["location-input"]}`}
                                // className={styles["location-input"]}
                                id="city"
                                align='justify'
                                isLoading={props.isLoading}
                                labelKey={option => `${option}`}
                                onChange={(place: any) => {
                                    props.handlePlaceUpdate(place)
                                }}
                                selected={props.selectedPlace}
                                minLength={3}
                                onSearch={(query) => {
                                    props.setMatchString(query)
                                }}
                                ref={typeRef}
                                options={props.cities}
                                placeholder="Enter destination/ property"
                                renderMenuItemChildren={(option: any) => (
                                    <>
                                        <span>{option}</span>
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} ref={dateNode}>
                        <div className={styles["dropdown"]}>

                            <label className={styles["label"]}>When</label>
                            <button className={styles["dropbtn"]} onClick={() => {
                                setIsDate(!isDate)
                            }}> {props.selectedDay ? <span className={`${styles["date-span"]} w-max-content`}>{moment(props.selectedDay).format('L')}</span> : 'Move-in date'} {props.selectedDay ? '' : <Image alt='arrow' src={ArrowDown} />} </button>
                            <div className={styles["dropdown-content"]} style={isDate ? { display: "block" } : { display: "none" }}>
                                <DayPicker
                                    mode="single"
                                    selected={new Date(props?.selectedDay)}
                                    onSelect={handleSelectDay}
                                    captionLayout="dropdown"
                                    fromYear={currentYear} toYear={toYear}
                                    disabled={{ before: today }}
                                />
                            </div>
                        </div>
                    </Col>
                    {props.screenWidth > 700 ? <Col md={8} lg={3} sm={5} ref={priceNode}>
                        <div className={styles["dropdown"]}>

                            <label className={styles["label"]}>Price</label>
                            <button className={styles["dropbtn"]} onClick={() => {
                                setIsPriceRange(!isPriceRange)
                            }}>{props.minBudget > 20 && props.maxBudget === 10000 ? `$${props.minBudget}-$${props.maxBudget}+`: props.minBudget > 20 || props.maxBudget < 10000 ? `$${props.minBudget}-$${props.maxBudget}` : 'Any Range'} {props.minBudget > 20 || props.maxBudget < 10000 ? null : <Image alt='arrow' src={ArrowDown} />}</button>

                            <div className={styles["dropdown-content"]}
                                style={isPriceRange ? { display: "block" } : { display: "none" }}
                            >
                                <div >
                                    <h6 className='primary-color m-3'>Price per month</h6>
                                    <hr className="primary-color" />
                                    <div className='mx-3'>


                                        <Slider
                                            range
                                            step={5}
                                            min={20}
                                            max={10000}
                                            defaultValue={defaultPriceRange}
                                            onAfterChange={onAfterChange}
                                            tooltip={{
                                                formatter: (value) => {
                                                  return value === 10000 ? `${value}+` : value;
                                                }
                                              }}
                                        />
                                        <Row>
                                            <Col><p>$20</p></Col>
                                            <Col className='text-end'><p>$10000+</p></Col>
                                        </Row>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col> : null }

                    <Col lg={1} md={2} sm={2} className='text-center align-items-center d-flex justify-content-center'>
                        <Image alt='arrow' src={Filter} className={styles["filters-icon"]} onClick={() => props.setModalShow(true)} />
                    </Col>

                    <Col lg={1} md={2} sm={5} className=' align-items-center d-flex justify-content-center'>
                        <Button className={`p-3 ${styles["full-width-sm-devices"]}`} onClick={() => {
                            props.dispatch(props.updateSelectedCity([...props.selectedPlace]))
                            props.handleSearch()
                        }}> <MdOutlineSearch className='fs-3' /></Button>
                    </Col>

                </Row>
                :
                <Row className={`${styles["property-search-bar"]} bannerRow gx-0`}>

                    <Col md={4} ref={locationNode}>
                        <div className={styles["dropdown"]}>

                            <label className={styles["label"]}>Location</label>
                            <AsyncTypeahead
                                filterBy={filterBy}
                                className={styles["location-input"]}
                                id="city"
                                align='justify'
                                isLoading={props.isLoading}
                                labelKey={option => `${option}`}
                                onChange={(place: any) => {
                                    props.handlePlaceUpdate(place)
                                }}
                                selected={props.selectedPlace}
                                minLength={3}
                                onSearch={(query) => {
                                    props.setMatchString(query)
                                }}
                                ref={typeRef}
                                options={props.cities}
                                placeholder="Enter destination/ property"
                                renderMenuItemChildren={(option: any) => (
                                    <>
                                        <span>{option}</span>
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                    <Col md={6}   className=''>
                        <Row>
                        <Col xs={7} sm={7} ref={dateNode} className='border-end'>
                                <div className={styles["dropdown"]}>

                                    <label className={styles["label"]}>When</label>
                                    <button className={styles["dropbtn"]} onClick={() => {
                                        setIsDate(!isDate)
                                    }}> {props.selectedDay ? <span className='w-max-content'>{moment(props.selectedDay).format('L')}</span> : 'Move-in date'} {props.selectedDay ? '' : <Image alt='arrow' src={ArrowDown} />} </button>
                                    <div className={styles["dropdown-content"]} style={isDate ? { display: "block" } : { display: "none" }}>
                                    <DayPicker
                                    mode="single"
                                    selected={new Date(props?.selectedDay)}
                                    onSelect={handleSelectDay}
                                    captionLayout="dropdown"
                                    fromYear={currentYear} toYear={toYear}
                                    disabled={{ before: today }}
                                />
                                    </div>
                                </div>
                            </Col>
                            <Col xs="2" lg="1" sm="2" className='align-items-center text-center d-flex justify-content-center border-end'>
                                <Image src={Filter} className={styles["filters-icon"]} onClick={() => props.setModalShow(true)} alt="" />
                            </Col>
                            <Col xs="3" md="1" sm="3" className='mx-auto mx-md-0'>
                               <Button className={`p-3 ${styles["full-width-sm-devices"]} search-btn`}  onClick={() => {
                                    props.dispatch(props.updateSelectedCity([...props.selectedPlace]))
                                    props.handleSearch()
                                }}> <MdOutlineSearch className='fs-3' /></Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            }

            <Modal
                show={props.modalShow}
                onHide={() => props.setModalShow(false)}
                size="lg"
                className='filter-modal'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="mx-auto fw-600 primary-color">
                        Filters
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='m-3'>
                    {props.screenWidth < 700 ?
                        <><div>
                            <label className={`${styles["label"]}`}>Price Range</label>
                            <div className='mx-3'>
                                <Slider
                                    range
                                    step={5}
                                    min={20}
                                    max={10000}
                                    defaultValue={[20, 10000]}
                                    onAfterChange={onAfterChange}
                                    tooltip={{
                                        formatter: (value) => {
                                          return value === 10000 ? `${value}+` : value;
                                        }
                                      }}
                                />
                                <div className='justify-content-between d-flex'>
                                    <span className='text-grey'>$20</span>
                                    <span className='text-grey'>$10000+</span>
                                </div>

                            </div>
                        </div>
                            <hr /></> : null}
                    <div>
                        <label className={`${styles["label"]} fs-6 mb-2`}>Property type</label>

                        <Row>


                            <Col>
                                <Form.Group className="mb-3 fs-6">
                                    <Form.Check inline type="checkbox" label="Apartment" checked={props.ATAppartment} id="apartment" onChange={(e) => { props.setATAppartment(e.target.checked) }} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 fs-6">
                                    <Form.Check inline type="checkbox" label="Condo" checked={props.ATCondo} id="condo" onChange={(e) => { props.setATCondo(e.target.checked) }} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 fs-6">
                                    <Form.Check inline type="checkbox" label="Cottage" checked={props.ATCottage} id="cottage" onChange={(e) => { props.setATCottage(e.target.checked) }} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 fs-6">
                                <Form.Check inline type="checkbox" label="House" checked={props.ATHouse} id="house" onChange={(e) => { props.setATHouse(e.target.checked) }} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3 fs-6">
                                <Form.Check inline type="checkbox" label="Room" checked={props.ATRoom} id="room" onChange={(e) => { props.setATRoom(e.target.checked) }} />
                                </Form.Group>
                            </Col>

                        </Row>
                    </div>
                    <hr />
                    <div>
                        <label className={`${styles["label"]} fs-6 mb-2`}>Bedrooms</label>
                        <Row>
                            <ToggleButtonGroup type="radio" className='d-inline-block' name="bedroom-options" defaultValue={props.rooms} onChange={(value) => {
                                props.setRooms(value)
                            }}>
                                <ToggleButton className="toggle-radio" checked id="bedrooms-0" value={0}>
                                    Any
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bedrooms-1" value={1}>
                                    1+
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bedrooms-2" value={2}>
                                    2+
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bedrooms-3" value={3}>
                                    3+
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bedrooms-4" value={4}>
                                    4+
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bedrooms-5" value={5}>
                                    5+
                                </ToggleButton>

                            </ToggleButtonGroup>
                        </Row>
                    </div>
                    <hr />
                    <div>
                        <label className={`${styles["label"]} fs-6 mb-2`}>Bathrooms</label>
                        <Row>
                            <ToggleButtonGroup type="radio" className='d-inline-block' name="bathroom-options" defaultValue={props.bathrooms} onChange={(value) => {

                                props.setBathrooms(value)
                            }}>
                                <ToggleButton className="toggle-radio" id="bathrooms-0" value={0}>
                                    Any
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bathrooms-1" value={1}>
                                    1+
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bathrooms-2" value={2}>
                                    2+
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bathrooms-3" value={3}>
                                    3+
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bathrooms-4" value={4}>
                                    4+
                                </ToggleButton>
                                <ToggleButton className="toggle-radio" id="bathrooms-5" value={5}>
                                    5+
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Row>
                    </div>
                    <div className='justify-content-end d-flex mt-3'>
                        <Button onClick={handleModalSubmit} className=''>Submit</Button>
                    </div>
                </Modal.Body>
            </Modal>

        </div >
    )
}

export default PropertySearchBar;