import React,{useState} from "react";
import { Image } from "react-bootstrap";
import { Carousel } from "react-responsive-carousel";

const ImgSlider = (props: any) => {
  const customPrevArrow = (clickHandler: () => void) => (
    <div
      className="custom-arrow prev"
      style={{
        position: "absolute",
        zIndex: 2,
        top: "calc(50% - 15px)",
        width: 30,
        height: 30,
        cursor: "pointer",
        display:currentSlide===0?"none":"block"
      }}
      onClick={clickHandler}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        className="control-arrow control-next"
        style={{transform: "rotate(180deg)"}}
      >
        <g
          id="Group_374"
          data-name="Group 374"
          transform="translate(-1119.92 -1629.92)"
        >
          <path
            id="Path_163"
            data-name="Path 163"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            transform="translate(1119.92 1629.92)"
            fill="#fff"
          />
          <g
            id="Group_163"
            data-name="Group 163"
            transform="translate(1124.845 1636.189)"
          >
            <path
              id="Path_116"
              data-name="Path 116"
              d="M0,0,5.764,6.141,11.461,0"
              transform="translate(7.076 11.462) rotate(-90)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M6157.313,10281H6144.1"
              transform="translate(-6144.097 -10275.269)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </svg>
    </div>
  );

   
  const customNextArrow = (clickHandler: () => void) => (
    <div
      className="custom-arrow prev"
      style={{
        position: "absolute",
        zIndex: 2,
        top: "calc(50% - 15px)",
        right:0,
        width: 30,
        height: 30,
        cursor: "pointer",
        display:currentSlide===3?"none":"block"
      }}
      onClick={clickHandler}
      
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 24 24"
        className="control-arrow control-next"
      >
        <g
          id="Group_374"
          data-name="Group 374"
          transform="translate(-1119.92 -1629.92)"
        >
          <path
            id="Path_163"
            data-name="Path 163"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            transform="translate(1119.92 1629.92)"
            fill="#fff"
          />
          <g
            id="Group_163"
            data-name="Group 163"
            transform="translate(1124.845 1636.189)"
          >
            <path
              id="Path_116"
              data-name="Path 116"
              d="M0,0,5.764,6.141,11.461,0"
              transform="translate(7.076 11.462) rotate(-90)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M6157.313,10281H6144.1"
              transform="translate(-6144.097 -10275.269)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </svg>
    </div>
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleSlideChange = (index:number) => {
    setCurrentSlide(index);
  };
  return (
    <Carousel
      showStatus={false}
      showThumbs={false}
      className={"property-carousel"}
      renderArrowPrev={customPrevArrow}
      renderArrowNext= {customNextArrow}
      onChange={handleSlideChange}
    >
      <div>
        <Image src={props.img1} alt="property-image" />
      </div>
      <div>
        <Image src={props.img2} alt="property-image" />
      </div>
      <div>
        <Image src={props.img3} alt="property-image" />
      </div>
      <div>
        <Image src={props.img4} alt="property-image" />
      </div>
    </Carousel>
  );
};
export default ImgSlider;
