import React, { useState, useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { Button, Col, Row } from 'react-bootstrap';
import PropertyListCard from '../../components/PropertyListCard/PropertListCard';
import PropertyListLoading from '../../components/PropertyListCard/PropertyListLoading';
import { updateCurrentPage, updatePageNumber,updateData } from './propertyListSlice';

const PropertyList =(props: any) => {
    const dispatch = useAppDispatch();
    const [hasMore, setHasMore] = useState(false)
    const currentPage = useAppSelector((state) => state.propertyList.currentPage);
    const data = useAppSelector((state) => state.propertyList.data);

    const [loader, setLoader] = useState(true)

    const onGrabDataLocal = (page: any) => {
        setLoader(true);
        dispatch(updateCurrentPage(page));
        const startIndex = (page - 1) * props.NUM_PER_PAGE;
        const endIndex = startIndex + props.NUM_PER_PAGE;
        const points = props.propertyData?.GridProperty?.points;
        const newData = points && points.slice(startIndex, endIndex);
        dispatch(updateData([...data, ...newData ?? []]));
        setHasMore(page < props.totalPages);
        setTimeout(() => {
            setLoader(false); // set loader state to false when there is no more data to load
        }, 2300);
      };

      useEffect(() => {
        setLoader(true)
        dispatch(updatePageNumber(1));
        dispatch(updateCurrentPage(1));

            setTimeout(() => {
                onGrabDataLocal(1)
                setHasMore(currentPage < props.totalPages)
                if (props.propertyData?.GridProperty?.RecordCount === 0) {
                setLoader(false)
                }
            }, 1200);
        
            return () => {
                dispatch(updatePageNumber(1));
                dispatch(updateCurrentPage(1));
                dispatch(updateData([]))
              };

    }, [props.propertyData, props.totalPages, dispatch])

    const handleLoadMore = useCallback(() => {
        const nextPage = currentPage + 1;
        dispatch(updatePageNumber(nextPage));
        onGrabDataLocal(nextPage);
      }, [currentPage, dispatch, onGrabDataLocal]);

    return (
        <div className='custom-container p-0'>
            <Row className='mb-3 flex-row flex-wrap content-start'>
                {data && props.TOTAL_COUNT > 0 && data.map((property: any) => {

                    let desc = property?.Description?.filter((str: string) => !str.startsWith("Room"));
                    return (
                        <Col sm={12} md={6} lg={props.mapView ? 4 : 3} xl={props.mapView ? 4 : 3} key={property?.id}>
                            <PropertyListCard
                                key={property?.id}
                                propertyId={property?.id}
                                images={[...property?.images]}
                                title={property?.name}
                                description={[...desc].slice(1)}
                                price={property.Price}
                                bedRoomCount={property?.BedRoomCount}
                                image=""
                                BathType={property?.BathType}
                                PType={property.PType}
                                setSelectedProperty={props.setSelectedProperty}
                                PropAvailableDate = {new Date(
                                    property?.AvailDate
                                  )}


                                
                            />
                        </Col>

                    )
                })}

                {loader && <div>
                    <PropertyListLoading total={6} />
                </div>}

                {hasMore && <div className='text-center'><Button
                    onClick={handleLoadMore}> Load More </Button></div>}
            </Row>
        </div>


    )
};
export default PropertyList;
