import React from "react";
import { Link } from "react-router-dom";
const LatestArticles = () => {
  return (
    <div>
      <h4>Latest Articles</h4>
      <hr />
      <div>
        <Link
          to={`/blog/${"One Arbitrage Partner Can Change Everything w Stay Nixon"}`}
          target="_blank"
          className="primary-color fs-5"
        >
          One Arbitrage Partner Can Change Everything w/ Stay Nixon
        </Link>
      </div>
      <br />
      <div>
        <Link
          to={`/blog/${"Better Your Community Through Real Estate with RB"}`}
          target="_blank"
          className="primary-color fs-5"
        >
          Better Your Community Through Real Estate wtih RB
        </Link>
      </div>

      <br />
      <div>
        <Link
          to={`/blog/${"From A Thought To 14 Mid-Term Arbitrage Rentals in 6 Months"}`}
          target="_blank"
          className="primary-color fs-5 mb-3"
        >
          From A Thought To 14 Mid-Term Arbitrage Rentals in 6 Months
        </Link>
      </div>
      <br />
      <div>
        <Link
          to={`/blog/${"Revolutionizing Real Estate Lending: Fund That Flip's Innovative Solutions"}`}
          target="_blank"
          className="primary-color fs-5"
        >
          Revolutionizing Real Estate Lending: Fund That Flip's Innovative
          Solutions
        </Link>
      </div>
    </div>
  );
};

export default LatestArticles;
