import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postContactForm } from './contactAPI';

const initialState: any = {
  loading: false,
  status: ''
};

export const postContactAsync = createAsyncThunk(
  'contact/postContactForm',
  async (params: any) => {
    const response = await postContactForm(params);
    return response?.data?.HttpStatusCode;
  }
);


export const contactSlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    incrementRoom: (state) => {
      state.rooms += 1;
    },

    updateRange: (state, action) => {
      state.selectedDateRange = action.payload
    },
    updatePriceRange: (state, action) => {
      state.minBudget = action.payload[0]
      state.maxBudget = action.payload[1]
    },
    updatePages: (state, action) => {
      state.total_pages = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postContactAsync.pending, (state) => {
        state.loading = true;
      })

      .addCase(postContactAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload;
        state.error = "";
      })

      .addCase(postContactAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Something went wrong.';
      });


  },
});

export default contactSlice.reducer;
