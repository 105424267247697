import React, { FunctionComponent } from 'react';
import {Card} from 'react-bootstrap';
import styles from './LearnMore.module.css';

interface CardInterface {
    title: string;
    description: string;
    image: string
  }

  
const LearnMoreCard: FunctionComponent<CardInterface> = ({ title, description, image }) => {
    return(
        <Card  className={`${styles["lm-card"]}`}>
              <Card.Img className={styles["icon"]} variant="top" src={image}  />
              <Card.Body className={`${styles["card-body"]} mb-4`}>
                <Card.Title className={`${styles["card-title"]} mb-3`}>{title}</Card.Title>
                <Card.Text className={`${styles["card-description"]}`}>
                 {description}
                </Card.Text>
              </Card.Body>
            </Card>
        
    )
}

export default LearnMoreCard;