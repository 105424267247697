import axiosInstance from "../../lib/axios";

export const getCityState = (matchString : string) => {
    return axiosInstance
        .get(`/Properties/getMapAutoCompleteData?matchString=${matchString}`)
        .then((response) => {
            return { data: response.data }
        })
        .catch((error) => {
            if (error && error.response) {
                if (error.response) {
                    return { data: error.response.data }
                }
            }
        });
};

export const getPropertyList = (params: any) => {
    if(params.MaxBudget === "10000") {
        params.MaxBudget = "10000+"
    }
    return axiosInstance
        .post(`/Properties/getPropertyList/`, { ...params })
        .then((response) => {
            return { data: response.data }
        })
        .catch((error) => {
            if (error && error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    window.location.assign('/')
                    localStorage.clear();
                    sessionStorage.clear();
                }
                if (error.response) {
                    return { data: error.response.data }
                }
            }
        });
};

export const getProperties = (state: string, city: string) => {
    return axiosInstance
        .post(`/Properties/GetProperties/`, { state, city })
        .then((response) => {
            return { data: response.data }
        })
        .catch((error) => {
            if (error && error.response) {
                if (error.response) {
                    return { data: error.response.data }
                }
            }
        });
};
