import React, { FunctionComponent } from 'react';
import styles from './AboutUs.module.css';
import { Image } from 'react-bootstrap';

interface TagInterface {
    title: string;
    description: string;
    image: string
  }

  
const TagBlock: FunctionComponent<TagInterface> = ({ title, description, image }) => {
    return(
        <div className={`${styles["tag-style-wrap"]} `}>
            <div>
           <div className={`${styles["tag-icon-wrap"]} `}> <Image src={image} className="img-fluid" height="50"/></div>
            </div>
           <div>
                <h4 className='fw-600 mb-2 primary-color'>{title}</h4>
                <p className='fw-400'>{description}</p>
            </div>
        </div>
        
    )
}

export default TagBlock;