import React, { useEffect } from "react";
import styles from './ListPropertyRedirect.module.css';
const ListPropertyRedirect = () => {
    useEffect(() => {
      window.location.replace('https://www.furnishedfinder.com/list-your-property?s=FA');
    }, [])
  
    return <div className={styles["list-prop-wrap"]}>
        <h3>Redirecting...</h3>
    </div>
  }

export default ListPropertyRedirect;