import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProperties, getPropertyList, getCityState } from './propertyListAPI';

export interface CoordData {
  Center: string
  MapTypeId: string
  MaxZoom: string
  MinZoom: string
  Zoom: string
}
export interface PropertyDetailList {
  CommonResponseStatus: {
    Message: string;
    Status: boolean;
  }
  GridProperty: {
    RecordCount: number,
    points: any
  }
  MapProperty:
  { points: any, RecordCount: number }
  PageCount: number,
  PageNumber: number,
  PageSize: number

}

export interface Marker {
  id: number;
  lat: string;
  long: string;
  PType: string;
  Price: string;
  images: Array<{ url: string }>;
  name: string;
  Description: Array<string>;
}

export interface MapContainerProps {
  lat?: string;
  lng?: string;
  markers?: Array<any>;
  selectedProperty?: number;
  zoom: number;
}

const initialState: any = {
  loading: false,
  plLoading: false,
  mapData: {},
  properties: {},
  error: "",
  cities: [],
  rooms: 0,
  selectedCity: [],
  selectedDateRange: {},
  priceRange: 0,
  filters: {},
  total_props: 0,
  total_pages: 0,
  dataLoading: false,
  currentPage: 1,
  data: [],
  hasMoreData: true,
  pageNumber: 1
};

export const getPropertiesAsync = createAsyncThunk(
  'property/getProperties',
  async (params: { state: string, city: string }) => {
    const response = await getProperties(params.state, params.city);
    return response?.data?.Data?.MapCoordinate;
  }
);

export const getPropertyListAsync = createAsyncThunk(
  'property/getPropertyList',
  async (params: any) => {
    const response = await getPropertyList(params);
    return response?.data?.Data;
  }
);
export const getCityStateAsync = createAsyncThunk(
  'property/getCityState',
  async (matchString: string) => {
    const response = await getCityState(matchString);
    let cities = response?.data?.Data?.lstCityStateData.map((el: any) => { return el.city; });
    return cities
  }
);

export const getSortAsync = createAsyncThunk(
  'property/getSort',
  async (properties: any) => {
    const response = await [...properties?.GridProperty?.points].sort((a: any, b: any) => a.Price.slice(1).replace(',', '') - b.Price.slice(1).replace(',', ''))
    return {
      ...properties,
      GridProperty: {
        ...properties.GridProperty,
        points: [...response]
      }

    }

  }
);

export const getSortDescAsync = createAsyncThunk(
  'property/getSortDesc',
  async (properties: any) => {
    let response = await [...properties?.GridProperty?.points].sort((a: any, b: any) => b.Price.slice(1).replace(',', '') - a.Price.slice(1).replace(',', ''))
  return {
      ...properties,
      GridProperty: {
        ...properties.GridProperty,
        points: response
      }

    }


  }
);

export const getSortDateAsync = createAsyncThunk(
  'property/getSortDate',
  async (properties: any) => {
    const response = await [...properties?.GridProperty?.points].sort((a: any, b: any) => { return new Date(a.AvailDate).getTime() - new Date(b.AvailDate).getTime(); })
    const resobj = {
      ...properties,
      GridProperty: {
        ...properties.GridProperty,
        points: [...response]
      }

    }
    return resobj
  }
);


export const propertyListSlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    incrementRoom: (state) => {
      state.rooms += 1;
    },
    decrementRoom: (state) => {
      if (state.rooms > 0) {
        state.rooms -= 1;
      }
    },
    updateFilters: (state, action) => {
      state.filters = { ...action.payload }
    },
    updateDateRange: (state, action) => {
      state.selectedDateRange = action.payload
    },
    updatePriceRange: (state, action) => {
      state.minBudget = action.payload[0]
      state.maxBudget = action.payload[1]
    },
    updatePages: (state, action) => {
      state.total_pages = action.payload
    },
    updatePageNumber: (state, action) => {
      state.pageNumber = action.payload
    },
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    updateSelectedCity: (state, action) => {
      state.selectedCity = [...action.payload]
      // state.loading = true;
      state.plLoading = true;
      state.data = [];
      state.currentPage = 1;
      state.pageNumber = 0;

    },
    updateData: (state, action) => {
        state.data = [...action.payload]
    },
    updateProperties: (state, action) => {
      state.data = action.payload
  },
    setData: (state, action) => {
      
  state.dataLoading = action.payload?.dataLoading ?? false;
  state.hasMoreData = action.payload?.hasMoreData ?? true;
    },
    onGrabData: (state, action) => {
        state.dataLoading = false;
        state.data = [...state.data!, ...action.payload.data!];
        state.currentPage = state.currentPage + 1;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPropertiesAsync.pending, (state) => {
        state.loading = true;
        state.plLoading = true;
      })

      .addCase(getPropertiesAsync.fulfilled, (state, action: PayloadAction<CoordData>) => {
        state.loading = false;
        state.mapData = action.payload;
        state.error = "";
      })

      .addCase(getPropertiesAsync.rejected, (state, action) => {
        state.loading = false;
        state.mapData = {};
        state.error = action.error.message || 'Something went wrong.';
      })

      .addCase(getPropertyListAsync.pending, (state) => {
        state.plLoading = true;
      })

      .addCase(getPropertyListAsync.fulfilled, (state, action) => {
        state.plLoading = false;
        state.properties = action.payload;
        state.error = "";
      })

      .addCase(getPropertyListAsync.rejected, (state, action) => {
        state.plLoading = false;
        state.properties = {}
        state.error = action.error.message || 'Something went wrong.';
      })
      .addCase(getCityStateAsync.pending, (state) => {
        state.loading = true;
      })

      .addCase(getCityStateAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.cities = action.payload;
        state.error = "";
      })

      .addCase(getCityStateAsync.rejected, (state, action) => {
        state.loading = false;
        state.cities = [];
        state.error = action.error.message || 'Something went wrong.';
      })

      .addCase(getSortAsync.pending, (state) => {
        state.plLoading = true;
      })

      .addCase(getSortAsync.fulfilled, (state, action) => {
        state.plLoading = false;
        state.properties = action.payload;
        state.error = "";
      })

      .addCase(getSortAsync.rejected, (state, action) => {
        state.plLoading = false;
        state.properties = {};
        state.error = action.error.message || 'Something went wrong.';
      })

      .addCase(getSortDescAsync.pending, (state) => {
        state.plLoading = true;
      })

      .addCase(getSortDescAsync.fulfilled, (state, action) => {
        state.plLoading = false;
        state.properties = action.payload;
        state.error = "";
      })

      .addCase(getSortDescAsync.rejected, (state, action) => {
        state.plLoading = false;
        state.properties = {};
        state.error = action.error.message || 'Something went wrong.';
      })

      .addCase(getSortDateAsync.pending, (state) => {
        state.plLoading = true;
      })

      .addCase(getSortDateAsync.fulfilled, (state, action) => {
        state.plLoading = false;
        state.properties = action.payload;
        state.error = "";
      })

      .addCase(getSortDateAsync.rejected, (state, action) => {
        state.plLoading = false;
        state.properties = {};
        state.error = action.error.message || 'Something went wrong.';
      });


  },
});

export const { incrementRoom, decrementRoom, updateDateRange, updatePriceRange, updateFilters, updatePages, updateSelectedCity, onGrabData, setData, updatePageNumber, updateCurrentPage, updateData, updateProperties } = propertyListSlice.actions;
export default propertyListSlice.reducer;
