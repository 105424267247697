import React from 'react';

import Logo from '../../assets/svg/logo-inverted.svg'
import { Container, Nav, Row, Col, Image } from 'react-bootstrap';
import styles from './Footer.module.css';
import { MdOutlineFacebook } from 'react-icons/md';
import Instagram from '../../assets/images/instagram.png';
import Twitter from '../../assets/images/twitter.png';
import { Link } from 'react-router-dom';

const Footer = () => {
    const scrollToTop = () => {
        window.scrollTo(0, 0);
      }
    return (
        <footer className={`${styles["hrColor"]} section-padding`}>
            <Container fluid>
                <Row className={styles.footerTop}>
                    <Col sm={12} lg={4}><div className='mb-3'><Link to="/"><Image src={Logo} alt="logo" className={`${styles.logo} align-items-center justify-content-center `} /></Link></div></Col>
                    <Col sm={12} lg={8}>
                        <div className={`d-md-flex justify-content-lg-end ${styles["footer-nav"]}`}>
                            <Link className={styles.navLink} onClick={scrollToTop} to="/list-your-property">List your property</Link>
                            <Link className={styles.navLink} onClick={scrollToTop}  to="/property-owner">Property owners</Link>
                            <Link className={styles.navLink} onClick={scrollToTop} to="/search-map">Search map</Link>
                            <Link className={styles.navLink} onClick={scrollToTop} to="/blog">Blog</Link>
                            <Link className={styles.navLink} onClick={scrollToTop} to="/contact">Contact us</Link>
                            <span>
                                <a href="https://www.facebook.com/FURNISHEDFINDER">
                                    <MdOutlineFacebook className={styles["icons"]} />
                                </a>
                                <a href="https://www.instagram.com/furnishedfinder/">
                                    <Image src={Instagram} className={styles["icons"]} alt="instagram" />
                                </a>
                                <a href="https://twitter.com/furnishedfinder">
                                    <Image src={Twitter} className={styles["icons"]} alt="twitter" />
                        </a>
                        </span>
                    </div>
                </Col>
            </Row>
            <hr className={`${styles["hrColor"]}`} />
            <Row className='align-items-center pb-2'>
                <Col sm={12} lg={6}>
                    <Nav.Item className={`${styles["nav-item"]}`}> {`© ${new Date().getFullYear()} Furnished Apartments | 12900 Stroh Ranch Place #125 Parker, CO 80134`}</Nav.Item>
                </Col>
                <Col sm={6} lg={6} className={`${styles["footer-nav-2"]} justify-content-end`}>
                    <div className='d-md-flex justify-content-end'>
                        <Link className={`${styles.navLink} pe-3`} to="/terms-of-service" onClick={scrollToTop}>Terms of Service</Link>
                        <Link className={`${styles.navLink} `} to="/privacy-policy" onClick={scrollToTop}>Privacy Policy</Link>
                    </div>
                </Col>
            </Row>
        </Container>
        </footer >
    )
}
export default Footer;
