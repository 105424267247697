import React from 'react';
import styles from './PropertyListCard.module.css';
import { Card, Row, Col, Placeholder } from 'react-bootstrap';
import PlaceholderImg from '../../assets/images/ph_img.png';

const PropertyListCardPlaceholder = () => {
  return (
    <Card className={`${styles['property-card']} mb-3 property-card`}>
      <Card.Img variant="top" className={`${styles["ph-img"]}`} src={PlaceholderImg} />
      <Card.Body>
        <Row className='mb-3'>
          <Col className={`d-flex`} sm>
            <Placeholder xs={8} />
          </Col>
        </Row>
        <Placeholder as={Card.Title} animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={12} />
        </Placeholder>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={12} />
          <Placeholder xs={12} />
        </Placeholder>
      </Card.Body>
    </Card>
  )
}



const PropertyListLoading = (props: any) => {
  const arrays = props.total === 6 ? [1, 2, 3] : [1, 2, 3, 4]

  return (
    <Row className='mb-3 flex-row flex-wrap content-start'>
      {arrays.map((a, index) => {
        return (<Col key={index} md={props.total === 6 ? 4 : 3}>
          < PropertyListCardPlaceholder />
        </Col>)
      })}
    </Row>

  )
}
export default PropertyListLoading;