import React, { useRef, useState } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import Image1 from "../../../assets/images/blogs/propety_owners/pob3/pob_3_1.png";
import Image2 from "../../../assets/images/blogs/propety_owners/pob3/pob_3_2.png";
import Image3 from "../../../assets/images/blogs/propety_owners/pob3/pob_3_3.png";

import { Link } from "react-router-dom";
import { MdOutlineArrowUpward } from "react-icons/md";
import { FaSpotify, FaYoutube, FaPodcast } from "react-icons/fa";
import LatestArticles from "../latest-articles";
import styles from "../Blog.module.css";

const POB3 = () => {
  const topRef = useRef<HTMLDivElement>(null);

  const executeScroll = () => topRef.current?.scrollIntoView();

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  return (
    <>
      <Container fluid id={"blog-detail"} ref={topRef} >
        <Row>
          <Col md={8} className={`${styles["blog-left-panel"]}`}>
            <Row className="mb-2 mt-2">
              <h2 className="primary-color mb-2">
              From Single Family to Multi-Family: How RB Built a Profitable Real Estate Portfolio and Found Success in Mid-Term Rentals
              </h2>

              <span className="fs-6 mb-3">
                Author : FF Team | September 20, 2023
              </span>
            </Row>
            <Image src={Image1} alt="blog-image-1" className={"blog-image mb-3"} />
            <p>
              RB is a realtor, investor, and property manager in New York. She
              pursued her first multi-family real estate investment at 23 years
              old but had a hard time locking one in, so she got creative and
              purchased a single family home instead and converted it into a
              multi-family property. She has a variety of multi-family
              properties in Queens, Long Island, Upstate New York, and the
              Bronx. RB has a heart for the inner-city communities of New York
              and loves coaching others, showing them that real estate is
              possible for everyone.
            </p>
            <p>
              RB has been a seasoned landlord since 2003, with a track record of
              successful long-term rentals. However, just before the pandemic
              hit, she received a valuable tip from a realtor friend: consider
              renting her properties to traveling medical professionals.
              Intrigued, she decided to explore this option further and was
              pleasantly surprised by the results.
            </p>
            <p>
              According to her realtor friend, medical professionals are in
              constant demand and tend to work long hours, leaving them little
              time or energy for maintaining a home. As a result, they look for
              a comfortable, peaceful, and quiet place to stay, which makes them
              ideal tenants from a landlord's perspective. Her friend noted that
              the demand for such rentals was high, and the gap between tenants
              was minimal. Moreover, she would be able to command much higher
              rents than before, making this a profitable and lucrative option
              for her.
            </p>
            <p>
              Having just heard about this blossoming niche in the real estate
              market known as mid-term rentals, RB was excited to jump in. She
              quickly converted one of her long-term units into a mid-term unit,
              listed her property on FurnishedFinder.com, and has been pleased
              with the results ever since.
            </p>
            <p>
              Before RB ventured out into mid-term rentals, however, she got her
              feet wet by purchasing single family homes and converting them
              into multi-family properties. While that may sound simple enough,
              RB was figuring out how to do all of this on the fly—learning on
              the job, so to speak. She knew it was possible because the
              single-family home she purchased had the exact same layout as the
              multifamily home she had originally been interested in purchasing.
              She sought the help of professionals, such as architects,
              electricians, and plumbers, consulted with her local building
              department to make sure she was getting the proper permits, and
              started making progress.
            </p>
            <p>
              Once her property's conversion was complete and occupied by
              tenants, she realized the huge advantages of what she had done:
              she immediately had huge equity in her property since she was now
              collecting rent from two families but still only had a mortgage
              payment for a single family home. The equity she was able to pull
              out of her initial property was used to rinse and repeat this
              process of obtaining single family homes and converting them into
              multifamily properties.
            </p>
            <p>
              The advantages of this conversion were immediately apparent to RB.
              She was now collecting rent from two families but only had a
              mortgage payment for a single-family home, thus building
              significant equity in the property. This equity allowed her to
              invest in more single-family homes, convert them into multi-family
              properties, and repeat the process. Soon, RB had built a portfolio
              of profitable multi-family properties that generated a stable
              stream of passive income.
            </p>
            <p>
              Pre Pandemic, RB was deeply immersed in community engagement,
              helping others understand how real estate can help build
              generational wealth. Her focus was on the importance of home
              ownership, the benefits that come with it, and how to create a
              business. She also tries to educate people about the resources
              available to them, from how to obtain grants, down payment
              assistance, closing cost assistance, and how people can get their
              credit score repaired for free.
            </p>
            <p>
              When she's working with a client that has a budget on the smaller
              side, she advises them to focus on the locations that fit their
              budget. The number of multifamily homes to choose from drastically
              increases when one broadens the locations they're willing to
              invest in. She also makes sure her clients understand how to
              squeeze every last bit of profit from their property, often in
              ways they may not have thought of. For example, in New York, space
              is at a premium. Garage space and driveway space are all rentable.
            </p>
            <p className="mb-4">
              While RB acknowledges that many people are able to profit from
              real estate arbitrage, her goal for her clients is to purchase
              property and leverage that investment in order to purchase
              additional properties.
            </p>

            <h3 className="primary-color">
              <b>The Importance of Having Systems in Place</b>
            </h3>
         
   
                <p>
                  RB describes her personal real estate learning experience as
                  learning to fly a plane. Having discovered firsthand what
                  works and what doesn't, she now wholeheartedly emphasizes the
                  importance of documentation.
                </p>
                <p>
                  Communication is key to a successful relationship between
                  landlord and tenant. Setting a level of expectation between
                  the two is critical. The tenant gets a clear understanding of
                  the property and what they're responsible for, and the
                  landlord learns what is important to the tenant. RB has never
                  had to evict anyone since she started her real estate journey
                  in 2003, and keeping that open line of communication is
                  important.
                </p>


            <p className="mb-4">
              A perfect example of great communication is anytime you have a
              conversation with a tenant regarding any issues you may be having,
              whether it be noise, damage, or something else from your lease
              that may need to be enforced, mention to them that you know life
              is hectic and busy so you'll send them a written copy of the
              conversation for their convenience so there's no confusion. Doing
              this makes you seem like a reasonable, responsible landlord to the
              tenant, but you're also documenting any instances that may need to
              be referenced later when their lease ends, such as reasons why
              they're not receiving their full deposit back.
            </p>

            <h3 className="primary-color">
              <b>Help on Speed Dial</b>
            </h3>

            <p>
              As a landlord, it's important to have a reliable network of
              electricians, plumbers, contractors, and handymen available to
              help with any issues that may arise. If an issue is not addressed
              right away, tenants often feel like you're dragging your feet,
              which starts to taint their experience at your property. Rental
              properties require ongoing maintenance and repairs, and having a
              team of professionals you can rely on can save you time, money,
              and stress in the long run.
            </p>
            <p>
              Electrical and plumbing issues can be particularly urgent as they
              can pose safety risks and cause damage to the property. Having an
              electrician or plumber you trust on speed dial can help you
              address these issues quickly and effectively. Similarly, having a
              contractor available for larger-scale repairs or renovations can
              help keep your properties in top condition and attract quality
              tenants.
            </p>
            <p className="mb-4">
              A reliable team of handymen can also be invaluable for handling
              smaller maintenance tasks, like painting or patching walls. By
              having a network of professionals you can call on, you can avoid
              the hassle of trying to find a qualified technician in a pinch and
              ensure that your properties are well-maintained and in good
              condition.
            </p>

            <h3 className="primary-color">
              <b>Property Management Software</b>
            </h3>
            <div>
              <Image
                src={Image2}
                alt="blog-image-2"
                className={"blog-image mb-3"}
                // style={{ width:"500px", float: 'left', marginRight: '16px', marginBottom: '16px' }}
              />
              <div>
            <p>
              Landlords have a lot to do, including managing tenants, responding
              to maintenance requests, and monitoring rental revenue and
              expenses. It can be difficult to keep up with everything,
              especially if the landlord has several properties. Software for
              property management, such as <a href="https://www.guesty.com/" target="_blank" className="blog-content-link">Guesty</a> or  <a href="https://www.lodgify.com/" target="_blank" className="blog-content-link">Lodgify</a>, can be useful in
              this situation. The use of these platforms simplifies procedures
              and benefits landlords.
            </p>
            <p>
              Landlords can automate a lot of their activities with property
              management software, which is one of its key advantages. For
              instance, programs such as Furnished Finder’s sister company, <a href="https://www.keycheck.com/" target="_blank" rel="noreferrer">KeyCheck</a>, accept online rent payments and sends automated rent
              reminders. Besides saving time, this streamlines the leasing
              process for both landlords and tenants.
            </p>
          
            </div>
            {/* <div style={{ clear: 'both' }}>
      
      </div>   */}
            </div>
            <p>
              Landlords can automate a lot of their activities with property
              management software, which is one of its key advantages. For
              instance, programs such as Furnished Finder’s sister company,
              KeyCheck, accept online rent payments and sends automated rent
              reminders. Besides saving time, this streamlines the leasing
              process for both landlords and tenants.
            </p>
            <p className="mb-4">
              Another advantage of using property management software is that it
              provides landlords with a central hub for managing all of their
              properties. They can use the software to keep track of rental
              income and expenses, manage maintenance requests, and communicate
              with tenants. This allows landlords to stay organized and ensure
              that nothing falls through the cracks. It also makes it easier for
              them to scale their business as they acquire more properties.
            </p>

            <h3 className="primary-color">
              <b>Recession Advice</b>
            </h3>

            <p>
              As we may be heading into a recession, RB suggests investors build
              their cash reserves to be able to take advantage of property
              purchases. Someone may find themselves in a bind and need to make
              a quick sale of their property. If you're in a position to buy,
              you not only help them out but are likely to pick up a property at
              a price below market value.
            </p>
  

            <h3 className="primary-color">
              <b>Advice for her younger self</b>
            </h3>
            <div>
            <Image src={Image3} alt="blog-image-3" className={"blog-image mb-3"}
                // style={{ width:"500px", float: 'left', marginRight: '16px', marginBottom: '16px' }}
            

            />
            <div>
            <p>
              Never get comfortable. It's important to note that in the real
              estate investing industry, change is constant. Whether it's shifts
              in the market, changes in tenant needs and demands, or new
              technologies emerging, it's essential to stay adaptable and
              willing to pivot in order to remain successful.
            </p>
            <p>
              Staying on top of industry trends and constantly educating oneself
              is part of this. Attending conferences, networking with other
              investors, or reading books and articles by industry experts could
              all be part of this. It may also imply looking for new ways to
              improve tenant experiences, such as incorporating smart home
              technology or providing more personalized services.
            </p>
            </div>
            {/* <div style={{ clear: 'both' }}>
      
      </div>   */}
            </div>
  
            
            <p>
              In addition, it's important to remember that real estate investing
              is not just about making money but also about creating positive
              impact in communities. By focusing on providing safe and
              comfortable living spaces for tenants, being responsive to their
              needs, and supporting local businesses and organizations,
              investors can create a sense of community and foster long-term
              relationships with tenants.
            </p>
            <p>
              Overall, the key takeaway for any real estate investor is to keep
              an open mind, stay informed, and always be on the lookout for ways
              to improve and add value to tenants and the community. By doing
              so, investors can not only weather the market's ups and downs but
              also thrive in the long run.
            </p>
            <div className="podcasts mb-3">
              <h4> Listen to the Podcast here</h4>
              <a
                href="https://youtu.be/IpyqBHYDlAk?feature=shared"
                className={`me-3`}
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube style={{ color: "FF0000", fontSize: "30px" }} />
              </a>
              <a
                href="https://open.spotify.com/episode/6zIdWX5IxAKcVUWOKSs7n9?si=cJqJRtr0Q--RQ_msPBj5qQ"
                className="me-3"
                target="_blank"
                rel="noreferrer"
              >
                <FaSpotify style={{ color: "#1DB954", fontSize: "28px" }} />
              </a>
              <a
                href="https://podcasts.apple.com/us/podcast/ff17-bettering-your-community-through-real-estate-balancing/id1641540804?i=1000589941451"
                className="me-3"
                target="_blank"
                rel="noreferrer"
              >
                <FaPodcast style={{ color: "#872ec4", fontSize: "26px" }} />
              </a>
            </div>
            <hr />
            <div className="mb-3 back-link">
              <Link to="/blog" className="primary-color fs-6">
                Back to Blog Home Page
              </Link>
            </div>
          </Col>
          <Col md={4} className={`${styles["blog-right-panel"]} ${styles["latest-column"]}`}>
            <LatestArticles />
          </Col>
        </Row>
      </Container>
      <div className={"scroll-wrap"}>
        <div
          onClick={executeScroll}
          className={"scroll-icon"}
          style={{ display: showScroll ? "flex" : "none" }}
        >
          <MdOutlineArrowUpward />
        </div>
      </div>
    </>
  );
};

export default POB3;
