import React, { useRef, useState } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import Image1 from "../../../assets/images/blogs/propety_owners/rapid-expansion-and-success-dee-coulsons-rental-arbitrage-journey/pob_2_1.webp";
import Image2 from "../../../assets/images/blogs/propety_owners/rapid-expansion-and-success-dee-coulsons-rental-arbitrage-journey/pob_2_2.webp";
import Image3 from "../../../assets/images/blogs/propety_owners/rapid-expansion-and-success-dee-coulsons-rental-arbitrage-journey/pob_2_3.png";

import { Link } from "react-router-dom";
import { MdOutlineArrowUpward } from "react-icons/md";
import { FaSpotify, FaYoutube, FaPodcast } from "react-icons/fa";
import LatestArticles from "../latest-articles";
import styles from "../Blog.module.css";

const POB2 = () => {
  const topRef = useRef<HTMLDivElement>(null);

  const executeScroll = () => topRef.current?.scrollIntoView();

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  return (
    <>
      <Container fluid id={"blog-detail"} ref={topRef}>
        <Row>
          <Col md={8} className={styles["blog-left-panel"]}>
            <Row className="mb-2 mt-2">
              <h2 className="primary-color mb-2">
                Rapid Expansion and Success: Dee Coulson's Rental Arbitrage
                Journey
              </h2>

              <span className="fs-6 mb-3">
                Author : FF Team | September 18, 2023
              </span>
            </Row>
            <Image
              src={Image1}
              alt="blog-image-1"
              className={"blog-image mb-3"}
            />

            <p>
              In just six months, rental arbitrage expert Dee Coulson
              successfully expanded her portfolio from one apartment suite to 14
              units. Her business,{" "}
              <a
                href="https://luxhighendproperties.com/"
                target="_blank"
                className="blog-content-link"
              >
                luxhighendproperties.com
              </a>
              , specializes in offering flexible-term housing (mid-term rentals
              of 30+ days) that is all-inclusive for traveling medical
              professionals. To ensure her properties are as attractive and
              inviting as possible, she makes sure the apartment complexes have
              superior services and amenities, including access to swimming
              pools, gyms, and rooftop lounges.
            </p>
            <p>
              Dee decided to enter this specialized market after realizing the
              high demand for temporary housing among traveling nurses during
              the difficult times of the COVID-19 pandemic. She started speaking
              with landlords and property managers, describing her rental
              arbitrage business model and the advantages it provided to both
              the property owners and the traveling medical professionals in
              need of upscale lodging.
            </p>
            <p>
              Dee leases properties, furnishes them to a high standard, and
              rents them out at competitive rates. She only engages in rental
              arbitrage. Dee ensures transparency by developing close
              relationships with property owners who are aware of her rental
              arbitrage operations, despite the fact that many apartment
              buildings have strict policies against subleasing.
            </p>
            <p className="mb-4">
              Due to Dee's effective financial management, her business was able
              to grow quickly. She was able to finance the furnishing of her
              next property by using the deposit and first month's rent from her
              first rental property. She continued in this manner until she
              reached her current 14-unit portfolio. She notes that it cost her
              $6,000 to fully furnish her first apartment.
            </p>
            <h3 className="primary-color">Insurance and Arbitrage</h3>

            <p>
              Insurance is a crucial aspect of rental arbitrage, and Dee Coulson
              emphasizes its importance in protecting both the property owner
              and the tenants. While rental properties typically require renters
              insurance, even in cases where the owner is not personally
              occupying the unit, Dee shares a close call incident to illustrate
              the significance of having appropriate insurance coverage.
            </p>
            <p>
              In this particular incident, one of Dee's tenants, exhausted from
              a 14-hour shift, started cooking on the stove and unintentionally
              fell asleep. As time passed, the food on the stove began to burn,
              filling the unit with smoke and triggering the smoke alarms.
              Fortunately, the fire department arrived promptly and managed to
              extinguish the fire before it caused any significant damage. Dee
              highlights that having the proper insurance coverage was
              instrumental in this situation, as it would have protected her
              from potential financial liability for damages and injuries.
            </p>
            <p className="mb-4">
              As a result of her experience, Dee is able to provide valuable
              advice for property owners who rent out their properties: it is
              essential to have landlord insurance designed specifically for
              rental properties, as opposed to just renter's insurance or
              homeowner's insurance, which wouldn't cover losses in this
              situation because they both require the owner-tenant to reside in
              the property and may not be adequate to cover accidents or
              liabilities arising from rental activities.
            </p>
            <h3 className="primary-color">Interior Design</h3>

            <p>
              Dee Coulson emphasizes the significance of paying attention to the
              space available and choosing furniture that appropriately fills
              the rooms when furnishing rental properties for medium-term stays.
              A well-designed and tastefully furnished space not only improves
              the aesthetic appeal overall but also provides tenants with a
              practical and cozy environment.
            </p>
            <div>
              <Image
                src={Image2}
                alt="blog-image-2"
                className={"blog-image mb-3"}
                // style={{ width:"500px", float: 'left', marginRight: '16px', marginBottom: '16px' }}
              />

              <div>
                <p>
                  Selecting the proper-sized couch is a crucial component of
                  furnishing living spaces. The couch should be in proportion to
                  the size of the room; it shouldn't be either too big or too
                  small to be inconsequential. A well-matched coffee table next
                  to the couch creates a focal point and offers tenants a useful
                  surface to set things down on or congregate around. A TV stand
                  or mounted TV with a piece of furniture underneath, like a
                  credenza, also adds visual interest and makes good use of
                  vertical space.
                </p>
                <p>
                  Once the essential furniture pieces are chosen, it is
                  relatively simple to add thoughtful finishing touches to the
                  remaining space. The addition of indoor plants not only adds
                  life and vibrancy to the space but also fosters peace and
                  natural beauty. Wall hangings, mirrors, and other decorative
                  accents can turn plain walls into eye-catching features and
                  create a cozier atmosphere. The coziness and comfort of
                  seating areas can be improved by adding plush pillows and
                  throws, and carefully chosen decorative accents, such as
                  vases, sculptures, or eye-catching lighting fixtures, can add
                  character and style.
                </p>
              </div>
            </div>
            <p>
              Area rugs are also a great way to add color and pattern to a room.
              And don't forget about the importance of proper lighting! Layering
              different types of lighting, such as overhead fixtures, table
              lamps, and floor lamps, can create a warm and inviting ambiance.
              Finally, incorporating personal touches like local city photos and
              artwork can make a space feel truly unique and special.
            </p>
            <p>
              When choosing these extra decor items, Dee advises property owners
              to strike a balance between functionality and aesthetics. These
              finishing touches can be carefully chosen and arranged to
              personalize the area, increase its appeal to potential tenants,
              and create a warm and inviting atmosphere that distinguishes the
              property from others on the market.
            </p>

            <p>
              In a rental arbitrage business, the cash flow that each unit
              generates can vary greatly depending on elements like unit size
              and market competition. It can be challenging to set rental prices
              because there is no universal formula; however, many people price
              their units at twice what their base rent is. This permits a
              comfortable profit margin while maintaining market
              competitiveness.
            </p>
            <p>
              Dee considers apartment buildings to be especially beneficial in
              terms of "bang for your buck." The availability of on-site staff
              within apartment buildings is a significant factor contributing to
              cost efficiency. By relying on the on-site staff, Dee can minimize
              the need to hire external contractors or maintenance personnel,
              reducing additional costs that would otherwise eat into her
              profits. The staff members take care of various maintenance tasks,
              such as addressing plumbing or electrical issues, fixing
              appliances, and handling repairs within the units. This
              streamlined maintenance process not only saves time but also
              eliminates the need for Dee to personally attend to each
              maintenance request, allowing her to focus on other aspects of her
              rental arbitrage business.
            </p>
            <p>
              Furthermore, the on-site staff is in charge of grounds
              maintenance, ensuring that the overall property is well-kept and
              aesthetically pleasing. This includes tasks like landscaping, lawn
              care, and communal area maintenance. They also manage amenities
              such as swimming pools, gyms, rooftop lounges, and any other
              amenities provided by the apartment building. These sought after
              amenities are available to Dee for just the cost of her base rent,
              increasing the attractiveness of her rental properties and
              allowing her to charge higher rent, all without incurring
              significant expenses.
            </p>

            <Image
              src={Image3}
              alt="blog-image-3"
              className={"blog-image mb-3"}
            />

            <p>
              In the early stages of developing her portfolio, Dee noticed that
              the typical cash flow per unit ranged from $500 to $900. The main
              factors influencing this variation were unit size and market
              demand. Larger units typically command higher rental rates due to
              their increased square footage and ability to house more tenants,
              but they also have a smaller pool of potential tenants. Dee has
              discovered that studio and one-bedroom apartments have a number of
              benefits for her portfolio, especially in terms of ease of
              furnishing. These smaller units call for fewer furniture pieces
              and other accessories, which translates to lower initial costs and
              quicker setup. She has streamlined her processes and improved her
              efficiency in getting her properties ready for rental by
              concentrating on these unit types.
            </p>
            <p className="mb-4">
              Additionally, her preference for one-bedroom and studio apartments
              meets the needs of her target market of traveling medical
              professionals. These people frequently need a small, comfortable
              home that can accommodate their mid-term housing needs while on
              assignments or rotations.
            </p>

            <h3 className="primary-color">Keycheck</h3>

            <p>
              Dee uses{" "}
              <a
                href="https://www.keycheck.com/"
                target="_blank"
                className="blog-content-link"
              >
                Keycheck
              </a>{" "}
              to assist with tenant vetting and background checks. Keycheck is a
              comprehensive platform that streamlines the tenant screening
              procedure and gives landlords the tools they need to choose
              tenants with confidence. Keycheck provides a number of useful
              features with its simple interface and powerful tools. In-depth
              background checks, thorough credit analyses, eviction history
              reports, income verification, tenant scorecards, customizable
              criteria, and prompt results are some of these features.
            </p>

            <div className="podcasts mb-3">
              <h4> Listen to the Podcast here</h4>
              <a
                href="https://youtu.be/5cF9DoCkGvM"
                className={`me-3`}
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube style={{ color: "FF0000", fontSize: "30px" }} />
              </a>
              <a
                href="https://open.spotify.com/episode/5FBYf9CHK2qWYIuxOI5JxO?si=Ggp2kEk0RsC7C0CSrHIpew"
                className="me-3"
                target="_blank"
                rel="noreferrer"
              >
                <FaSpotify style={{ color: "#1DB954", fontSize: "28px" }} />
              </a>
              <a
                href="https://podcasts.apple.com/us/podcast/ff42-from-a-thought-to-14-mid-term-arbitrage/id1641540804?i=1000616675690"
                className="me-3"
                target="_blank"
                rel="noreferrer"
              >
                <FaPodcast style={{ color: "#872ec4", fontSize: "26px" }} />
              </a>
            </div>
            <hr />
            <div className="mb-3 back-link">
              <Link to="/blog" className="primary-color fs-6">
                Back to Blog Home Page
              </Link>
            </div>
          </Col>
          <Col
            md={4}
            className={`${styles["blog-right-panel"]} ${styles["latest-column"]}`}
          >
            <LatestArticles />
          </Col>
        </Row>
      </Container>
      <div className={"scroll-wrap"}>
        <div
          onClick={executeScroll}
          className={"scroll-icon"}
          style={{ display: showScroll ? "flex" : "none" }}
        >
          <MdOutlineArrowUpward />
        </div>
      </div>
    </>
  );
};

export default POB2;
