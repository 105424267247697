import React from "react";
import { MdClose } from "react-icons/md";
import styles from "./PropertyDetails.module.css";
import { Carousel } from "react-responsive-carousel";
const ImageModal = (props: any) => {
  const customPrevArrowModal = (clickHandler: () => void) => (
    <div
      className={styles["customPrevArrow"]}
      style={{
        position: "fixed",
        zIndex: 2,
        top: "50%",
        left: "3%",
        width: 30,
        cursor: "pointer",
        display: props.currentSlide === 0 ? "none" : "block",
      }}
      onClick={clickHandler}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        className="control-arrow control-next"
        style={{ transform: "rotate(180deg)" }}
      >
        <g
          id="Group_374"
          data-name="Group 374"
          transform="translate(-1119.92 -1629.92)"
        >
          <path
            id="Path_163"
            data-name="Path 163"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            transform="translate(1119.92 1629.92)"
            fill="#fff"
          />
          <g
            id="Group_163"
            data-name="Group 163"
            transform="translate(1124.845 1636.189)"
          >
            <path
              id="Path_116"
              data-name="Path 116"
              d="M0,0,5.764,6.141,11.461,0"
              transform="translate(7.076 11.462) rotate(-90)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M6157.313,10281H6144.1"
              transform="translate(-6144.097 -10275.269)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </svg>
    </div>
  );
  const customNextArrowModal = (clickHandler: () => void) => (
    <div
      className={styles["customNextArrow"]}
      style={{
        position: "fixed",
        zIndex: 2,
        top: "50%",
        width: 30,
        right: "3%",
        cursor: "pointer",
        display:
          props.currentSlide ===
          props.propertyDetails.PropertyInfoImages.images.length - 1
            ? "none"
            : "block",
      }}
      onClick={clickHandler}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        className="control-arrow control-next"
      >
        <g
          id="Group_374"
          data-name="Group 374"
          transform="translate(-1119.92 -1629.92)"
        >
          <path
            id="Path_163"
            data-name="Path 163"
            d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z"
            transform="translate(1119.92 1629.92)"
            fill="#fff"
          />
          <g
            id="Group_163"
            data-name="Group 163"
            transform="translate(1124.845 1636.189)"
          >
            <path
              id="Path_116"
              data-name="Path 116"
              d="M0,0,5.764,6.141,11.461,0"
              transform="translate(7.076 11.462) rotate(-90)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
            <path
              id="Path_117"
              data-name="Path 117"
              d="M6157.313,10281H6144.1"
              transform="translate(-6144.097 -10275.269)"
              fill="none"
              stroke="#005682"
              strokeLinecap="round"
              strokeWidth="1.5"
            />
          </g>
        </g>
      </svg>
    </div>
  );
  return (
    <div className={styles["popup-overlay"]}>
      <div>
        <button
          className={styles["close-button"]}
          onClick={() => props.setImgModal(false)}
        >
          <MdClose className={styles["md-close-btn"]} />
          <p style={{ color: "white" }}>Close</p>
        </button>
      </div>
      <div className={styles["pageDiv"]}>
        <p style={{ color: "white" }}>
          {props.currentSlide + 1}/
          {props.propertyDetails.PropertyInfoImages.images.length}
        </p>
      </div>
      <div className={styles["popup-content"]}>
        <Carousel
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          className={'property-details-carousel'}
          renderArrowPrev={customPrevArrowModal}
          renderArrowNext={customNextArrowModal}
          onChange={props.handleSlideChange}
          selectedItem={props.currentSlide}
        >
          {props.propertyDetails &&
            props.propertyDetails?.PropertyInfoImages?.images.map(
              (image: any) => {
                return (
                  <div className={styles["contentCarousel"]}>
                    <img src={image.url} alt=""/>
                  </div>
                );
              }
            )}
        </Carousel>
      </div>
    </div>
  );
};

export default ImageModal;
