import React, { useState, useRef, FormEvent, useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
  Image,
} from "react-bootstrap";
import styles from "./Contact.module.css";
import Building from "../../assets/images/building.png";
import { MdOutlineArrowUpward } from "react-icons/md";
import { postContactAsync } from "./contactSlice";

const Contact = () => {
  const dispatch = useAppDispatch();
  const [validated, setValidated] = useState(false);
  const [alert, setAlert] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [department, setDepartment] = useState("");
  const [department2, setDepartment2] = useState("");
  const [department3, setDepartment3] = useState("");
  const [showDepartment2, setShowDepartment2] = useState(false);
  const [showDepartment3, setShowDepartment3] = useState(false);
  const [department2Options, setDepartment2Options] = useState<any>([]);
  const [department3Options, setDepartment3Options] = useState<any>([]);


  const [showKnowledge, setShowKnowledge] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity()) {
      event.preventDefault();
      setAlert(true);
      dispatch(
        postContactAsync({
          name,
          email,
          phone,
          department,
          message,
        })
      );

      setTimeout(() => {
        setName("");
        setEmail("");
        setPhone("");
        setDepartment("");
        setMessage("");
        setValidated(false);
      }, 100);
    }
  };
  const topRef = useRef<HTMLDivElement>(null);

  const executeScroll = () => topRef.current?.scrollIntoView();

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);

  const changeDepartment = (value: any) => {
    setDepartment(value);
    setShowDepartment2(false);
    setShowDepartment3(false);
    setDepartment2('')
    setDepartment3('')

  };

  const changeDepartment2 = (value: any) => {
    setShowKnowledge(false)
    setDepartment2(value);
    setShowDepartment3(false);
    setDepartment3('')
  };

  const changeDepartment3 = (value: any) => {
    setShowKnowledge(false)
    setDepartment3(value);
    // setShowDepartment3(false);
  };
  useEffect(() => {
    if (
      department === "I want to list my property" ||
      department === "I want to list a property with Furnished Apartments" ||
      department === "I have paid the Furnished Apartments membership fee and need support" ||
      department === "I am a Traveler" ||
      department === "I am a Company interested in a Partnership" ||
      department === "I need help with a Lease,Tenant Screening or Payment Collection" ||
      department === "I already joined Furnished Apartments, what's next?" ||
      department === "I need help completing my listing"
    ) {
      setShowKnowledge(true);
    } else {
      setShowKnowledge(false);
    }

    if(department ===
        "I have paid the Furnished Apartments membership fee and need support") {
            setShowDepartment2(true)
            setDepartment2Options([
                '--Select a query--',
                'I need help with my listing',
                'I want to renew,add listings,units or OVB',
                'Report a user'
            ])
        }
        else if(department ==='I am a Traveler') {
          setShowDepartment2(true)
          setDepartment2Options([
              '--Select a query--',
              'I need help finding housing',
              'I need help with my account',
              'Report a user'
    
          ])
        }
        else if(department ==='I need help completing my listing') {
          setShowDepartment2(true)
          setDepartment2Options([
              '--Select a query--',
              'How do I edit my property headline?',
              'How do I change the username displayed on my listing?',
              'How do I upload or edit photos?',
              'I need to change my property address',
              'How do I set the availability date of my property?',
              'I just finished my listing and I\'d like to know if everything is setup correctly',
              'I have multiple units, how do I edit them?',
              'I have multiple listings, how do I edit them?',
              'What\'s the average rent value in my area?'
    
          ])
        } 
        else if(department === 'I need help with my listing public view on the map') {
          setShowDepartment2(true)
          setDepartment2Options([
              '--Select a query--',
              'My listing is not on the map',
              'I made changes and they are not displayed on the map, why?',
              'My main photo is not displayed on the map'
          ])
        }

        else if(department === 'Managing my Listing') {
          setShowDepartment2(true)
          setDepartment2Options([
              '--Select a query--',
              'My listing it not active yet, why?',
              'How do I restore my listing?',
              'How do I share my listing or see my property number',
              'What\'s an extra unit and how to add it?',
              'What\'s an OVB and how to add it?',
              'How do I delete my listing?',
              'How do I temporarily hide my listing?',
              'How do I pause e-mail notifications?',
              'How do I get reviews from tenants?',
              'How do I remain in the top status of your search results?'


          ])
        }

        else if(department === 'Managing my tenant leads') {
          setShowDepartment2(true)
          setDepartment2Options([
              '--Select a query--',
              'How do I connect with Tenant Leads?',
              'What are Unmatched Leads?',
              'Accessing and responding to Tenant Messages',
              'I want to receive Housing Requests submitted for locations either further from or closer to my property'
          ])
        }

        else if(department === 'My account settings') {
          setShowDepartment2(true)
          setDepartment2Options([
              '--Select a query--',
              'How do I log in to my account?',
              'How do I change my password?',
              'Managing my credit cards',
              'How do I renew my membership?',
              'How do I enable/disable autorenew?'
          ])
        }

        


  }, [department]);
  
  useEffect(() => {

    
    if(department2 ===
        'I need help with my listing') {
            setShowDepartment3(true)
            setDepartment3Options([
                '--Select a query--',
                'I have failed the verification process',
                'I want to restore my listing',
                'I purchased the Listing Setup Service',
                'Edit and manage my listing',
                'Disable/cancel membership'
            ])
        } 

        if(department2 === "How do I edit my property headline?" ||
         department2 === "I need help with my listing" ||
        department2 === "I want to renew,add listings,units or OVB" ||
        department2 === "How do I upload or edit photos?" ||
        department2 === "I need to change my property address" ||
         department2 === "How do I set the availability date of my property?" ||
        department2 === "I just finished my listing and I'd like to know if everything is setup correctly" ||
        department2 === "I have multiple units, how do I edit them?" ||
        department2 === "I have multiple listings, how do I edit them?" ||
        department2 === "What's the average rent value in my area?" ||
        department2 === "My listing is not on the map" ||
        department2 === "My main photo is not displayed on the map" ||
        department2 === "My listing it not active yet, why?" ||
        department2 === "How do I restore my listing?" ||
        department2 === "How do I share my listing or see my property number" ||
        department2 === "What's an extra unit and how to add it?" ||
        department2 === "What's an OVB and how to add it?" ||
        department2 === "How do I delete my listing?" ||
        department2 === "How do I temporarily hide my listing?" ||
        department2 === "How do I pause e-mail notifications?" ||
        department2 === "How do I get reviews from tenants?" ||
        department2 === "How do I remain in the top status of your search results?" ||
        department2 === "How do I connect with Tenant Leads?" ||
        department2 === "What are Unmatched Leads?" ||
        department2 === "Accessing and responding to Tenant Messages" ||
        department2 === "I want to receive Housing Requests submitted for locations either further from or closer to my property" ||
        department2 === "How do I log in to my account?" ||
        department2 === "How do I change my password?" ||
        department2 === "Managing my credit cards" ||
        department2 === "How do I renew my membership?" ||
        department2 === "How do I enable/disable autorenew?" ||
        department2 === "How do I find housing?" ||
        department2 === "How do I leave a review on a property owner's listing?" ||
        department2 === "How do I manage/delete my housing requests?" ||
        department2 === "How do I manage my account at Furnished Apartments?" ||
        department2 === "How do I change the username displayed on my listing?"
        ){
          setShowKnowledge(true)
        }
        else {
          setShowKnowledge(false)
        }
    
  }, [department2])

    
  useEffect(() => {

        if( department3 === "I have failed the verification process" ||
        department3 === "Disable/cancel membership"){
          setShowKnowledge(true)
        }
        else {
          setShowKnowledge(false)
        }
    
  }, [department3])

  const addKnowledgeBaseLinks = () => {
    if (department === "I want to list my property") {
        
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/how-to-list-your-property">
              How Do I List My Property on Furnished Apartments and Travel Nurse
              Housing
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/how-to-list-your-property#your-account-login-credentials">
              How Do I Login To My Account
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/how-to-list-your-property#choosing-an-option-to-set-up-your-property-listing">
              Will You Setup My Listing For Me
            </a>
          </li>
        </ul>
      );
    } else if (
      department === "I want to list a property with Furnished Apartments"
    ) {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/how-to-list-your-property#list-your-property-definition">
              How to List My Property with Furnished Apartments and Travel Nurse
              Housing
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/how-to-list-your-property#choosing-an-option-to-set-up-your-property-listing">
              Will You Setup My Listing For Me?
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-account#managing-your-dashboard1">
              Verifying my Identity to List my Property
            </a>
          </li>
        </ul>
      );
    } else if (
      department ===
      "I have paid the Furnished Apartments membership fee and need support"
    ) {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/property-ownership-verification#your-temporary-property-listing-number">
              How do I log into My Account?
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/how-to-list-your-property#choosing-an-option-to-set-up-your-property-listing">
              Will You Setup My Listing For Me?
            </a>
          </li>
        </ul>
      );
    } else if (department === "I am a Traveler") {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#submitting-housing-request">
              Submitting a Housing Request
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#leaving reviews on property listings">
              Leaving a Review on a Property Listings
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#managing-your-housing-requests">
              Managing My Housing Request
            </a>
          </li>
        </ul>
      );
    } else if (department === "I am a Company interested in a Partnership") {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/partnership">
              https://www.furnishedfinder.com/partnership
            </a>
          </li>
        </ul>
      );
    } else if (
      department ===
      "I need help with a Lease,Tenant Screening or Payment Collection"
    ) {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/state-specific-leases#purchasing-custom-residential-lease-subscription">
              Purchasing a Custom Residential Lease Subscription
            </a>
          </li>
        </ul>
      );
    } else if (
      department === "I already joined Furnished Apartments, what's next?"
    ) {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/property-ownership-verification#verifying-property-ownership">
              Verifying Property Ownership
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings">
              Your Furnished Apartments Listing Settings
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-account#setting-up-your-profile">
              Setting up you Profile
            </a>
          </li>

          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/how-to-list-your-property#choosing-an-option-to-set-up-your-property-listing">
              Will You Setup My Listing For Me
            </a>
          </li>
        </ul>
      );
    } else if (department === "I need help completing my listing") {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings">
              Editing your Property Listing
            </a>
          </li>
        </ul>
      );
    }
     
      
  };

const subTopicChange = () => {
  if (department2 === "How do I edit my property headline?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#adding-your-contact-information">
            Setting you property headline
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "I need help with my listing") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings">
            Editing My Property Listing
          </a>
        </li>
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#managing-multiple-units-under-one-property-listing">
            Editing Multiple Units
          </a>
        </li>
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-tenant-leads-and-messages#connecting-with-tenant-leads">
            How do I Connect with Tenant Leads?
          </a>
        </li>
      </ul>
    );

  
  } else if (department2 === "I want to renew,add listings,units or OVB") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-properties#adding-another-unit-to-an-existing-listing">
            What's an extra unit and how do I add it?
          </a>
        </li>
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-properties#adding-a-verified-owner-badge-to-your-listing">
            What's an Owner Verification Badge (OVB) and how do I add it?
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 === "How do I change the username displayed on my listing?"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-account#setting-up-your-profile">
            Display Name
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I upload or edit photos?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#uploading-your-property-photos">
            How do I upload or edit photos?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "I need to change my property address") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/property-ownership-verification#documents-for-property-ownership-verification">
            What documents should I submit to have my property listing address
            changed?
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 === "How do I set the availability date of my property?"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#setting-your-property-availability-date-on-the-calendar">
            Setting Your Property Availability Date On The Calendar
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 ===
    "I just finished my listing and I'd like to know if everything is setup correctly"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#viewing-your-listing-status">
            What's my listing status?
          </a>
        </li>
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#setting-your-property-availability-date-on-the-calendar">
            How do I remain in the top 'status' of your search results?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "I have multiple units, how do I edit them?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#managing-multiple-units-under-one-property-listing">
            Managing Multiple Units
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 === "I have multiple listings, how do I edit them?"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-properties#adding-another-property-listing">
            Managing Multiple Listings
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "What's the average rent value in my area?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#Searching-Our-Map">
            How do I see other property listings in my area?
          </a>
        </li>
      </ul>
    );
  }
  //I need help with my listing public view on the map
  else if (department2 === "My listing is not on the map") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#viewing-your-listing-status">
            Completing my listing 100% to make it visible on the map
          </a>
        </li>
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/property-ownership-verification">
            Verifying Property Ownership to make my listing visible on the map
          </a>
        </li>
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#setting-your-property-availability-date-on-the-calendar">
            Updating my calendar to remain in the top 'status' of your search
            results
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "My main photo is not displayed on the map") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#uploading-your-property-photos">
            Selecting my featured image
          </a>
        </li>
      </ul>
    );
  }
  //Managing my listing
  else if (department2 === "My listing it not active yet, why?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/property-ownership-verification">
            My Temporary Property Listing Number
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I restore my listing?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/property-ownership-verification#documents-for-property-ownership-verification">
            What documents should I submit to restore my listing?
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 === "How do I share my listing or see my property number"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#viewing-your-listing-status">
            How to find my listing shareable link and property number
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "What's an extra unit and how to add it?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-properties#adding-another-unit-to-an-existing-listing">
            What's an extra unit and how to add it?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "What's an OVB and how to add it?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-properties#adding-a-verified-owner-badge-to-your-listing">
            What's an OVB and how to add it?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I delete my listing?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-properties#deleting-your-property-listing">
            How do I delete my listing?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I temporarily hide my listing?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#setting-your-property-availability-date-on-the-calendar">
            How do I temporarily hide my listing?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I pause e-mail notifications?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#managing-your-notification-settings">
            How do I pause e-mail notifications
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I get reviews from tenants?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#getting-reviews-from-your-tenants">
            How do I get reviews from tenants
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 === "How do I remain in the top status of your search results?"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#setting-your-property-availability-date-on-the-calendar">
            How do I remain in the top 'status' of your search results
          </a>
        </li>
      </ul>
    );
  }
  // Managing my tenant leads
  else if (department2 === "How do I connect with Tenant Leads?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-tenant-leads-and-messages#connecting-with-tenant-leads">
            How do I connect with Tenant Leads?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "What are Unmatched Leads?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-tenant-leads-and-messages#accessing-unmatched-leads">
            What are Unmatched Leads?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "Accessing and responding to Tenant Messages") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-tenant-leads-and-messages#accessing-and-responding-to-direct-messages">
            Accessing and responding to Tenant Messages
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 ===
    "I want to receive Housing Requests submitted for locations either further from or closer to my property"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-listing-settings#managing-your-notification-settings">
            Setting my search radius to receive Housing Requests submitted for
            locations either further from or closer to my property
          </a>
        </li>
      </ul>
    );
  }
  // My account settings
  else if (department2 === "How do I log in to my account?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-account">
            How do I log in to my account?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I change my password?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-account">
            How do I change my password?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "Managing my credit cards") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-account#managing-your-credit-cards">
            Managing credit cards
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I renew my membership?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-properties#renewing-your-furnished-finder-membership">
            How do I renew my membership?
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I enable/disable autorenew?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-account#enabling-and-disabling-auto-renew">
            How do I enable/disable autorenew?
          </a>
        </li>
      </ul>
    );
  }
  //I am a Travel Nurse/Medical Professional
  else if (department2 === "How do I find housing?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#Searching-Our-Map">
            Searching Our map
          </a>
        </li>
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#submitting-housing-request">
            Submitting a Housing Request
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 === "How do I leave a review on a property owner's listing?"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#leaving%20reviews%20on%20property%20listings">
            Leaving a Review on a Property Listing
          </a>
        </li>
      </ul>
    );
  } else if (department2 === "How do I manage/delete my housing requests?") {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#managing-your-housing-requests">
            Managing My Housing Requests
          </a>
        </li>
      </ul>
    );
  } else if (
    department2 === "How do I manage my account at Furnished Apartments?"
  ) {
    return (
      <ul id="kw-list">
        <li>
          <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/travelers#managing-your-dashboard">
            Managing My Dashboard
          </a>
        </li>
      </ul>
    );
  }
}

  const onlastSubTopicChange = () => {
    if (department3 === "I have failed the verification process") {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/your-furnished-finder-account#managing-your-dashboard1">
              Verifying my Identity to Activate my Listing
            </a>
          </li>
        </ul>
      );
    } else if (department3 === "Disable/cancel membership") {
      return (
        <ul id="kw-list">
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-account#enabling-and-disabling-auto-renew">
              How do I enable/disable autorenew?
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/knowledgebase/manage-your-properties#deleting-your-property-listing">
              How do I delete my listing?
            </a>
          </li>
          <li>
            <a className="support-link" href="https://www.furnishedfinder.com/terms-of-use#terms-of-refund">
              Property Listing Terms and conditions - Refunds
            </a>
          </li>
        </ul>
      );
    }
  };

  return (
    <Container className={styles["contact-page"]} ref={topRef}>
      <Row className={`${styles["form-wrap"]} `}>
        <Col
          lg={6}
          md={12}
          xs={12}
          className={`${styles["form-container"]} mt-5`}
        >
          <h1 className="mb-0">Let's Talk</h1>
          <p className="mb-1">An Easier Way to Live</p>
          <div className={styles["focus-set"]}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-2">
                <Form.Group
                  as={Col}
                  controlId="formGridName"
                  sm={12}
                  lg={6}
                  className="mb-2 mb-lg-0"
                >
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    placeholder=""
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter your name.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridEmail" sm={12} lg={6}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder=""
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter an email.
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Form.Group className="mb-2" controlId="formGridPhonenumber">
                <Form.Label>Phone number(optional)</Form.Label>
                <Form.Control
                  placeholder=""
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group className="mb-2" controlId="formGridDepartment">
                <Form.Label>Department</Form.Label>
                <Form.Select
                  required
                  placeholder="Select a department"
                  id="department"
                  onChange={(e) => {
                    changeDepartment(e.target.value);
                  }}
                >
                  <option selected disabled value="Department">
                    Department
                  </option>

                  <option value="I want to list my property">
                    I want to list my property
                  </option>
                  <option value="I want to list a property with Furnished Apartments">
                    I want to list a property with Furnished Apartments
                  </option>
                  <option value="I have paid the Furnished Apartments membership fee and need support">
                    I have paid the Furnished Apartments membership fee and need
                    support
                  </option>
                  <option value="I am a Traveler">I am a Traveler</option>
                  <option value="I am a Company interested in a Partnership">
                    I am a Company interested in a Partnership
                  </option>
                  <option value="I just want to leave a suggestion">
                    I just want to leave a suggestion
                  </option>
                  <option value="I need help with a Lease,Tenant Screening or Payment Collection">
                  I need help with a Lease,Tenant Screening or Payment Collection
                  </option>
                  <option value="I already joined Furnished Apartments, what's next?">
                    I already joined Furnished Apartments, what's next?
                  </option>
                  <option value="I need help completing my listing">
                    I need help completing my listing
                  </option>
                  <option value="I need help with my listing public view on the map">
                    I need help with my listing public view on the map
                  </option>
                  <option value="Managing my Listing">
                    Managing my Listing
                  </option>
                  <option value="Managing my tenant leads">
                    Managing my tenant leads
                  </option>
                  <option value="My account settings">
                    My account settings
                  </option>
                  <option value="Collecting payments">
                    Collecting payments
                  </option>
                  <option value="Screening Tenants and Lease agreements">
                    Screening Tenants and Lease agreements
                  </option>
                  <option value="I am a Travel Nurse/Medical Profesional">
                    I am a Travel Nurse/Medical Professional
                  </option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  Please select a department.
                </Form.Control.Feedback>
              </Form.Group>
              {showDepartment2 && (
                <Form.Group className="mb-2" controlId="Department2Div">
                  <Form.Select id="Department2"      onChange={(e:any) => {
                    changeDepartment2(e.target.value);
                  }}>
                    {department2Options.map((option: any, index: any) => (
                     
                           <option key={option} value={option} disabled={index === 0 ? true : false} selected={index === 0 ? true : false}>
                            {option}
                          </option>
                    
                    ))}
                  </Form.Select>
                </Form.Group>
              )}

              {showDepartment3 && (
                <Form.Group id="Department3Div">
                  <Form.Select id="Department3" 
                  onChange={(e: any) => {
                    changeDepartment3(e.target.value);
                  }}>
                    {department3Options.map((option: any, index: number) => (
                      <option key={option} value={option} disabled={index === 0 ? true : false} selected={index === 0 ? true : false}>
                        {option}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              )}
              {showKnowledge && (
                <div className="mb-2">
                  <h4 className="kw-heading mb-2">
                    Instant Answers To Common Questions:
                  </h4>
                  {(showDepartment3 && department3 !=='') ? onlastSubTopicChange() :(showDepartment2 && department2 !=='') ? subTopicChange() : addKnowledgeBaseLinks()}
                </div>
              )}

              <Form.Group className="mb-2" controlId="formGridMessage">
                <Form.Label>Your Message</Form.Label>
                <Form.Control
                  required
                  as="textarea"
                  name="message"
                  rows={3}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  placeholder="Type your message here…"
                />
                <Form.Control.Feedback type="invalid">
                  Please enter a message.
                </Form.Control.Feedback>
              </Form.Group>
              <Row className="d-flex justify-content-between">
                <Col>
                  <p className={styles["txt-lft-width-sm-devices"]}>
                    Fields marked with (optional) are not mandatory.
                  </p>
                </Col>
                <Col className="d-flex justify-content-end">
                  <Button variant="primary" type="submit" >
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
          <Alert variant="success" show={alert}>
            Thanks for contacting us.
          </Alert>
        </Col>
      </Row>
      <Row className={`${styles["address-bg"]} mb-5`}>
        <Col className={`${styles["address-data"]} justify-content-end`}>
          <Card className={`${styles["address-card"]} mt-5 mb-2 mb-lg-5`}>
            <Card.Body>
              <div className="d-flex align-items-start">
                <Image
                  src={Building}
                  className={`${styles["address-icon"]} me-3`}
                  alt=""
                />

                <div>
                  <Card.Title>Business Hours:</Card.Title>
                  <p>Mon-Fri: 8am - 8pm MST </p>
                  <p>Sat: 9am - 5pm MST </p>
                  <p>Sun: 9am - 5pm MST</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className={styles["address-data"]}>
          <Card className={`${styles["address-card"]} mt-2 mb-5 mt-lg-5`}>
            <Card.Body>
              <div className="d-flex align-items-start">
                <Image
                  src={Building}
                  className={`${styles["address-icon"]} me-3`}
                  alt=""
                />
                <div>
                  <Card.Title>Address:</Card.Title>
                  <p>12900 Stroh Ranch Place Suite 125 Parker, CO 80134</p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div className={styles["scroll-wrap"]}>
        <div
          onClick={executeScroll}
          className={styles["scroll-icon"]}
          style={{ display: showScroll ? "flex" : "none" }}
        >
          <MdOutlineArrowUpward />
        </div>
      </div>
    </Container>
  );
};

export default Contact;
