import { useMemo, useState } from "react";
import { GoogleMap, InfoWindow } from "@react-google-maps/api";

import { OverlayView } from "@react-google-maps/api";
import { Row, Col, Image } from "react-bootstrap";
import styles from "./PropertyList.module.css";
import { Link } from "react-router-dom";
import { Marker, MapContainerProps } from "./propertyListSlice";
const MapContainer = (props: MapContainerProps) => {
  const [activeMarker, setActiveMarker] = useState<Marker | null>(null);

  const handleActiveMarker = (markerId: any) => {
    setActiveMarker(null);
    if (markerId === activeMarker) {
      return;
    }
    setTimeout(() => {
      setActiveMarker(markerId);
    }, 0);
  };

  const containerStyle = {
    width: "100%",
    height: "600px",
  };
  const center = useMemo(
    () => ({
      lat: parseFloat(props?.lat ?? "37.6000"),
      lng: parseFloat(props?.lng ?? "-95.6650"),
    }),
    [props?.lat, props?.lng]
  );
  const handleOnLoad = (map: any) => {
    try {
      const bounds = new google.maps.LatLngBounds();
      props?.markers?.forEach((data: any) => {
        bounds?.extend({ lat: data.lat, lng: data.long });
      });
      map.fitBounds(bounds);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      {props?.markers ? (
        <div>
          <GoogleMap
            key={JSON.stringify(props.markers)}
            onLoad={handleOnLoad}
            center={center}
            zoom={12}
            mapContainerStyle={containerStyle}
            onClick={() => setActiveMarker(null)}
          >
            {props?.markers?.map((marker: any) => (
              // {markersData?.markers?.map((marker: any) => (

              <OverlayView
                position={{
                  lat: parseFloat(marker?.lat),
                  lng: parseFloat(marker?.long),
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                key={marker.id}
              >
                <div
                  style={{
                    border:
                      props.selectedProperty === marker.id
                        ? "1px solid white"
                        : "1px solid black",
                    backgroundColor:
                      props.selectedProperty === marker.id
                        ? "black"
                        : marker.PType === "Apartment"
                        ? "#005783"
                        : marker.PType === "Condo"
                        ? "#58B754"
                        : marker.PType === "House"
                        ? "#58B754"
                        : marker.PType === "Room"
                        ? "#682282"
                        : "#228265",
                    padding: "5px 10px",
                    zIndex: props.selectedProperty === marker.id ? 5 : 1,
                  }}
                  className={`${styles["mrk-container"]} ${styles["anime"]}`}
                  onClick={() => handleActiveMarker(marker.id)}
                >
                  {marker.Price}
                  {activeMarker === marker.id ? (
                    <InfoWindow
                      onCloseClick={() => setActiveMarker(null)}
                      position={{
                        lat: parseFloat(marker?.lat),
                        lng: parseFloat(marker?.long),
                      }}
                    >
                      <div>
                        <Row className="justify-content-between overflow-hidden">
                          <Col md={4}>
                            <Link
                              to={`/search-map/property/${marker.id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <Image
                                src={marker.images[0].url}
                                style={{
                                  width: "80px",
                                  height: "-webkit-fill-available",
                                }}
                                alt=""
                              />
                            </Link>
                          </Col>
                          <Col md={8}>
                            <Link
                              to={`/search-map/property/${marker.id}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {" "}
                              <h6 className="primary-color">{marker.name}</h6>
                            </Link>
                            <span>
                              {marker.Description.slice(1).join(` | `)}
                            </span>
                          </Col>
                        </Row>
                      </div>
                    </InfoWindow>
                  ) : null}
                </div>
              </OverlayView>
            ))}
          </GoogleMap>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default MapContainer;
