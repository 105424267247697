import React from 'react';
import { Container } from 'react-bootstrap';

import ContactForm from '../../features/Contact/Contact';

const ContactUs = () => {
    return (
        <Container fluid>
            <ContactForm/>
        </Container>
    )
}
export default ContactUs;
