import axiosInstance from "../../lib/axios";
import { EnquiryForm } from "./propertyOwnerSlice";
export const postEnquiryForm = (params: EnquiryForm) => {
    return axiosInstance
        .post(`/Properties/PropertyOwnerInquiry/`, {
            "Fname": params.firstName,
            "Lname": params.lastName,
            "mailto:email": params.email,
            "phone": params.phoneNumber,
            "where": params.city,
            "PType": params.pType,
            "Cnotes": params.message,
            "Source": "FA",
            "country": "United States"
        })
        .then((response) => {
            return { data: response.data }
        })
        .catch((error) => {
            if (error && error.response) {
                if (error.response) {
                    return { data: error.response.data }
                }
            }
        });
};

