import { useEffect, useRef, useState } from "react";
import styles from "./TermsOfUse.module.css";
import { Container } from "react-bootstrap";
import { MdOutlineArrowUpward } from "react-icons/md";

const TermsOfUse = () => {
  const topRef = useRef<HTMLDivElement>(null);

  const executeScroll = () => topRef.current?.scrollIntoView();

  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  const currentDate = new Date();
  const yearEnd = new Date("2023-12-31");

  return (
    <section>
      <div className={styles["banner-heading"]}>
        <h1>Terms of Use</h1>
        <p>Furnished Apartments Platform Terms of Use</p>
      </div>
      <Container className={styles["terms-of-use"]} ref={topRef}>
        <p>Property Listing Terms & Conditions</p>

        <h5>1.Acceptance of the Terms of Use</h5>
        <p>
          These terms of use are entered into by and between you (either as an
          individual or on behalf of the legal entity you represent) and Pioneer
          Properties, Inc. doing business as Furnished Apartments (“Furnished
          Apartments”) and Travel Nurse Housing.com (“Travel Nurse Housing”).
          The following terms and conditions, together with any documents they
          expressly incorporate by reference (collectively, the “Terms of Use”),
          govern your access to and use of www.furnishedapartments.com or
          www.travelnursehousing.com www.travelnursehousing.com , including any
          content, functionality and services offered on or through
          www.furnishedapartments.com, www.travelnursehousing.com, and any other
          website operated by Furnished Apartments, whether as a guest or a
          registered user, and any Furnished Apartments mobile or desktop apps
          (collectively, the “Furnished Apartments Platform”).
        </p>
        <p>
          This Furnished Apartments Platform is offered and available to users
          who are 18 years of age or older and reside in the United States or
          any of its territories or possessions. By using the Furnished
          Apartments Platform, you represent and warrant that you are of legal
          age to form a binding contract with Furnished Apartments and meet all
          of the foregoing eligibility requirements. If you do not meet all of
          these requirements, you must not access or use the Furnished
          Apartments Platform.
        </p>
        <h5>2.Changes to the Terms of Use</h5>
        <p>
          Furnished Apartments may revise and update these Terms of Use from
          time to time in its sole discretion. All changes are effective
          immediately when Furnished Apartments posts them and apply to all
          access to and use of the Furnished Apartments Platform thereafter.
          However, any changes to the dispute resolution provisions set forth in
          the Governing Law and Jurisdiction section will not apply to any
          disputes for which the parties have actual notice on or prior to the
          date the change is posted on the Furnished Apartments Platform.
        </p>
        <p>
          Your continued use of the Furnished Apartments Platform following the
          posting of revised Terms of Use means that you accept and agree to the
          changes. You are expected to check this page frequently, so you are
          aware of any changes, as they are binding on you.
        </p>
        <h5>
          3.Accessing the Furnished Apartments Platform and Account Security
        </h5>
        <p>
          Furnished Apartments reserves the right to withdraw or amend the
          Furnished Apartments Platform, and any service or material provided on
          the Furnished Apartments Platform, in Furnished Apartments’s sole
          discretion without notice. Furnished Apartments will not be liable if
          for any reason all or any part of the Furnished Apartments Platform is
          unavailable at any time or for any period. From time to time,
          Furnished Apartments may restrict access to some parts of the
          Furnished Apartments Platform, or the entire Furnished Apartments
          Platform, to users, including registered users.
        </p>
        <p>You are responsible for:</p>

        <p>
          <ul>
            <li>
              Making all arrangements necessary for you to have access to the
              Furnished Apartments Platform.
            </li>
            <li>
              Ensuring that all persons who access the Furnished Apartments
              Platform through your internet connection are aware of these Terms
              of Use and comply with them.
            </li>
          </ul>
        </p>
        <p>
          To access the Furnished Apartments Platform or some of the resources
          it offers, you may be asked to provide certain registration details or
          other information. It is a condition of your use of the Furnished
          Apartments Platform that all the information you provide on the
          Furnished Apartments Platform is correct, current and complete. You
          agree that all information you provide to register with this Furnished
          Apartments Platform, post on the Furnished Apartments Platform, or
          otherwise, including but not limited to through the use of any
          interactive features on the Furnished Apartments Platform, is governed
          by the Furnished Apartments Privacy Policy, and you consent to all
          actions Furnished Apartments takes with respect to your information
          consistent with the Privacy Policy.
        </p>
        <p>
          If you choose, or are provided with, a username, password or any other
          piece of information as part of Furnished Apartments security
          procedures, you must treat such information as confidential, and you
          must not disclose it to any other person or entity. You also
          acknowledge that your account is personal to you and agree not to
          provide any other person with access to this Furnished Apartments
          Platform or portions of it using your username, password or other
          security information. You will be responsible for all activities that
          occur under that password. You agree to notify Furnished Apartments
          immediately of any unauthorized access to or use of your username or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer so that others are not able to view or record your
          password or other personal information.
        </p>
        <p>
          You also represent and warrant that: (i) you will not select or
          utilize a username of another person with intent to impersonate that
          person; (ii) you will not select or utilize a username in which
          another person has rights, if you do not have that person's
          authorization to use such name; and (iii) you will not select or
          utilize a username that Furnished Apartments in its sole discretion
          deems offensive.
        </p>
        <p>
          Furnished Apartments has the right to disable any username, password
          or other identifier, whether chosen by you or provided by Furnished
          Apartments, at any time in its sole discretion for any or no reason,
          including if, in Furnished Apartments’s opinion, you have violated any
          provision of these Terms of Use.
        </p>

        <h5>4. Furnished Apartments Not a Party to Any Transaction.</h5>
        <p>
          {" "}
          The Furnished Apartments Platform acts as a venue to allow property
          owners or managers who advertise on the Furnished Apartments Platform
          (each, a “Property Owner”) to offer for rent in a variety of pricing
          formats, a specific short-term rental property (“Property”) to
          potential renters (each, a “Traveler” and, collectively with the
          Property Owners, the “users”). Furnished Apartments does not own or
          manage any Property listed on the Furnished Apartments Platform, does
          not enter into a lease(s) or other transactions with Property Owners
          and Travelers, is not a party to a rental agreement or other
          transaction between users, and provides no insurance relating to any
          transaction. Please also note that, without limitation, Furnished
          Apartments does not perform any criminal background checks on any
          Property Owner or any Property Owner employee, contractor, owner or
          representative. As such, Furnished Apartments urges all users to be
          responsible about their use of the Furnished Apartments Platform and
          any transaction entered into as a result of either listing a Property
          or renting a Property.
        </p>
        <h5>5.Intellectual Property Rights</h5>
        <p>
          {" "}
          The Furnished Apartments Platform and its entire contents, features
          and functionality (including but not limited to all information,
          software, text, displays, images, video and audio, and the design,
          selection and arrangement thereof), are owned by Furnished Apartments,
          its licensors or other providers of such material and are protected by
          United States and international copyright, trademark, patent, trade
          secret and other intellectual property or proprietary rights laws.
        </p>
        <p>
          {" "}
          These Terms of Use permit you to use the Furnished Apartments Platform
          for your personal, non-commercial use only, or for legitimate business
          purposes relating to your role as a potential property owner or
          customer of Furnished Apartments subject to any separate agreement
          with Furnished Apartments. You must not reproduce, distribute, modify,
          create derivative works of, publicly display, publicly perform,
          republish, download, store or transmit any of the material on the
          Furnished Apartments Platform, except as follows:
          <ul>
            <li>
              Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages
              of the Furnished Apartments Platform for your own personal,
              non-commercial use, or for legitimate business purposes relating
              to your role as a potential or current property owner, supplier or
              customer of Furnished Apartments, and not for further
              reproduction, publication or distribution.
            </li>
            <li>
              If Furnished Apartments provides desktop, mobile or other
              applications for download, you may download a single copy to your
              computer or mobile device solely for your own personal,
              non-commercial use, provided you agree to be bound by Furnished
              Apartments’s end user license or services agreement for such
              applications.
            </li>
          </ul>
          You must not:
        </p>
        <p>
          <ul>
            <li>
              Modify copies of any materials from the Furnished Apartments
              Platform.
            </li>
            <li>
              Use any illustrations, photographs, video or audio sequences or
              any graphics separately from the accompanying text.
            </li>
            <li>
              Delete or alter any copyright, trademark or other proprietary
              rights notices from copies of materials from the Furnished
              Apartments Platform.
            </li>
          </ul>
        </p>
        <p>
          Except as otherwise agreed to between you and Furnished Apartments,
          you must not reproduce, duplicate, copy, sell, trade, resell, exploit,
          access or use for any commercial purposes any part of the Furnished
          Apartments Platform or any services or materials available through the
          Furnished Apartments Platform.
        </p>
        <p>
          If you wish to make any use of material on the Furnished Apartments
          Platform other than that set out in this section, please address your
          request to: support@furnishedapartments.com.
        </p>
        <p>
          If you print, copy, modify, download or otherwise use or provide any
          other person with access to any part of the Furnished Apartments
          Platform in breach of the Terms of Use, your right to use the
          Furnished Apartments Platform will cease immediately and you must, at
          Furnished Apartments’s option, return or destroy any copies of the
          materials you have made. No right, title or interest in or to the
          Furnished Apartments Platform or any content on the Furnished
          Apartments Platform is transferred to you, and all rights not
          expressly granted are reserved by Furnished Apartments. Any use of the
          Furnished Apartments Platform not expressly permitted by these Terms
          of Use is a breach of these Terms of Use and may violate copyright,
          trademark and other laws. Furnished Apartments INTENDS TO FULLY
          PROSECUTE ALL VIOLATIONS OF APPLICABLE AND PURSUE ALL AVAILABLE
          REMEDIES.
        </p>
        <h5>6.Trademarks</h5>
        <p>
          {" "}
          The Furnished Apartments and Travel Nurse Housing names and logos, and
          all related names, logos, product and service names, designs and
          slogans are trademarks of Furnished Apartments or its affiliates or
          licensors. You must not use such marks without the prior written
          permission of Furnished Apartments. Without limitation, you may not
          use these names, logos or trademarks for purposes of keyword
          advertising. All other names, logos, product and service names,
          designs and slogans on this Furnished Apartments Platform are the
          trademarks of their respective owners.
        </p>
        <h5>7.Prohibited Uses</h5>
        <p>
          You may use the Furnished Apartments Platform only for lawful purposes
          and in accordance with these Terms of Use. You agree not to use the
          Furnished Apartments Platform:
          <ul>
            <li>
              In any way that violates any applicable federal, state, local or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </li>
            <li>
              For the purpose of exploiting, harming or attempting to exploit or
              harm minors in any way by exposing them to inappropriate content,
              asking for personally identifiable information or otherwise.
            </li>
            <li>
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with the Content Standards set out
              in these Terms of Use.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any “junk mail”, “chain letter” or
              “spam” or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate Furnished Apartments, a
              Furnished Apartments employee, another user or any other person or
              entity (including, without limitation, by using e-mail addresses
              or screen names associated with any of the foregoing).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the Furnished Apartments Platform, or which,
              as determined by Furnished Apartments, may harm Furnished
              Apartments or users of the Furnished Apartments Platform or expose
              them to liability.
            </li>
          </ul>
          Additionally, you agree not to:
          <ul>
            <li>
              Use the Furnished Apartments Platform in any manner that could
              disable, overburden, damage, or impair the site or interfere with
              any other party's use of the Furnished Apartments Platform,
              including their ability to engage in real time activities through
              the Furnished Apartments Platform.
            </li>
            <li>
              Use any robot, spider or other automatic device, process or means
              to access the Furnished Apartments Platform for any purpose,
              including monitoring or copying any of the material on the
              Furnished Apartments Platform.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on
              the Furnished Apartments Platform or for any other unauthorized
              purpose without Furnished Apartments’s prior written consent.
            </li>
            <li>
              Use any device, software or routine that interferes with the
              proper working of the Furnished Apartments Platform.
            </li>
            <li>
              Introduce any viruses, trojan horses, worms, logic bombs or other
              material which is malicious or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage or
              disrupt any parts of the Furnished Apartments Platform, the server
              on which the Furnished Apartments Platform is stored, or any
              server, computer or database connected to the Furnished Apartments
              Platform.
            </li>
            <li>
              Attack the Furnished Apartments Platform via a denial-of-service
              attack or a distributed denial-of-service attack.
            </li>
            <li>
              Otherwise attempt to interfere with the proper working of the
              Furnished Apartments Platform.
            </li>
          </ul>
        </p>
        <h5>8.User Contributions</h5>
        <p>
          The Furnished Apartments Platform may contain message boards, chat
          rooms, personal web pages or profiles, forums, bulletin boards, blogs,
          community areas and other interactive features (collectively,
          “Interactive Services”) that allow users to post, submit, publish,
          display or transmit to other users or other persons (hereinafter,
          “post”) content or materials (collectively, “User Contributions”) on
          or through the Furnished Apartments Platform.
        </p>
        <p>
          All User Contributions must comply with the Content Standards set out
          in these Terms of Use.
        </p>
        <p>
          Any User Contribution you post to the Furnished Apartments Platform
          will be considered non-confidential and non-proprietary. By providing
          any User Contribution on the Furnished Apartments Platform, you grant
          Furnished Apartments and its affiliates and service providers, and
          each of their and Furnished Apartments’s respective licensees,
          successors and assigns the right to use, reproduce, modify, perform,
          display, distribute and otherwise disclose to third parties any such
          material for any purpose.
        </p>
        <p>
          You represent and warrant that:
          <ul>
            <li>
              You own or control all rights in and to the User Contributions and
              have the right to grant the license granted above to Furnished
              Apartments and Furnished Apartments’s affiliates and service
              providers, and each of their and Furnished Apartments’s respective
              licensees, successors and assigns.
            </li>
            <li>
              All of your User Contributions are truthful, accurate, appropriate
              and comply with applicable law.
            </li>
            <li>
              All of your User Contributions do and will comply with these Terms
              of Use.
            </li>
          </ul>
        </p>

        <p>
          {" "}
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not Furnished
          Apartments, has full responsibility for such content, including its
          legality, reliability, accuracy and appropriateness.
        </p>
        <p>
          Furnished Apartments is not responsible, or liable to any third party,
          for the content or accuracy of any User Contributions posted by you or
          any other user of the Furnished Apartments Platform.
        </p>
        <h5>9.Monitoring and Enforcement; Termination</h5>
        <p>
          Furnished Apartments has the right to:
          <ul>
            <li>
              Remove or refuse to post any User Contributions for any or no
              reason in its sole discretion. Furnished Apartments may add,
              change, discontinue, remove, edit or suspend such User
              Contributions at any time for any reason without notice to you,
              without your permission, and without liability.
            </li>
            <li>
              Take any action with respect to any User Contribution that
              Furnished Apartments deems necessary or appropriate in Furnished
              Apartments’s sole discretion, including if Furnished Apartments
              believes that such User Contribution violates the Terms of Use,
              including the Content Standards, infringes any intellectual
              property right or other right of any person or entity, threatens
              the personal safety of users of the Furnished Apartments Platform
              or the public or could create liability for Furnished Apartments.
            </li>
            <li>
              Disclose your identity or other information about you to any third
              party who claims that material posted by you violates their
              rights, including their intellectual property rights or their
              right to privacy.
            </li>
            <li>
              Take appropriate legal action, including without limitation,
              referral to law enforcement, for any illegal or unauthorized use
              of the Furnished Apartments Platform.
            </li>
            <li>
              Terminate or suspend your access to all or part of the Furnished
              Apartments Platform for any or no reason, including without
              limitation, any violation of these Terms of Use.
            </li>
          </ul>
          Without limiting the foregoing, Furnished Apartments has the right to
          fully cooperate with any law enforcement authorities or court order
          requesting or directing Furnished Apartments to disclose the identity
          or other information of anyone posting any materials on or through the
          Furnished Apartments Platform. YOU WAIVE AND HOLD HARMLESS Furnished
          Apartments AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM
          ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING
          PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY
          ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH
          PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p>
          However, Furnished Apartments cannot and does not undertake to review
          all material before it is posted on the Furnished Apartments Platform
          and cannot ensure prompt removal of objectionable material after it
          has been posted. Accordingly, Furnished Apartments assumes no
          liability for any action or inaction regarding transmissions,
          communications or content provided by any user or third party.
          Furnished Apartments has no liability or responsibility to anyone for
          performance or nonperformance of the activities described in this
          section.
        </p>

        <h5>10.Content Standards</h5>
        <p>
          {" "}
          These content standards (the “Content Standards”) apply to any and all
          User Contributions and use of Interactive Services. User Contributions
          must in their entirety comply with all applicable federal, state,
          local and international laws and regulations. Without limiting the
          foregoing, User Contributions must not:
          <ul>
            <li>
              Contain any material which is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory or
              otherwise objectionable.
            </li>
            <li>
              Promote sexually explicit or pornographic material, violence, or
              discrimination based on race, sex, religion, nationality,
              disability, sexual orientation or age.
            </li>
            <li>
              Infringe any patent, trademark, trade secret, copyright or other
              intellectual property or other rights of any other person.
            </li>
            <li>
              Violate the legal rights (including the rights of publicity and
              privacy) of others or contain any material that could give rise to
              any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              of Use and Furnished Apartments’s Privacy Policy.
            </li>
            <li>Be likely to deceive any person.</li>
            <li>
              Promote any illegal activity, or advocate, promote or assist any
              unlawful act.
            </li>
            <li>
              Cause annoyance, inconvenience or needless anxiety or be likely to
              upset, embarrass, alarm or annoy any other person.
            </li>
            <li>
              Impersonate any person, or misrepresent your identity or
              affiliation with any person or organization.
            </li>
            <li>
              Involve commercial activities or sales, such as contests,
              sweepstakes and other sales promotions, barter or advertising.
            </li>
            <li>
              Give the impression that they emanate from or are endorsed by
              Furnished Apartments or any other person or entity, if this is not
              the case.
            </li>
          </ul>
        </p>
        <h5>11.Copyright Infringement</h5>
        <p>
          If you believe that any User Contributions violate your copyright,
          please see Furnished Apartments’s Copyright Policy set forth below for
          instructions on sending Furnished Apartments a notice of copyright
          infringement. It is the policy of Furnished Apartments to terminate
          the user accounts of repeat infringers.
        </p>
        <h5>12.Reliance on Information Posted</h5>
        <p>
          The information presented on or through the Furnished Apartments
          Platform is made available solely for general information purposes.
          Furnished Apartments does not warrant the accuracy, completeness or
          usefulness of this information. Any reliance you place on such
          information is strictly at your own risk. Furnished Apartments
          disclaims all liability and responsibility arising from any reliance
          placed on such materials by you or any other visitor to the Furnished
          Apartments Platform, or by anyone who may be informed of any of its
          contents.
        </p>
        <p>
          {" "}
          The Furnished Apartments Platform includes content provided by third
          parties, including materials provided by other users, bloggers and
          third-party licensors, syndicators, aggregators and/or reporting
          services. All statements and/or opinions expressed in these materials,
          and all articles and responses to questions and other content, other
          than the content provided by Furnished Apartments, are solely the
          opinions and the responsibility of the person or entity providing
          those materials. These materials do not necessarily reflect the
          opinion of Furnished Apartments. Furnished Apartments is not
          responsible, or liable to you or any third party, for the content or
          accuracy of any materials provided by any third parties.
        </p>
        <h5>13.Changes to the Furnished Apartments Platform</h5>
        <p>
          {" "}
          Furnished Apartments may update the content on the Furnished
          Apartments Platform from time to time, but its content is not
          necessarily complete or up-to-date. Any of the material on the
          Furnished Apartments Platform may be out of date at any given time,
          and Furnished Apartments is under no obligation to update such
          material.
        </p>
        <h5>
          14.Information About You and Your Visits to the Furnished Apartments
          Platform
        </h5>
        <p>
          {" "}
          All information Furnished Apartments collects on this Furnished
          Apartments Platform is subject to Furnished Apartments’s Privacy
          Policy. By using the Furnished Apartments Platform, you consent to all
          actions taken by Furnished Apartments with respect to your information
          in compliance with the Privacy Policy.
        </p>
        <h5>
          15.Linking to the Furnished Apartments Platform and Social Media
          Features
        </h5>
        <p>
          {" "}
          You may link to the Furnished Apartments Platform homepage, provided
          you do so in a way that is fair and legal and does not damage
          Furnished Apartments’s reputation or take advantage of it, but you
          must not establish a link in such a way as to suggest any form of
          association, approval or endorsement on Furnished Apartments’s part
          without Furnished Apartments’s express written consent.
        </p>
        <p>
          The Furnished Apartments Platform may provide certain social media
          features that enable you to:
          <ul>
            <li>
              Link from your own or certain third-party Furnished Apartments
              Platforms to certain content on the Furnished Apartments Platform.
            </li>
            <li>
              Send e-mails or other communications with certain content, or
              links to certain content, on the Furnished Apartments Platform.
            </li>
            <li>
              Cause limited portions of content on the Furnished Apartments
              Platform to be displayed or appear to be displayed on your own or
              certain third-party Furnished Apartments Platforms.
            </li>
          </ul>
          The Furnished Apartments Platform may provide certain social media
          features that enable you to:
          <ul>
            <li>
              Establish a link from any Furnished Apartments Platform that is
              not owned by you.
            </li>
            <li>
              Cause the Furnished Apartments Platform or portions of it to be
              displayed, or appear to be displayed by, for example, framing,
              deep linking or in-line linking, on any other site.
            </li>
            <li>
              Link to any part of the Furnished Apartments Platform other than
              the homepage.
            </li>
            <li>
              Otherwise take any action with respect to the materials on the
              Furnished Apartments Platform that is inconsistent with any other
              provision of these Terms of Use.
            </li>
          </ul>
          The Furnished Apartments Platform from which you are linking, or on
          which you make certain content accessible, must comply in all respects
          with the Content Standards set out in these Terms of Use.
        </p>
        <p>
          You agree to cooperate with Furnished Apartments in causing any
          unauthorized framing or linking immediately to cease. Furnished
          Apartments reserves the right to withdraw linking permission without
          notice.
        </p>
        <p>
          Furnished Apartments may disable all or any social media features and
          any links at any time without notice in Furnished Apartments’s
          discretion.
        </p>
        <h5>16.Links from the Furnished Apartments Platform</h5>
        <p>
          If the Furnished Apartments Platform contains links to other sites and
          resources provided by third parties, these links are provided for your
          convenience only. This includes links contained in advertisements,
          including banner advertisements and sponsored links. Furnished
          Apartments has no control over the contents of those sites or
          resources and accepts no responsibility for them or for any loss or
          damage that may arise from your use of them. If you decide to access
          any of the third-party Furnished Apartments Platforms linked to this
          Furnished Apartments Platform, you do so entirely at your own risk and
          subject to the terms and conditions of use for such Furnished
          Apartments Platforms.
        </p>
        <h5>17.Geographic Restrictions</h5>
        <p>
          The owner of the Furnished Apartments Platform, Furnished Apartments,
          is based in the United States. Furnished Apartments provides this
          Furnished Apartments Platform for use only by persons located in the
          United States. Furnished Apartments makes no claims that the Furnished
          Apartments Platform or any of its content is accessible or appropriate
          outside of the United States. Access to the Furnished Apartments
          Platform may not be legal by certain persons or in certain countries.
          If you are visiting the Furnished Apartments Platform or using the
          services from a country other than the country in which our servers
          are located, your communications with us may result in the transfer of
          information (including your registration or personal information)
          across international boundaries. By visiting this Furnished Apartments
          Platform, using the services, and/or communicating electronically with
          us, you consent to such transfers. If you access the Furnished
          Apartments Platform from outside the United States, you do so on your
          own initiative and are responsible for compliance with local laws.
        </p>
        <h5>18.Disclaimer of Warranties and Other Disclaimers</h5>
        <p>
          You understand that Furnished Apartments cannot and does not guarantee
          or warrant that files available for downloading from the internet or
          the Furnished Apartments Platform will be free of viruses or other
          destructive code. You are responsible for implementing sufficient
          procedures and checkpoints to satisfy your particular requirements for
          anti-virus protection and accuracy of data input and output, and for
          maintaining a means external to Furnished Apartments’s site for any
          reconstruction of any lost data.
        </p>
        <p>
          Furnished Apartments WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED
          BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE Furnished Apartments PLATFORM OR ANY SERVICES OR
          ITEMS OBTAINED THROUGH THE Furnished Apartments PLATFORM OR FROM
          Furnished Apartments, OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON
          IT, OR ON ANY Furnished Apartments PLATFORM LINKED TO IT.
        </p>
        <p>
          YOUR USE OF THE Furnished Apartments PLATFORM, ITS CONTENT AND ANY
          SERVICES OR ITEMS OBTAINED THROUGH THE Furnished Apartments PLATFORM,
          IS AT YOUR OWN RISK. THE Furnished Apartments PLATFORM OR FROM
          Furnished Apartments, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
          THROUGH THE Furnished Apartments PLATFORM OR FROM Furnished
          Apartments, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
          WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
          Furnished Apartments NOR ANY PERSON ASSOCIATED WITH Furnished
          Apartments MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY
          OF THE Furnished Apartments PLATFORM. WITHOUT LIMITING THE FOREGOING,
          NEITHER Furnished Apartments NOR ANYONE ASSOCIATED WITH Furnished
          Apartments REPRESENTS OR WARRANTS THAT THE Furnished Apartments
          PLATFORM, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          Furnished Apartments PLATFORM OR FROM Furnished Apartments WILL BE
          ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
          CORRECTED, THAT THE Furnished Apartments PLATFORM OR THE SERVER THAT
          MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
          THAT THE Furnished Apartments PLATFORM OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE Furnished Apartments PLATFORM OR FROM Furnished
          Apartments WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p>
          Furnished Apartments HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
          LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
          FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT ANY TRANSMISSION TO AND FROM THE
          Furnished Apartments PLATFORM IS NOT CONFIDENTIAL AND YOUR
          COMMUNICATIONS OR USER CONTENT MAY BE READ OR INTERCEPTED BY OTHERS.
          YOU FURTHER ACKNOWLEDGE AND AGREE THAT BY SUBMITTING COMMUNICATIONS OR
          USER CONTENT TO Furnished Apartments AND BY POSTING INFORMATION ON THE
          Furnished Apartments PLATFORM, INCLUDING PROPERTY LISTINGS, NO
          CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER RELATIONSHIP
          IS CREATED BETWEEN YOU AND Furnished Apartments OTHER THAN PURSUANT TO
          THESE TERMS OF USE.
        </p>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT YOU WILL NOT HOLD OR SEEK TO HOLD
          Furnished Apartments OR ANY THIRD-PARTY PROVIDER RESPONSIBLE FOR THE
          CONTENT PROVIDED BY ANY USER, INCLUDING, WITHOUT LIMITATION, ANY
          TRANSLATION THEREOF, AND YOU FURTHER ACKNOWLEDGE AND AGREE THAT
          Furnished Apartments IS NOT A PARTY TO ANY RENTAL TRANSACTION OR OTHER
          TRANSACTION BETWEEN USERS OF THE Furnished Apartments PLATFORM.
          Furnished Apartments HAS NO CONTROL OVER AND DOES NOT GUARANTEE THE
          SAFETY OF ANY TRANSACTION, RENTAL PROPERTY OR THE TRUTH OR ACCURACY OF
          ANY LISTING OR OTHER CONTENT PROVIDED ON THE Furnished Apartments
          PLATFORM. YOU SHOULD TAKE REASONABLE PRECAUTIONS WHEN ENTERING INTO
          ANY TRANSACTION.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <h5>19.Limitation on Liability</h5>
        <p>
          IN NO EVENT WILL Furnished Apartments, ITS AFFILIATES OR THEIR
          LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS
          BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT
          OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE Furnished
          Apartments PLATFORM, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE
          Furnished Apartments PLATFORM OR SUCH OTHER WEBSITES OR ANY SERVICES
          OR ITEMS OBTAINED THROUGH THE Furnished Apartments PLATFORM OR SUCH
          OTHER WEBSITES (INCLUDING ALL SERVICES PROVIDED BY Furnished
          Apartments), INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
          CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
          PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
          REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
          LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY
          TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
          FORESEEABLE.
        </p>
        <p>
          IF YOU ARE DISSATISFIED WITH THE Furnished Apartments PLATFORM, YOU DO
          NOT AGREE WITH ANY PART OF THE TERMS OF USE, OR HAVE ANY OTHER DISPUTE
          OR CLAIM WITH OR AGAINST Furnished Apartments, ANY THIRD PARTY
          PROVIDER OR ANY USER OF THE Furnished Apartments PLATFORM, WITH
          RESPECT TO THESE TERMS OF USE, OR THE Furnished Apartments PLATFORM,
          THEN YOUR SOLE AND EXCLUSIVE REMEDY AGAINST Furnished Apartments IS TO
          DISCONTINUE USING THE Furnished Apartments PLATFORM. IN ALL EVENTS,
          Furnished Apartments’S TOTAL LIABILITY TO YOU OR ANY THIRD PARTY IN
          ANY CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE Furnished
          Apartments PLATFORM IS LIMITED TO THE AMOUNT OF FEES YOU PAY TO
          Furnished Apartments IN THE TWELVE MONTHS PRIOR TO THE ACTION GIVING
          RISE TO LIABILITY
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <h5>20.Indemnification</h5>
        <p>
          You agree to defend, indemnify and hold harmless Furnished Apartments,
          its affiliates, licensors and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses or fees (including reasonable attorneys' fees) arising out of
          or relating to your violation of these Terms of Use or your use of the
          Furnished Apartments Platform, including, but not limited to, your
          User Contributions, any use of the Furnished Apartments Platform's
          content, services and products other than as expressly authorized in
          these Terms of Use or your use of any information obtained from the
          Furnished Apartments Platform.
        </p>
        <h5>21.General Release</h5>
        <p>
          BECAUSE Furnished Apartments IS NOT INVOLVED IN TRANSACTIONS BETWEEN
          PROPERTY OWNERS AND TRAVELERS OR OTHER USER DEALINGS, IF A DISPUTE
          ARISES BETWEEN ANY USERS, EACH PARTY RELEASES Furnished Apartments
          (AND ITS RESPECTIVE AGENTS, EMPLOYEES AND AFFILIATES) FROM CLAIMS,
          DEMANDS, AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND
          NATURE, KNOWN AND UNKNOWN, SUSPECTED AND UNSUSPECTED, DISCLOSED AND
          UNDISCLOSED, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH
          DISPUTES. EACH USER FURTHER RELEASES Furnished Apartments FOR AND FROM
          ANY AND ALL CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE,
          KNOWN OR UNKNOWN, ARISING FROM, RELATED TO, OR IN CONNECTION WITH, (A)
          USE OF THE Furnished Apartments PLATFORM, (B) ACTIONS, INACTIONS,
          AND/OR OMISSIONS OF OTHER USERS, (D) THE PROMOTION OR ADVERTISING OF
          PROPERTY ON THE Furnished Apartments PLATFORM, AND (E) THE USE OF THE
          Furnished Apartments PLATFORM TO OFFER FOR RENT OR TO FACILITATE THE
          RENT OF PROPERTY.
        </p>
        <h5>22.Governing Law and Jurisdiction</h5>
        <p>
          All matters relating to the Furnished Apartments Platform and these
          Terms of Use and any dispute or claim arising therefrom or related
          thereto (in each case, including non-contractual disputes or claims),
          shall be governed by and construed in accordance with the internal
          laws of the State of Colorado without giving effect to any choice or
          conflict of law provision or rule (whether of the State of Colorado or
          any other jurisdiction).
        </p>
        <p>
          Any legal suit, action or proceeding arising out of, or related to,
          these Terms of Use or the Furnished Apartments Platform shall be
          instituted exclusively in the federal courts of the United States or
          the courts of the State of Colorado in each case located in the City
          and County of Denver, although Furnished Apartments retains the right
          to bring any suit, action or proceeding against you for breach of
          these Terms of Use in your country of residence or any other relevant
          country. You waive any and all objections to the exercise of
          jurisdiction over you by such courts and to venue in such courts.
        </p>
        <h5>23.Arbitration</h5>
        <p>
          At Furnished Apartments’s sole discretion, it may require you to
          submit any disputes arising from the use of these Terms of Use or the
          Furnished Apartments Platform, including disputes arising from or
          concerning their interpretation, violation, invalidity,
          non-performance, or termination, to final and binding arbitration
          under the Rules of Arbitration of the American Arbitration Association
          applying Colorado law.
        </p>
        <h5>24.Limitation on Time to File Claims</h5>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE, THE PRIVACY POLICY, OR RELATED IN ANY WAY TO
          THE Furnished Apartments PLATFORM MUST BE COMMENCED WITHIN ONE (1)
          YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <h5>25.Waiver and Severability</h5>
        <p>
          No waiver by Furnished Apartments of any term or condition set forth
          in the Terms of Use shall be deemed a further or continuing waiver of
          such term or condition or a waiver of any other term or condition, and
          any failure of Furnished Apartments to assert a right or provision
          under the Terms of Use shall not constitute a waiver of such right or
          provision.
        </p>
        <p>
          If any provision of the Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>
        <h5>26.Property Owner Services</h5>
        <p>
          All listings of property through the Furnished Apartments Platform or
          other transactions for the sale of services or information formed
          through the Furnished Apartments Platform or as a result of visits
          made by you are governed by Furnished Apartments’s Property Listing
          Terms and Conditions, located at https://www.furnishedapartments.com/
          terms-of-use which are hereby incorporated into these Terms of Use.
        </p>
        <h5>27.Entire Agreement</h5>
        <p>
          The Terms of Use, Privacy Policy, and any other documents referenced
          in the Terms of Use, and any services agreement or license terms
          between the parties, including any Property Listing Terms and
          Conditions, constitute the sole and entire agreement between you and
          Furnished Apartments with respect to the Furnished Apartments Platform
          and supersede all prior and contemporaneous understandings,
          agreements, representations and warranties, both written and oral,
          with respect to the Furnished Apartments Platform.
        </p>
        <h5>28.Your Comments and Concerns</h5>
        <p>
          This Furnished Apartments Platform is operated by Pioneer Properties,
          Inc.
        </p>
        <p>
          All notices of copyright infringement claims should be sent to the
          copyright agent designated in Furnished Apartments’s Copyright Policy
          as set forth below in the manner and by the means set forth therein.
        </p>
        <p>
          All other feedback, comments, requests for technical support and other
          communications relating to the Furnished Apartments Platform should be
          directed to: support@furnishedapartments.com.
        </p>
        <h3>Copyright Policy</h3>
        <h4>REPORTING CLAIMS OF COPYRIGHT INFRINGEMENT</h4>
        <p>
          Furnished Apartments takes claims of copyright infringement seriously.
          Furnished Apartments will respond to notices of alleged copyright
          infringement that comply with applicable law. If you believe any
          materials accessible on or from this site (the “Furnished Apartments
          Platform”) infringe your copyright, you may request removal of those
          materials (or access to them) from the Furnished Apartments Platform
          by submitting written notification to Furnished Apartments’s copyright
          agent designated below. In accordance with the Online Copyright
          Infringement Liability Limitation Act of the Digital Millennium
          Copyright Act (17 U.S.C. § 512) (“DMCA”), the written notice (the
          “DMCA Notice”) must include substantially the following:
          <ul>
            <li>Your physical or electronic signature.</li>
            <li>
              Identification of the copyrighted work you believe to have been
              infringed or, if the claim involves multiple works on the
              Furnished Apartments Platform, a representative list of such
              works.
            </li>
            <li>
              Identification of the material you believe to be infringing in a
              sufficiently precise manner to allow Furnished Apartments to
              locate that material.
            </li>
            <li>
              Adequate information by which Furnished Apartments can contact you
              (including your name, postal address, telephone number, and, if
              available, email address).
            </li>
            <li>
              A statement that you have a good faith belief that use of the
              copyrighted material is not authorized by the copyright owner, its
              agent, or the law.
            </li>
            <li>
              A statement that the information in the written notice is
              accurate.
            </li>
            <li>
              A statement, under penalty of perjury, that you are authorized to
              act on behalf of the copyright owner.
            </li>
          </ul>
          Furnished Apartments’s designated copyright agent to receive DMCA
          Notices is: NAME: DMCA Agent
          <br />
          Pioneer Properties, Inc.
          <br />
          ADDRESS: 12900 Stroh Ranch Place. Suite 125
          <br />
          Parker, CO 80134
          <br />
          EMAIL: support@furnishedapartments.com
          <br />
          PHONE: 661-489-5907
        </p>

        <p>
          {" "}
          If you fail to comply with all of the requirements of Section
          512(c)(3) of the DMCA, your DMCA Notice may not be effective.
        </p>
        <p>
          Please be aware that if you knowingly materially misrepresent that
          material or activity on the Furnished Apartments Platform is
          infringing your copyright, you may be held liable for damages
          (including costs and attorneys' fees) under Section 512(f) of the
          DMCA.
        </p>
        <h3>COUNTER-NOTIFICATION PROCEDURES</h3>
        <p>
          If you believe that material you posted on the Furnished Apartments
          Platform was removed or access to it was disabled by mistake or
          misidentification, you may file a counter-notification with Furnished
          Apartments (a “Counter-Notice”) by submitting written notification to
          Furnished Apartments’s copyright agent designated above. Pursuant to
          the DMCA, the Counter-Notice must include substantially the following:
          <ul>
            <li>Your physical or electronic signature.</li>
            <li>
              An identification of the material that has been removed or to
              which access has been disabled and the location at which the
              material appeared before it was removed or access disabled.
            </li>
            <li>
              Adequate information by which Furnished Apartments can contact you
              (including your name, postal address, telephone number, and, if
              available, email address).
            </li>
            <li>
              A statement under penalty of perjury by you that you have a good
              faith belief that the material identified above was removed or
              disabled as a result of a mistake or misidentification of the
              material to be removed or disabled.
            </li>
            <li>
              A statement that you will consent to the jurisdiction of the
              Federal District Court for the judicial district in which your
              address is located (or if you reside outside the United States for
              any judicial district in which the Furnished Apartments Platform
              may be found) and that you will accept service from the person (or
              an agent of that person) who provided the Furnished Apartments
              Platform with the complaint at issue.
            </li>
          </ul>
        </p>

        <p>
          {" "}
          The DMCA allows Furnished Apartments to restore the removed content if
          the party filing the original DMCA Notice does not file a court action
          against you within ten business days of receiving the copy of your
          Counter-Notice.
        </p>
        <p>
          Please be aware that if you knowingly materially misrepresent that
          material or activity on the Furnished Apartments Platform was removed
          or disabled by mistake or misidentification, you may be held liable
          for damages (including costs and attorneys' fees) under Section 512(f)
          of the DMCA.
        </p>
        <h3>REPEAT INFRINGERS</h3>
        <p>
          It is Furnished Apartments’s policy in appropriate circumstances to
          disable and/or terminate the accounts of users who are repeat
          infringers.
        </p>
        <h4 className="primary-color">Review Policy</h4>
        <p>
          {" "}
          Reviews are an important part of our user experience as they serve as
          a self-regulated system of checks and balances, and exist for the
          protection of both parties. What happens in a property after a
          traveler and property owner connect on our site, and agree to enter
          into a habitation/cohabitation arrangement, is not something we have
          any insight into, nor can we control. Furnished Apartments is not a
          judge, nor will we serve as a mediator within a dispute between
          traveler and property owner because Furnished Apartments is not party
          to the lease, nor the transaction.
        </p>
        <p>
          We have a no tolerance policy for content manipulation, including
          self-posted reviews. Users caught posting false or inappropriate
          reviews risk losing their account without remediation or refund. Users
          may report reviews, by using the contact us feature, that violate
          these terms.
        </p>
        <p>
          Regarding self-posted reviews, If the owner has verified travel nurse
          reviews from recent (within 6 months), we will be happy to add those
          to your listing for you (even if they booked from another platform).
        </p>
        <p>
          All submissions will be reviewed prior to posting publicly where we
          may approve or decline the entire review, or parts of the review. The
          only reason will will remove a review is if it’s from an unauthorized
          reviewer (where the review was meant for another property/user or
          doesn’t otherwise apply to the party being reviewed).
        </p>
        <p>
          In order to maintain a welcoming environment for all users, your
          participation in the community must always be respectful of others. In
          particular:
        </p>
        <p>
          <b>Review Guidelines</b>
        </p>
        <ul>
          <li>
            All reviews must be from verified tenants or travelers who exchanged
            money with the landlord.
          </li>
          <li>All reviews must be helpful and cordial.</li>
          <li>
            Don't post content that is libelous, defamatory, harassing,
            threatening, or inflammatory. For example, don't use obscenities or
            profanity, and don't express hatred or intolerance for people on the
            basis of race, ethnicity, nationality, gender or gender identity,
            religion, sexual orientation, age, or disability, including by
            promoting organizations with such views.
          </li>
          <li>
            Don't post content that is disrespectful, obscene, lewd, or that
            contains sexual or otherwise explicit content.
          </li>
          <li>
            Don't post content that invades others' privacy. For example, don't
            post other people's full name, phone numbers, email addresses,
            mailing addresses, or other personal information, and don't post
            links to phishing or other malware sites.
          </li>
          <li>
            Don't impersonate other people or organizations or pretend to be
            someone or something you're not.
          </li>
          <li>Don't repeatedly send messages or requests to other people.</li>
          <li>
            Don't attempt to drown out other people's opinions, including by
            posting from multiple accounts or coordinating with others.
          </li>
          <li>
            Don't engage in name-calling or attack people based on whether you
            agree with them.
          </li>
          <li>
            You may question the beliefs and expertise of others as long as it
            is relevant and done in a respectful and non-threatening manner.
          </li>
          <li>
            Content created solely for the purpose of advertising or other
            commercial content, including company logos, links, or company names
            is never allowed.
          </li>
          <li>
            Spam, unwanted contact, or content that is shared repeatedly in a
            disruptive manner is also strictly prohibited.
          </li>
          <li>
            The following guidelines apply to Listing Reviews in addition to the
            other guidelines given above:
          </li>
          <li>
            If your review is removed or rejected because it does not comply
            with our guidelines concerning promotional content, you may resubmit
            1 (one) additional time in effort to comply with our listing review
            guidelines.
          </li>
          <li>
            We may restrict the ability to submit a review when we detect
            unusual reviewing behavior, or to maintain the best possible
            experience on our site.
          </li>
          <li>
            If you are suspected of harassing, or sharing false information
            about a user or traveler on purpose, you will be removed from the
            site without notice or refund.
          </li>
        </ul>
        <p>Last Modified: June 20, 2022</p>
        <h3 className="primary-color">Property Listing</h3>
        <h3 className="primary-color">Terms and Conditions</h3>
        <p>
          These Property Listing Terms and Conditions (the “Terms and
          Conditions”) set forth the terms and conditions governing the
          relationship between you (either as an individual or on behalf of the
          legal entity you represent) (the “Property Owner”) and Pioneer
          Properties, Inc., doing business as Furnished Apartments (“Furnished
          Apartments”) relating to Property Owner’s use of the
          www.furnishedapartments.com or www.travelnursehousing.com websites,
          including any content, functionality and services offered on or
          through www.furnishedapartments.com, www.travelnursehousing.com, and
          any other website operated by Furnished Apartments, whether as a guest
          or a registered user, and any Furnished Apartments mobile or desktop
          apps (collectively the “Furnished Apartments Platform”) and the
          listing of short term rental properties (“Properties”) on the
          Furnished Apartments Platform.
        </p>
        <p>
          <b>
            By using the Furnished Apartments Platform, or by clicking to accept
            or agree to the Terms and Conditions when this option is made
            available to you, Property Owner accepts and agrees to be bound and
            abide by these Terms and Conditions, in addition to the Furnished
            Apartments Platform Terms of Use located at
            https://www.furnishedapartments.com/terms-of-use and the Furnished
            Apartments Privacy Policy, located at
            https://www.furnishedapartments.com/privacy and incorporated herein
            by reference. If you do not want to agree to these Terms and
            Conditions (including the Terms of Use or the Privacy Policy), you
            must not access or use the Furnished Apartments Platform.
          </b>
        </p>

        <p>
          <b>
            To the extent the Terms of Use or Privacy Policy are inconsistent
            with these Terms and Conditions, the Terms and Conditions shall
            control.
          </b>
        </p>

        <h3>1. Services</h3>
        <p>
          <b>1.1 Property Advertising Services.</b>
          Subject to these Terms and Conditions, payment of all fees for the
          Furnished Apartments services, and Property Owner passing all
          background checks, Furnished Apartments agrees to provide Property
          Owner a limited, revocable, non-exclusive license to access the
          Furnished Apartments Platform and the content and services provided on
          the Furnished Apartments Platform solely for the purpose of
          advertising a short-term rental Property to individuals who are
          looking for short terms rentals (“Travelers”).
        </p>
        <p>
          <b>1.2. Property Owner Background Checks.</b>
          Furnished Apartments reserves the right at any time to perform
          background checks on Property Owners, and Property Owners may be
          subject to verification, including, but not limited to, validation
          against third-party databases or the verification of one or more
          official government or legal documents that confirm Property Owners’
          identity and location, financial status, etc. Property Owner
          authorizes Furnished Apartments, directly or through third parties, to
          make any inquiries necessary to validate Property Owner’s identity and
          location, confirm Property Owner’s ownership of its email address or
          financial accounts, its rights with regard to the property Property
          Owner intends to list on the Furnished Apartments Platform, etc.,
          subject to applicable law. When requested, Property Owner must timely
          provide Furnished Apartments with all requested information to perform
          the background checks. Based on the results of the background checks
          or the verification process, Furnished Apartments may, in its sole
          discretion, upon notice to Property Owner, determine not to provide
          services or discontinue the provision of existing services to Property
          Owners without refund of any pre-paid fees. Furnished Apartments does
          not store or keep any social security numbers.
        </p>

        <p>
          <b>1.3. Furnished Apartments Not a Party to Any Transaction.</b>
          Furnished Apartments does not enter into leases or other agreements
          with Property Owners and is not a party to any rental agreement or
          other transaction between Property Owners and Travelers. Furnished
          Apartments does not book the Traveler’s stay, nor does it manage the
          rent. All fees, charges, and rents from the Travelers are managed by,
          and paid directly to, the Property Owner. Furnished Apartments also
          does not get in the middle of disputes between Travelers and Property
          Owners.
        </p>
        <p>
          <b>1.4 Identity Verification/Background Checks.</b>
          Traveler verification on the Internet is difficult and Furnished
          Apartments cannot, and does not assume any responsibility for, the
          confirmation of each Traveler's purported identity or background.
          Furnished Apartments encourages Property Owners to communicate
          directly with a Traveler through the tools available on the Furnished
          Apartments Platform, though even this does not assure you of the
          identity of the person with which you are communicating, and perform
          whatever background checks Property Owner deems appropriate. Furnished
          Apartments further encourages you to take other reasonable measures to
          assure yourself of the other person’s identity and relevant details of
          your acceptance of a Traveler or proposed Traveler from Furnished
          Apartments Platform.
        </p>

        <p>
          <b>1.5 Property Owner Service/Traveler Ratings.</b>
          Property Owner acknowledges that the Furnished Apartments Platform is
          intended to refer Travelers only to those Property Owners who maintain
          the highest standards of professionalism and quality of service.
          Property Owner acknowledges that Travelers may rate and review a
          Property Owner at any time. Property Owner agrees to maintain high
          standards of professionalism, quality and service at all times,
          including treating all Travelers with respect, not engaging in
          threatening behavior, and providing a safe Traveler environment.
          Property Owner further agrees to maintain a Traveler rating at or
          above the minimum rating established by Furnished Apartments for
          access to the Furnished Apartments Platform, as modified from time to
          time.
        </p>

        <p>
          <b>1.6 Distribution of Listings to Third Party Websites.</b>
          To enable Property Owner to obtain broader distribution of its
          Properties, Furnished Apartments may provide the listing information,
          or otherwise provide for the distribution of, the Property Owner
          listing on a third-party website. Additional terms and conditions may
          apply to such distributions, as Furnished Apartments may notify
          Property Owner of via Property Owner’s online account or email.
        </p>
        <h3> 2.Property Owner Obligations and Responsibilities.</h3>
        <p>
          <b>2.1 Compliance with Applicable Law.</b>
          Property Owners are fully responsible for and agree to comply with all
          applicable laws, rules and regulations applicable to the listing of
          the Property and the conduct of their rental business, including but
          not limited to any and all laws, rules, regulations or other
          requirements relating to taxes, credit cards, data and privacy, taxes,
          permits or license requirements, zoning ordinances, safety compliance
          and compliance with all anti-discrimination and fair housing laws, as
          applicable. Property Owners further agree that they will at all times
          provide a safe environment for Travelers. Property Owners who accept
          credit card, banking or other payment information from Travelers agree
          to properly handle and safeguard all such information in accordance
          with applicable legal and regulatory requirements and best practices.
        </p>
        <p>
          <b>2.2 Property Listings.</b>
          All Property listings on the Furnished Apartments Platform are the
          sole responsibility of the Property Owner, and Furnished Apartments
          specifically disclaims any and all liability arising from the alleged
          accuracy of the listings, reviews, or any alleged breaches of contract
          on a Traveler's part. Property Owners agree that the Property Listings
          will at all times be accurate, and further agree that they are solely
          responsible for keeping, and shall at all times keep, their property
          information up-to-date on the Furnished Apartments Platform,
          including, but not limited to any and all representations about any
          Property, its amenities, location, price, and its availability for a
          specific date or range of dates. Furnished Apartments does not
          represent or warrant that any of the copy, content, property reviews,
          guest book entries, property location, suitability, pricing or
          availability information published on the Furnished Apartments
          Platform is accurate or up-to-date even in the case where prospective
          Travelers have searched for specific special offers, dates, or types
          of properties. Furnished Apartments may from time to time provide or
          facilitate services to Property Owners to create or improve the
          quality of their Property listings. Furnished Apartments also may from
          time to time create new or otherwise change the location or geographic
          descriptions it uses to identify Properties in their listings and
          search results. Consequently, Furnished Apartments may change the
          location or geographic description associated with any Property
          listing at any time without notice. However, Furnished Apartments
          assumes no responsibility to verify Property listing content or the
          accuracy of the location. Property Owners are solely responsible for
          ensuring the accuracy of location, geographic and other content and
          location or geographic descriptions and agree to promptly correct (or
          contact Furnished Apartments to correct) any inaccuracy. Furnished
          Apartments may suspend and remove a Property listing on the Furnished
          Apartments Platform if Property Owner fails to comply with the terms
          of this Section, including if Property Owner fails to set the
          availability of a Property on the Furnished Apartments Platform for a
          period of 90 days
        </p>

        <p>
          <b>2.3 License and Rights Granted to Furnished Apartments.</b>
        </p>
        <p>
          <b>(a)</b>
          By submitting or authorizing Property Owner contributed User
          Contributions, Property Owner grants to Furnished Apartments and its
          affiliates a perpetual, worldwide, irrevocable, unrestricted,
          non-exclusive, royalty-free and fully paid-up license to use, copy,
          license, sublicense (through multiple tiers), adapt, distribute,
          display, publicly perform, reproduce, transmit, modify, edit and
          otherwise exploit the copy, the photographs and the likenesses (if
          any) of any of Property Owner’s User Contributions, in connection with
          Furnished Apartments’s business or the business of its affiliates.
          Notwithstanding the foregoing, following the termination or expiration
          of a Property listing subscription, Furnished Apartments will not
          continue to display the User Contributions that were displayed in such
          listing.
        </p>

        <p>
          <b>(b)</b>
          Property Owner further grants Furnished Apartments and its affiliates
          the ability to copyright and protect the User Contributions, including
          the images, copy, and content available via any Property Owner’s
          listing, from the unauthorized use by unaffiliated third parties who
          may, from time to time, attempt to pirate such information via
          electronic or other means. This includes, but is not limited to, the
          right to file suit to seek injunctive relief to protect such material.
          You further agree to assist Furnished Apartments, at Furnished
          Apartments’s expense and control, to protect such copyrighted material
          from unauthorized redistribution.
        </p>

        <p>
          <b>(c)</b>
          Property Owner agrees that Furnished Apartments may sublicense all the
          rights granted to it under these Terms and Conditions to one or more
          third parties Furnished Apartments may contract with to display all or
          part of the Property Owner’s Property listing or otherwise provide
          promotional or other services related to Furnished Apartments’s
          business. Further, Property Owner agrees that Furnished Apartments may
          reproduce in whole or in part any photographic material supplied by
          Property Owner in the promotion of the Property or the promotion of
          the Furnished Apartments Platform. In the event that it is determined
          that Property Owner retains any rights of attribution, integrity or
          any other moral rights in any User Contributions, Property Owner
          hereby declares that it does not require that any personally
          identifying information be used in connection with the User
          Contributions or any derivative works thereof and that Property Owner
          has no objection to the publication, use, modification, deletion or
          exploitation of the User Contributions by Furnished Apartments or its
          affiliates.
        </p>

        <p>
          <b>
            2.4 Limitations on Communications and Use of Travelers’ Information
          </b>
        </p>
        <p>
          <b>(a)</b>
          Property Owner agrees that, with respect to Traveler’s personal
          information that Property Owner obtains directly or indirectly from or
          through the Furnished Apartments Platform or through any Furnished
          Apartments Platform-related communication or transaction, Furnished
          Apartments grants to Property Owner a license to use such information
          only for: (a) Furnished Apartments Platform related communications
          that are not unsolicited commercial messages, (b) using services
          offered through the Furnished Apartments Platform, and (c) inquiring
          about or otherwise facilitating a financial transaction between
          Property Owner and the Traveler related to the purpose of the
          Furnished Apartments Platform. Any other purpose will require express
          permission from the Traveler. Property Owner may not use any such
          information for any unlawful purpose or with any unlawful intent.
        </p>

        <p>
          <b>(b)</b>
          In all cases, Property Owners must give Travelers an opportunity to
          remove their information from the Property Owner address book or
          database or other records and a chance to review what information
          Property Owner has collected about them. In addition, under no
          circumstances, except as defined in this section, may Property Owner
          disclose personal information about a Traveler to any third party
          without both Furnished Apartments’s consent and the consent of the
          Traveler. Further, Property Owner agrees that it will protect
          Traveler’s personal information with the same degree of care that it
          protects its own confidential information (using at minimum a
          reasonable standard of care), and Property Owner assumes all liability
          for the misuse, loss, or unauthorized transfer of such information.
        </p>

        <p>
          <b>(c)</b>
          Furnished Apartments does not tolerate spam or unsolicited commercial
          electronic communications of any kind. Therefore, without limiting the
          foregoing, Property Owner is not licensed to add a Traveler, even a
          Traveler who has rented from Property Owner, to its mailing list
          (email or physical mail) without the Traveler’s express consent.
          Property Owner may not use any tool or service on the Furnished
          Apartments Platform to send spam or unsolicited commercial electronic
          communications of any kind or in any other way that would violate
          these Terms and Conditions. Property Owner is responsible for all
          content it provides to the Furnished Apartments Platform or through
          any tool or service provided on the Furnished Apartments Platform.
        </p>

        <p>
          <b>2.5 Translations and Maps.</b>
          If any Property Owner content is translated for display on the
          Furnished Apartments Platform or any site of any affiliate of
          Furnished Apartments, Furnished Apartments cannot guarantee the
          accuracy or quality of such translation and the Property Owner is
          solely responsible for the review, verification and accuracy of such
          translation. Maps provided on the Furnished Apartments Platform that
          are provided by Google are subject to the Google Maps terms and
          conditions located at:
          http://www.google.com/intl/en_us/help/terms_maps.html
        </p>

        <p>
          <b>2.6 Insurance.</b>
          Furnished Apartments does not provide liability, property or other
          insurance protection for Property Owners, and Property Owners are
          solely responsible for obtaining insurance coverage sufficient to
          protect the Properties and the Travelers. Property Owner agrees that
          it has or will obtain the appropriate insurance coverage sufficient to
          cover the rental of the Properties prior to the arrival of their first
          Traveler and will maintain adequate insurance coverage through the
          departure date of any Traveler they have obtained via the Furnished
          Apartments Platform. Further, Property Owners agree to provide
          Furnished Apartments with copies of relevant proof of coverage upon
          request.
        </p>

        <h3>3. Fees, Payment Terms.</h3>

        <p>
          <b>3.1 Fees.</b>
          Property Owner shall pay Furnished Apartments the fees and expenses
          set forth in the applicable order between Property Owner and Furnished
          Apartments. Unless otherwise agreed to, a 12-month subscription is{" "}
          {currentDate <= yearEnd ? " $99" : "$149"}, which auto renews at the
          end of the subscription term. Property Owners frequently have multiple
          spaces to rent at one Property. Property Owners may purchase extra
          units (which provide unique calendars, descriptions, unit name, and
          images) for {currentDate <= yearEnd ? "$79" : "$99"} for a 12-month
          term. Mid-term purchases are prorated accordingly. Property Owners of
          Hotels and apartment complexes can purchase listings for $250 for a
          12-month term
          {currentDate <= yearEnd ? ", or $150 for a 6-month trial" : ""}. All
          extra units, as described above, for hotels and apartment complexes
          are included. All fees are non-refundable.
        </p>

        <p>
          <b>3.2 Automatic Renewal of Subscriptions.</b>
          For any subscription paid for by credit card, such subscription shall
          automatically renew at the expiration of the then-current term for an
          additional term of the same duration (as the previous term) and at the
          then-current non-promotional subscription rate. Automatic renewal
          applies to all subscriptions purchased by credit card or e-check. The
          automatic renewal feature allows your service to remain uninterrupted
          at the expiration of your then-current term. If Property Owner wishes
          to turn off auto-renewal, Property Owner must log on to its account
          and manually turn off auto-renewal, prior to expiration of the
          then-current term. Requests received via email, voicemail, or other
          correspondence do not constitute a valid opt-out. Upon any such
          turning off auto-renewal, your subscription will remain active through
          the expiration of your then-current subscription term, however your
          subscription will not be automatically renewed upon the expiration of
          your then current term. If the subscription does not auto-renew or
          expires at the end of the then current subscription term and Property
          Owner desires to renew the subscription, Property Owner will be
          required to pay the then-current non-promotional subscription rate to
          renew the subscription or to activate a new subscription. If Property
          Owner does not turn off auto-renewal, Property Owner re-affirms and
          authorizes Furnished Apartments to charge the credit card at the end
          of each subscription term for an additional term of the same duration
          as the initial term and at the then-current non-promotional
          subscription rate for the same product or service. Furnished
          Apartments intends to send reminder emails, prior to the expiration of
          the term, with regard to the subscription end date and automatic
          renewal.
        </p>

        <p>
          <b>3.3 Subscription Terms.</b>
          All subscription listings are sold to run the full term that is chosen
          by the Property Owner. Unless otherwise agreed to, listings are for a
          minimum of 12 months, and can be up to 24 months. The term starts on
          the date that the Property Owner submits the full or initial (as
          applicable) payment and expires on the last date of the term chosen by
          the Property Owner. For example, for an annual subscription term, if
          the Property Owner submits payment for the subscription on July 1st,
          the subscription would expire on June 30 of the following year. No
          trial subscriptions are offered.
        </p>

        <p>
          <b>3.4 Non-Subscription Listings.</b>
          If Furnished Apartments enables Property Owner to list Property on a
          basis other than by subscription, Property Owner agrees to pay the
          fees as described in the sign-up process for each rental of the
          Property displayed in such listing, which terms may be updated by
          Furnished Apartments from time to time without notice by displaying
          the terms on the Furnished Apartments Platform. The sign-up process
          and additional notices Property Owner may receive from Furnished
          Apartments may also provide additional terms and conditions for such
          listings.
        </p>

        <p>
          <b>3.5 Refunds.</b>
          All fees paid are non-refundable, including in the circumstance where
          a Property Owner background check comes back negative or a
          verification cannot be completed. If Property Owner sells the Property
          and no longer wishes for the listing to remain online, Furnished
          Apartments should be contacted so the listing can be removed; however,
          no refund will be owed or provided.
        </p>

        <p>
          <b>3.6 Taxes.</b>
          All Fees and other amounts payable by Property Owner under the
          Agreement are exclusive of taxes and similar assessments. Property
          Owner is responsible for all sales, use and excise taxes, and any
          other similar taxes, duties and charges of any kind imposed by any
          federal, state or local governmental or regulatory authority on any
          amounts payable by Property Owner hereunder, other than any taxes
          imposed on Furnished Apartments's income.
        </p>

        <p>
          <b>3.7 Payment .</b>
          Payment will be as set forth in the applicable order form, generally
          by major credit card or debit card, Paypal, e-check or similar, on an
          annual automatic and recurring basis. A valid credit card must remain
          on file at all times. Late payments shall be subject to a charge of
          18% per annum on the due amount from due date until payment has been
          received by Furnished Apartments. Client shall also reimburse
          Furnished Apartments any bank charges, penalties or other amounts for
          insufficient funds or other payment rejection. Credit card payments
          will show up as transactions with Pioneer Properties, Inc.
        </p>
        <h3>4. Additional Terms and Conditions.</h3>
        <p>
          <b>4.1 Appearance in Search Results.</b>
          Furnished Apartments cannot guarantee that Property Owner’s listing
          will appear in any specific order in search results on the Furnished
          Apartments Platform. Search order will fluctuate based on a variety of
          factors such as search parameters, subscription level purchased,
          listing quality, how frequently a calendar is updated, and other
          factors that Furnished Apartments may deem important to the user
          experience from time to time. Listing appearance or order in any
          particular search result may also vary depending on the search
          criteria used by the particular Traveler. Furnished Apartments
          reserves the right to apply various search algorithms or to use
          methods to optimize results for particular Travelers’ experiences and
          the overall marketplace. Listings placed on a non-subscription basis,
          such as those that may be grandfathered in by a previous model, may
          not always appear in search results in any particular subscription
          level or at all. Listings distributed on third party sites are not
          guaranteed to display on such third-party site in any particular order
          or at all. Search results and sort order may appear different on
          current or future Furnished Apartments mobile applications than they
          appear on the Furnished Apartments Platform. To optimize the search
          experience for both Property Owners and Travelers, Furnished
          Apartments retains the right to run occasional tests that will be
          limited in duration but may alter how we display search results and
          subscription levels.
        </p>

        <p>
          <b>4.2 Content, Layout and Copy.</b>
          All content and copy edits submitted by Property Owners are subject to
          review and approval by Furnished Apartments in its sole discretion.
          Furnished Apartments reserves the right to refuse to publish any
          content that it determines in its sole discretion does not meet these
          Terms and Conditions or is otherwise unacceptable. However, Furnished
          Apartments assumes no duty to review content and shall not have any
          liability for any loss or damage resulting from the design or
          positioning of the copy, Properties, content and/or photographs or any
          change made to any content, photograph or copy submitted by any
          Property Owner. All content must meet these Terms and Conditions
          including the Content Standards of Furnished Apartments Platform Terms
          of Use. Furnished Apartments reserves the right to edit content
          submitted to the Furnished Apartments Platform in a non-substantive
          manner solely to cause the content to comply with the Content
          Standards or formatting requirements. Property Owners are responsible
          for reviewing and ensuring that any content displayed on the Furnished
          Apartments Platform appears as the Property Owner intended.
        </p>

        <p>
          <b>4.3 Photographs.</b>
        </p>

        <p>
          <b>(a)</b>
          Photographs submitted by Property Owner should depict the furnished
          Property as the main subject of the photograph and may not include
          children or adults if you do not have their legal consent or any
          information that would violate the privacy rights, intellectual
          property rights or any other rights of a third party. Photographs must
          meet Furnished Apartments’s Content Standards. Furnished Apartments
          reserves the right not to display or to remove any photographs that it
          determines, in its sole discretion, do not meet these Terms and
          Conditions or are otherwise unacceptable to us.
        </p>

        <p>
          <b>(b) </b>
          By submitting a photograph to Furnished Apartments, Property Owner
          represents and warrants that (i) (A) it holds all intellectual
          property rights with respect to each submitted photograph, or (B) it
          has secured from the copyright holder all rights necessary for the
          photograph to be used in an online advertisement, (ii) that any people
          in the photograph have given permission for their likeness to be
          displayed in an online advertisement on the Furnished Apartments
          Platform, (iii) that the photograph accurately and fairly represents
          the subject of the photograph and has not been altered in any manner
          that would mislead a viewer of that photograph, and (iv) that it will
          indemnify and hold harmless Furnished Apartments from any cause of
          action arising from any misrepresentation or otherwise with respect to
          any and all photographs so submitted.
        </p>

        <p>
          <b>(c)</b>
          It is Property Owner’s responsibility to obtain reproduction
          permission for all photographic and other material used in its
          listings. Property Owner warrants that it is the owner of the
          copyright in such material or is authorized by the owner thereof to
          grant to Furnished Apartments the rights therein contained and agrees
          to provide any proof of such rights to us that Furnished Apartments
          may request. Further, Property Owner agrees that Furnished Apartments
          may reproduce in whole or in part any photographic material supplied
          by Property Owner in the promotion of either the Property or the
          promotion of the Furnished Apartments Platform.
        </p>
        <p>
          <b>4.4 Uses of Trademarks, Names or Logos.</b>
        </p>

        <p>
          <b>(a)</b>
          There are limited ways in which Property Owners may use Furnished
          Apartments’s trademarks, names or logos (including, without
          limitation, Furnished Apartments, furnishedapartments.com, Travel
          Nurse Housing, TravelNurseHousing.com) without specific prior written
          authorization. The following are general guidelines: It is usually
          permissible for Property Owner, during the term of any subscription,
          to refer to Furnished Apartments in a descriptive manner in its
          listing on the Furnished Apartments Platform or in other permissible
          communications. For example, Property Owner might say “Check out my
          listing on Furnished Apartments” or “I list properties on Furnished
          Apartments.” Posting of the URL on any social platform is encouraged.
          However, Property Owner may not refer to Furnished Apartments or any
          of its affiliates in any way that might lead someone to believe that
          Property Owner or its business or Property, is sponsored by,
          affiliated with, or endorsed by Furnished Apartments or one of its
          affiliates. For example, Property Owner may not say “Furnished
          Apartments sponsors my furnished rental,” or describe the Property as
          “Furnished Apartments’s best furnished rental.” Property Owner may not
          use the Furnished Apartments name or one of its affiliates’ names on
          any other website that lists furnished rentals without Furnished
          Apartments’s prior written authorization.
        </p>

        <p>
          <b>(b)</b>
          Property Owner may also not use, directly or indirectly, any Furnished
          Apartments trademarks, names or logos, and all related names, logos,
          product and service names, designs and slogans (e.g. Furnished
          Apartments, furnishedapartments.com, Travel Nurse Housing, and
          TravelNurseHousing.com), or any similar words or names (for example,
          without limitation, Property Owner may not use “Furnished
          Apartmentss,” “traveling nurse housing,” “housing for traveling
          nurses”, etc.), during the term of this Agreement and thereafter in
          any advertising, including keyword advertising, or social media.
        </p>

        <p>
          <b>4.5 Hypertext Links.</b>
          Furnished Apartments reserves the right to refuse hypertext links to,
          or addresses of, other web sites from Property Owner’s pages, and to
          remove links or web addresses without notice in its sole discretion.
          Further, Furnished Apartments reserves the right to charge for
          hypertext links at any time.
        </p>

        <p>
          <b>4.6. Substitution of Properties.</b>
          Advertising More Than One Property; Property Managers. . Each Property
          Owner listing must relate to an individual and uniquely identified
          Property, unless (i) Property Owner is a property manager who has
          signed up for one of Furnished Apartments’s packages for Property
          Owners who are property managers, or (ii) Property Owner otherwise has
          purchased a listing package that expressly allows for substitution of
          properties. This means that:
        </p>

        <p>
          <b>(a)</b>
          The Property in a listing may not be substituted for another Property
          without Furnished Apartments’s consent. Furnished Apartments may
          approve a request in its discretion if the property manager's contract
          for the Property was terminated and the Property Owner provides
          sufficient proof, as requested by Furnished Apartments, and completes
          any additional request forms Furnished Apartments may request. The
          term of the subscription for any substituted Property shall be the
          same as the term of the originally listed Property (i.e., the term
          will not be extended past the original term). If a Property Owner
          submits changes to an existing listing that, if approved, would
          substantially alter the listing to make it that of another Property,
          then Furnished Apartments has the right to terminate the listing and
          may choose, in its sole discretion, to retain any fees associated with
          the term of the previously existing listing as compensation for the
          violation of this condition.
        </p>

        <p>
          <b>(b)</b>
          The listing specifically cannot be a mere example of properties in a
          given area. Only one Property can appear on each listing, unless it is
          a Property with multiple rental units on the same site and additional
          advertising units are purchased. Furnished Apartments reserves the
          right to amend the copy or remove any listing when more than one
          Property is described in such listing, and may choose, in its sole
          discretion to retain any fees associated with the initial term of such
          non-conforming listing as compensation for the violation of this
          condition.
        </p>

        <p>
          <b>(c)</b>
          All subscription listing packages require one subscription per listing
          (one subscription per Property).
        </p>

        <p>
          <b>4.7 Payments Between Property Owners and Travelers.</b>
          Furnished Apartments are not a party to any payment transaction
          between Property Owners and Travelers, even if it receives a
          commission in connection with any payment transaction. No Property
          Owner may request any traveler to mail cash, or utilize any
          instant-cash wire transfer service such as Western Union or MoneyGram
          in payment for all or part of a Property rental transaction. Any
          violation of this term or any other unacceptable payment methods that
          may be posted on the Furnished Apartments Platform may result in the
          immediate removal of the non-conforming listing from the Furnished
          Apartments Platform without notice to the Property Owner and without
          refund.
        </p>

        <p>
          <b>4.8 Feedback.</b>
          If Property Owner transmits or provided any communications or
          materials to Furnished Apartments by mail, email, telephone, or
          otherwise, suggesting or recommending changes to the Furnished
          Apartments Services (“Feedback”), Furnished Apartments is free to use
          such Feedback irrespective of any other obligation or limitation
          between the parties governing such Feedback. Property Owner hereby
          assigns to Furnished Apartments on Property Owner's behalf, and on
          behalf of its employees, contractors and/or agents, all right, title,
          and interest in, and Furnished Apartments is free to use, without any
          attribution or compensation to any party, any ideas, know-how,
          concepts, techniques, or other intellectual property rights contained
          in the Feedback, for any purpose whatsoever, although Furnished
          Apartments is not required to use any Feedback.
        </p>

        <h3>5. Relationship of the Parties.</h3>

        <p>
          {" "}
          The relationship between the parties is that of independent
          contractors. Nothing contained in the Terms and Conditions shall be
          construed as creating any agency, partnership, joint venture or other
          form of joint enterprise, employment or fiduciary relationship between
          the parties, and neither party shall have authority to contract for or
          bind the other party in any manner whatsoever.
        </p>
        <h3> 6. Representations, Warranties and Disclaimers.</h3>

        <p>
          <b>6.1 Property Owner Representations and Warranties.</b> In addition
          to any other representations and warranties set forth in this
          Agreement, Property Owner represents and warrants to Furnished
          Apartments that: (a) Property Owner has the full right, power and
          authority to enter into and perform its obligations and grant the
          rights, licenses, consents and authorizations it grants or is required
          to grant under the Agreement; (b) the Agreement will constitute the
          legal, valid and binding obligation of such party, enforceable against
          property Owner in accordance with its terms; (c) Property Owner has
          the necessary rights and consents in and relating to the User
          Contributions so that, as received by Furnished Apartments and
          processed in accordance with the Agreement, they do not and will not
          infringe, misappropriate or otherwise violate any Intellectual
          Property Rights, or any privacy or other rights of any third party or
          violate any applicable law; and (d) Furnished Apartments will at all
          times comply with all applicable laws and the terms of this Agreement.
        </p>
        <p>
          <b>6.2 Furnished Apartments Disclaimers</b>
        </p>

        <p>
          THE Furnished Apartments PLATFORM, ITS CONTENT AND ANY SERVICES OR
          ITEMS OBTAINED THROUGH THE Furnished Apartments PLATFORM OR FROM
          Furnished Apartments, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
          BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
          NEITHER Furnished Apartments NOR ANY PERSON ASSOCIATED WITH Furnished
          Apartments MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY
          OF THE Furnished Apartments PLATFORM. WITHOUT LIMITING THE FOREGOING,
          NEITHER Furnished Apartments NOR ANYONE ASSOCIATED WITH Furnished
          Apartments REPRESENTS OR WARRANTS THAT THE Furnished Apartments
          PLATFORM, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          Furnished Apartments PLATFORM OR FROM Furnished Apartments WILL BE
          ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
          CORRECTED, THAT THE Furnished Apartments PLATFORM OR THE SERVER THAT
          MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
          THAT THE Furnished Apartments PLATFORM OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE Furnished Apartments PLATFORM OR FROM Furnished
          Apartments WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
        <p>
          Furnished Apartments HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
          LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
          FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          PROPERTY OWNER ACKNOWLEDGES AND AGREES THAT ANY TRANSMISSION TO AND
          FROM THE Furnished Apartments PLATFORM IS NOT CONFIDENTIAL AND
          COMMUNICATIONS OR USER CONTENT MAY BE READ OR INTERCEPTED BY OTHERS.
          PROPERTY OWNER FURTHER ACKNOWLEDGES AND AGREES THAT BY SUBMITTING
          COMMUNICATIONS OR USER CONTENT TO Furnished Apartments AND BY POSTING
          INFORMATION ON THE Furnished Apartments PLATFORM, INCLUDING PROPERTY
          LISTINGS, NO CONFIDENTIAL, FIDUCIARY, CONTRACTUALLY IMPLIED OR OTHER
          RELATIONSHIP IS CREATED BETWEEN PROPERTY OWNER AND Furnished
          Apartments OTHER THAN PURSUANT TO THESE TERMS OF USE.
        </p>
        <p>
          PROPERTY OWNER ACKNOWLEDGES AND AGREES THAT IT WILL NOT HOLD OR SEEK
          TO HOLD Furnished Apartments OR ANY THIRD-PARTY PROVIDER RESPONSIBLE
          FOR THE CONTENT PROVIDED BY ANY USER, INCLUDING, WITHOUT LIMITATION,
          ANY TRANSLATION THEREOF, AND PROPERTY OWNER FURTHER ACKNOWLEDGE SAND
          AGREES THAT Furnished Apartments IS NOT A PARTY TO ANY RENTAL
          TRANSACTION OR OTHER TRANSACTION BETWEEN USERS OF THE Furnished
          Apartments PLATFORM. Furnished Apartments HAS NO CONTROL OVER AND DOES
          NOT GUARANTEE THE SAFETY OF ANY TRANSACTION, RENTAL PROPERTY OR THE
          TRUTH OR ACCURACY OF ANY LISTING OR OTHER CONTENT PROVIDED ON THE
          Furnished Apartments PLATFORM. PROPERTY OWNER SHOULD TAKE REASONABLE
          PRECAUTIONS WHEN ENTERING INTO ANY TRANSACTION.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          <b>6.3 Additional Disclaimers. </b>Property Owner understands,
          acknowledges and agrees that the Property rental services provided by
          Property Owner are fully and entirely Property Owner's responsibility.
          Furnished Apartments is not responsible or liable for the actions or
          inactions of a Traveler or other third party in relation to the
          services provided by Property Owner. Property Owner understands,
          therefore, that by using the Furnished Apartments Platform, Property
          Owner will be introduced to third parties in relation to whom
          Furnished Apartments has not conducted any background or reference
          checking, that may be potentially dangerous, and that Property Owner
          uses the Furnished Apartments Platform at his/her own risk.
        </p>
        <h3>7. Indemnification.</h3>
        <p>
          Property Owner shall defend, indemnify and hold harmless Furnished
          Apartments and its affiliates and their officers, directors,
          employees, agents, successors, and assigns from and against all
          losses, damages, liabilities, deficiencies, actions, judgments,
          interest, awards, penalties, fines, costs, or expenses of whatever
          kind (including reasonable legal fees) arising out of, resulting from
          or relating to: (a) bodily injury, death of any person, theft or
          damage to real or tangible, personal property resulting from Property
          Owner's acts or omissions, (b) any claims by any Traveler or Furnished
          Apartments Platform user related to the Property Owner or the
          Property, (c)Property Owner's breach of any representation, warranty,
          or obligation under this Agreement, and (d) any matters arising out of
          or related to Property Owner’s use of the Furnished Apartments
          Platform.
        </p>
        <h3>8. Limitation of Liability.</h3>
        <p>
          <b>8.1 EXCLUSION OF DAMAGES.</b>
          IN NO EVENT WILL Furnished Apartments OR ANY OF ITS LICENSORS, SERVICE
          PROVIDERS OR SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH THE
          AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY,
          INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
          LIABILITY AND OTHERWISE, FOR ANY: (a) IMPAIRMENT, INABILITY TO USE OR
          LOSS, INTERRUPTION OR DELAY OF THE SERVICES, (b) LOSS, DAMAGE,
          CORRUPTION OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM SECURITY,
          OR (c) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL,
          ENHANCED OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE
          ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR
          DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF
          ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.
        </p>

        <p>
          <b>8.2 CAP ON MONETARY LIABILITY.</b>
          IN NO EVENT WILL THE AGGREGATE LIABILITY OF Furnished Apartments UNDER
          OR IN CONNECTION WITH THE AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY
          LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED THE
          FEES ACTUALLY PAID BY PROPERTY OWNER IN THE 12 MONTHS IMMEDIATELY
          PRIOR TO THE ACTION OR CLAIM. THE FOREGOING LIMITATION APPLIES
          NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS
          ESSENTIAL PURPOSE.
        </p>

        <h3>9. Term and Termination.</h3>
        <p>
          <b>9.1 Term.</b>
          Unless earlier terminated in accordance with this Agreement, this
          Agreement will continue in effect for the period set forth in the
          applicable Services order form. Unless written notice of termination
          is provided at least thirty days prior to the end of the then current
          term, the Agreement will automatically renew for additional 30-day
          periods (or such other period as agreed to by the parties).
        </p>

        <p>
          <b>9.2 Termination/Suspension.</b>
          In addition to any other express termination or suspension right set
          forth elsewhere in this Agreement, Furnished Apartments may suspend or
          terminate Property Owner’s access or use of the Furnished Apartments
          Platform, and the Agreement, at any time if there is any breach of any
          provision of the Agreement, including any unauthorized use of the
          Furnished Apartments Platform. No refunds will be provided in the
          event of such a termination.
        </p>

        <p>
          <b>9.3 Surviving Terms.</b>
          The provisions set forth in the following sections, and any other
          right or obligation of the parties in this Agreement that, by its
          nature, should survive termination or expiration of this Agreement,
          will survive any expiration or termination of this Agreement.
        </p>

        <h3>10. Force Majeure.</h3>
        <p>
          Furnished Apartments shall not be liable for any default or delay in
          the performance of any of its obligations under the Agreement if and
          to the extent such default or delay is caused, directly or indirectly,
          by (a) fire, flood, earthquake, elements of nature or acts of God; (b)
          wars (declared and undeclared), acts of terrorism, sabotage, riots,
          civil disorders, rebellions or revolutions; (c) extraordinary
          malfunction of third-party Internet infrastructure, data centers or
          related systems or (d) acts of any governmental authority with respect
          to any of the foregoing, and provided that such default or delay
          cannot reasonably be circumvented by the non-performing party through
          the use of commercially reasonable alternate sources, workaround plans
          or other commercially reasonable means.
        </p>
        <h3>11. Assignment.</h3>
        <p>
          Property Owner may not assign this Agreement. Furnished Apartments may
          freely assign its rights and obligations under this Agreement at any
          time. This Agreement will inure to the benefit of, be binding on, and
          be enforceable against, each of the parties hereto and their
          respective successors and assigns.
        </p>
        <h3>12. Dispute Resolution/Governing Law.</h3>

        <p>
          <b>12.1 Governing Law. </b> This Agreement will be governed in all
          respects by the laws of the State of Colorado (without regard to
          conflicts of law provisions), as such laws are applied to agreements
          entered into and to be performed entirely within the State of Colorado
          between Colorado residents.
        </p>
        <p>
          <b>12.2 Dispute Resolution</b>
        </p>

        <p>
          <b>(a)</b>
          Both parties must use their best efforts to resolve any dispute that
          may arise under, or in connection with, this Agreement through good
          faith negotiations.
        </p>

        <p>
          <b>(b)</b>
          If a dispute arises under, or in connection with, this Agreement which
          cannot be resolved by the parties through negotiation within 30 days,
          the parties agree to engage in mediation in Denver, Colorado before a
          mutually agreed upon mediator. If the parties cannot agree upon a
          mediator within 14 days, the parties agree to request appointment of a
          mediator by the American Arbitration Association under its Commercial
          Mediation Procedures before resorting to arbitration or some other
          dispute resolution procedure.
        </p>

        <p>
          <b>(c)</b>
          If mediation fails, any controversy or claim arising out of or
          relating to this Agreement, or the breach thereof, shall be settled by
          arbitration administered by the American Arbitration Association in
          accordance with its then applicable Commercial Arbitration Rules, and
          judgment on the award rendered by a single arbitrator may be entered
          in any court having jurisdiction thereof. The arbitrator has no
          authority to award punitive damages. The award shall be made within
          nine months of the filing of the notice of intention to arbitrate
          (demand), and the arbitrator shall agree to comply with this schedule
          before accepting appointment. However, the time limit may be extended
          by agreement of the parties or the arbitrator if necessary. The award
          of arbitration shall be accompanied by a reasoned opinion. Any
          arbitration will occur in Denver, Colorado.
        </p>

        <p>
          Nothing in this section shall prevent a party from seeking urgent
          interlocutory relief from a court of competent jurisdiction. The costs
          of any mediation and/or arbitration shall be borne equally by the
          parties.
        </p>

        <h3>13. Changes to the Terms and Condition</h3>
        <p>
          Furnished Apartments may revise and update these Terms and Conditions
          from time to time in its sole discretion. All changes are effective
          immediately when Furnished Apartments posts them and apply to all
          access to and use of the Furnished Apartments Platform thereafter.
          However, any changes to the dispute resolution provisions set forth in
          the Governing Law and Jurisdiction section will not apply to any
          disputes for which the parties have actual notice on or prior to the
          date the change is posted on the Furnished Apartments Platform.
        </p>
        <p>
          Your continued use of the Furnished Apartments Platform following the
          posting of revised Terms and Conditions means that you accept and
          agree to the changes. You are expected to check this page frequently,
          so you are aware of any changes, as they are binding on you.
        </p>
        <h3>14. Miscellaneous</h3>
        <p>
          <b>14.1 Notices.</b>
          Any notice required or permitted by this Agreement will be in writing
          and will be delivered as follows with notice deemed given as
          indicated: (i) by person delivery when delivered personally; (ii) by
          overnight courier upon written verification of receipt; or (iii) by
          certified or registered mail, return receipt requested, upon
          verification of receipt. Notice will be sent to the addresses provided
          by the parties.
        </p>

        <p>
          <b>14.2 Severability.</b>
          Should any provision of the Terms and Conditions be held by a court of
          competent jurisdiction to be illegal, invalid or unenforceable, the
          legality, validity and enforceability of the remaining provisions will
          not be affected or impaired thereby.
        </p>

        <p>
          <b>14.3 Waiver.</b>
          The waiver by either party of a breach of any provision of the Terms
          and Conditions of the other party will not operate or be construed as
          a waiver of any other or subsequent breach by such other party.
        </p>

        <p>
          <b>14.4 No Third-Party Beneficiaries.</b>
          The Agreement is for the sole benefit of the parties hereto and their
          respective permitted successors and permitted assigns and nothing
          herein, express or implied, is intended to or shall confer upon any
          other person any legal or equitable right, benefit or remedy of any
          nature whatsoever under or by reason of the Agreement.
        </p>

        <p>
          <b>14.5 Severability.</b>
          If any provision of this Agreement is invalid, illegal or
          unenforceable in any jurisdiction, such invalidity, illegality or
          unenforceability shall not affect any other term or provision of this
          Agreement or invalidate or render unenforceable such term or provision
          in any other jurisdiction. Upon such determination that any term or
          other provision is invalid, illegal or unenforceable, the parties
          hereto shall negotiate in good faith to modify this Agreement so as to
          effect the original intent of the parties as closely as possible in a
          mutually acceptable manner in order that the transactions contemplated
          hereby be consummated as originally contemplated to the greatest
          extent possible.
        </p>

        <p>
          <b>14.6 Waiver of Jury Trial.</b>
          Each party irrevocably and unconditionally waives any right it may
          have to a trial by jury in respect of any legal action arising out of
          or relating to the Agreement or the transactions contemplated hereby.
        </p>

        <p>
          <b>14.7 Attorneys' Fees.</b>
          In the event that any action, suit, or other legal or administrative
          proceeding is instituted or commenced by either party hereto against
          the other party arising out of the Agreement, the prevailing party
          shall be entitled to recover its reasonable attorneys' fees and court
          costs from the non-prevailing party.
        </p>

        <p>
          <b>14.8 Headings.</b>
          The headings in this Agreement are for reference only and do not
          affect the interpretation of this Agreement.
        </p>

        <p>
          <b>14.9 Entire Agreement.</b>
          These Terms and Conditions, including the Terms of Use and Privacy
          Policy Agreement, and all terms and other documents referenced in
          those agreements, constitutes the entire agreement between the parties
          relating to this subject matter and supersedes all prior or
          contemporaneous oral or written agreements concerning such subject
          matter.
        </p>
      </Container>
      <div className={"scroll-wrap"}>
        <div
          onClick={executeScroll}
          className={"scroll-icon"}
          style={{ display: showScroll ? "flex" : "none" }}
        >
          <MdOutlineArrowUpward />
        </div>
      </div>
    </section>
  );
};

export default TermsOfUse;
