import axiosInstance from "../../lib/axios";

export const getPropertyDetails = (propertyId: string) => {
    return axiosInstance
        .post(`/Properties/GetPropertyDetails/`, { propertyId })
        .then((response) => {
            return { data: response.data }
        })
        .catch((error) => {
            if (error && error.response) {
                if (error.response) {
                    return { data: error.response.data }
                }
            }
        });
};
