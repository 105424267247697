import React from 'react';
import styles from './PropertyListCard.module.css';
import { Card } from 'react-bootstrap';
import ImgSlider from '../ImgSlider/ImgSlider';
import { Link } from 'react-router-dom';
import moment from 'moment';
interface PropertyDetailsInterface {
  propertyId: string;
  images: any[];
  title: string;
  description: any;
  image: string;
  price: number;
  bedRoomCount: number;
  BathType: string;
  PType: string;
  setSelectedProperty: any;
  PropAvailableDate: any;
}

const PropertyListCard: React.FC<PropertyDetailsInterface> = ({ propertyId, images, title, description, image, PType, price, bedRoomCount, BathType, setSelectedProperty, PropAvailableDate }) => {
  const onMouseOutHandler = () => {
    setSelectedProperty(null)
  };

  const onMouseOverHandler = () => {
    setSelectedProperty(propertyId)
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  const today = new Date();

  return (
    <Card className={`${styles['property-card']} mb-3 property-card`} onMouseOver={onMouseOverHandler} onMouseOut={onMouseOutHandler}>
      <div >
        {images ?
          <div className='img-slider'>
            <ImgSlider
            img1={images ? images[0]?.url : ''}
            img2={images[1]?.url}
            img3={images[2]?.url}
            img4={images[3]?.url}
          />
           <p className="custom-legend">{PropAvailableDate <= today ? 'Available Now' : `Available from ${moment(PropAvailableDate).format("MMM Do")}`}</p>
          </div> : <div className={styles['loader_wrapper']}>
            < div className={styles['loader07']} />
          </div >}

      </div>
      <Link onClick={scrollToTop} to={`/search-map/property/${propertyId}`}  target="_blank" rel="noreferrer"><Card.Body>
        <div className='d-flex align-items-center justify-content-between mb-2'>
          <div className={`${styles["primary-color"]} ${styles["price-list"]} mt-1`}><h5 className='d-inline-block  fw-600 mb-0 mt-0 primary-color'>{price}</h5></div>
          <div className={`${PType === 'Room' ? styles["property-type-room"] : PType === 'House' ? styles["property-type-house"] : PType === 'Apartment' ? styles["property-type-apartment"] : PType === 'Condo' ?  styles["property-type-condo"] :  styles["property-type-container"]}`}>{PType}</div>
        </div>
        <Card.Title className={`${styles["primary-color"]} mt-1`}>{`${title.slice(0, 70)}${title.length > 70 ? '...' : ''}`}</Card.Title>
        <Card.Text className={styles["description"]}>
          {description?.join(' | ')}
        </Card.Text>
      </Card.Body>
      </Link>
    </Card>
  )
}

export default PropertyListCard;