import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import styles from './PopularCities.module.css';
import CityIcon from '../../assets/images/cities.png'
import HomeMap from '../../assets/images/map-image.png';
import { Link } from 'react-router-dom';

const PopularCities = () => {

    return(
        <section className='section-padding '>
            <Container>
        <Row>
          <Col lg={4} xs={12}  className='pt-lg-5 align-items-centre '>
            <div className="d-flex align-items-start custom-sm-device-query mt-sm-5 ">
              <Image src={CityIcon} className="me-3"></Image>
              <div>
                    <h1 className='primary-color'>Search Nationwide</h1>
                        <p>Search fully furnished properties nationwide and easily find your next place.</p>
                <Link className="btn btn-primary" to="/search-map">Explore more</Link>
              </div>
                    
            </div>
            
          </Col>
          <Col lg={8} className='mt-5 text-center'>
            <Image src={HomeMap} alt='Home-Map' className={styles["map"]}/>
            
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PopularCities;
