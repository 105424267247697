import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import TagBlock from "./TagBlock";
import AboutImg from "../../assets/images/AboutUs.png";
import Icon1 from "../../assets/images/Icons/home-icon1.png";
import Icon2 from "../../assets/images/Icons/home-icon2.png";
import Icon3 from "../../assets/images/Icons/home-icon3.png";

const AboutUs = () => {
  return (
    <section className="section-padding">
      <Container>
        <Row className="align-items-center mb-4">
          <Col
            xl={4}
            lg={4}
            md={12}
            className="text-sm-center text-lg-start text-center"
          >
            <h1 className="primary-color">
              A new way <br className="d-none d-lg-block" /> to live
            </h1>
            <h5 className="sub-title mb-4">
              Fully Furnished Apartments has so many advantages compared to
              traditional apartments.
            </h5>
          </Col>
          <Col xl={8} lg={8} md={12}>
            <Row>
              <Col md={4} className="mb-3">
                <TagBlock
                  image={Icon3}
                  title="Save money"
                  description={`Don't spend money on the "stuff" you need to outfit your rental. It's all included and for a similar rate as an unfurnished rental.`}
                />
              </Col>
              <Col md={4} className="mb-3">
                <TagBlock
                  image={Icon1}
                  title="No long days"
                  description="Browse the highest quality listings, and do everything online! Apply for the unit, sign your lease, and even pay your rent from any device."
                />
              </Col>
              <Col md={4} className="mb-3">
                <TagBlock
                  image={Icon2}
                  title="No setup needed"
                  description="Save the hassle of setting up utilities in your name. Utilities are also included in your monthly rent, without increasing the cost."
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col md={6}>
            <Image src={AboutImg} className="img-fluid"></Image>
          </Col>
          <Col md={5} className="text-sm-center text-md-start text-center">
            <div className="ps-lg-5">
              <h5 className="sub-title">
                Renting unfurnished is <i>old school</i> and a lot of extra
                work.
              </h5>
              <h2 className="primary-color fw-500">
                Whoever thought that was a good idea?<br className="d-none d-md-block" /> A Furnished Apartment makes way more sense. 
              </h2>
              <h5 className="sub-title">
                Apartment Hunting has changed: rent furnished.
              </h5>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default AboutUs;
