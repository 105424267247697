import React from 'react';
import 'antd/dist/antd.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-day-picker/dist/style.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './assets/css/style.css';
import { Helmet } from 'react-helmet';
import MetaImage from "./assets/images/banner-background.png";

import RouterManager from './setup/router-manager';

function App() {  
  return (
    <>
    <Helmet>
    <meta name="image" content={MetaImage} />
  </Helmet>
    <RouterManager />
    </>
  );
}

export default App;
