import React from 'react';
import LearnMoreCard from './LearnMoreCard';
import styles from './LearnMore.module.css';
import { Tab, Row, Nav, Col, Container } from 'react-bootstrap';
import OnlineRentCollectionImg from '../../assets/images/Icons/rent-collection.png';
import FreeTenantScreening from '../../assets/images/Icons/tenant-screening.png';
import CustomLease from '../../assets/images/Icons/custom-leases.png';
const LearnMore = () => {
    return (
        <section >
            <Container fluid>
                <div className={`${styles["learn-more"]} section-padding`}>
                    <Container >
                        <Tab.Container defaultActiveKey="landlord" >
                            <Nav className="switch-tabs mt-2  mb-5" variant="pills">
                                <Nav.Item className={`${styles["nav-content"]} m-1`}>
                                    <Nav.Link eventKey="rental">For Renters</Nav.Link>
                                </Nav.Item>
                                <Nav.Item className={`${styles["nav-content"]} m-1`}>
                                    <Nav.Link eventKey="landlord">For Landlord</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="rental">
                                    <Row className='justify-content-center align-items-baseline'>
                                        <Col lg={4} md={12} offset={2} className={`${styles["learn-more-wrap"]} `}>
                                            <LearnMoreCard
                                                title="Renting Made Simple"
                                                description="Browse the highest quality listings, apply online, sign your lease, and even pay your rent from any device."
                                                image={OnlineRentCollectionImg}
                                            />
                                            <a href="https://www.keycheck.com/" className={`btn btn-primary ${styles["learn-more-btn"]}`}>Learn more</a>


                                        </Col>
                                        <Col lg={4} md={12} className={`${styles["learn-more-wrap"]} `}>
                                            <LearnMoreCard
                                                title="Tips for Renters"
                                                description="Find answers to all of your renting questions with the best renter’s guide in the galaxy."
                                                image={FreeTenantScreening}

                                            />
                                            <a href="https://www.keycheck.com/" className={`btn btn-primary ${styles["learn-more-btn"]}`}>Learn more</a>


                                        </Col>

                                    </Row>

                                </Tab.Pane>
                                <Tab.Pane eventKey="landlord">
                                    <Row className='justify-content-center'>
                                        <Col lg={4} md={12} className={`${styles["learn-more-wrap"]} align-items-center mb-3`}>
                                            <LearnMoreCard
                                                title="Online Rent Collection"
                                                description="Collect rent online via Credit Card or Direct ACH payment."
                                                image={OnlineRentCollectionImg}

                                            />
                                            <a href="https://www.keycheck.com/Home/OnlineRentCollection" className={`btn btn-primary ${styles["learn-more-btn"]}`}>Learn more</a>

                                        </Col>
                                        <Col lg={4} md={12} className={`${styles["learn-more-wrap"]} align-items-center mb-3`}>
                                            <LearnMoreCard
                                                title="Free Tenant Screening"
                                                description="Criminal, Credit and Eviction checks.  (Free to Landlords, Tenant Pays)."
                                                image={FreeTenantScreening}

                                            />
                                            <a href="https://www.keycheck.com/Home/FreeTenantScreening" className={`btn btn-primary ${styles["learn-more-btn"]}`}>Learn more</a>

                                        </Col>
                                        <Col lg={4} md={12} className={`${styles["learn-more-wrap"]} align-items-center mb-3`}>
                                            <LearnMoreCard
                                                title="Custom Residential"
                                                description="State Specific Leases, Applications and more."
                                                image={CustomLease}

                                            />
                                            <a href="https://www.keycheck.com/Home/DocumentLeasesEsigning" className={`btn btn-primary ${styles["learn-more-btn"]}`}>Learn more</a>

                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Container>
                </div>
            </Container>
        </section>

    )

}

export default LearnMore;